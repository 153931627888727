import React , { useEffect , useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';

export default function BarChart({ data }) {
  const [ keys , setKeys ] = useState(null);
  const [ dataSet , setDataSet ] = useState(null);

  useEffect(() => {
    if (data) {

      setKeys();
      setDataSet();

      let keysValues = []
      for (const property in data[0]) {
        if (property !== "month") keysValues.push(property);
      }
      setKeys(keysValues);

      let dataSetValues = []
      data.forEach(month => {
        var monthCopy = {...month};
        var lastProperty = null
        for (const property in monthCopy ) {
          if (property !== "month") {
            if (lastProperty) {
              lastProperty = null
              monthCopy[`${property}Color`] = '#E6CF7A';
            } else {
              lastProperty = property
              monthCopy[`${property}Color`] = '#E8E0C3';
            }
          }
        }
        dataSetValues.push(monthCopy);
      });
      setDataSet(dataSetValues);
    }
  }, [data]);

  return (
    <div className="bar-chart-container">
      {keys && dataSet && <ResponsiveBar
        data={dataSet}
        keys={keys}
        indexBy="month"
        colorBy="id"
        margin={{ top: 30 , right: 10, bottom: 30, left: 0 }}
        padding={0.2}
        groupMode="grouped"
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableGridY={false}
        axisLeft={null}
        // axisLeft={({
        //   format: v => `${v} €` ,
        //   tickSize: 0
        // })}
        colors={({ id, data }) => data[`${id}Color`]}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        animate={true}
        tooltip={(props) => (
          <strong>
            {props.value.toFixed(2)} €
          </strong>
        )}
        enableLabel={false}
        motionStiffness={90}
        motionDamping={15}
      />
      }
    </div>
  )
}
