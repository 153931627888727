import React , { useEffect , useState } from 'react';

import { sortByUpdateDate } from '../../../../../utils/utils';

import ClientRow from './ClientRow';
import Modale from '../../../../partials/Modale';

import '../../AdminPartials/users.scss';
import EditUser from '../../AdminPartials/EditUser';

export default function ClientsList(props) {
  const { setActivePage , context , dispatch , match } = props;

  const [ allClients , setAllClients ] = useState();
  const [ search , setSearch ] = useState();

  const [ displayAddClientModale , setDisplayAddClientModale ] = useState(false);
  const [ clientId , setClientId ] = useState();

  useEffect(() => {
    setActivePage("clients");
    // eslint-disable-next-line 
  }, [])

  function editClient(id) {
    setClientId(id);
    setDisplayAddClientModale(true);
    dispatch({
      type : 'GET_USER',
      payload : null
    });
  }

  function closeClientModale() {
    setClientId();
    setDisplayAddClientModale(false);
    dispatch({
      type : 'GET_USER',
      payload : null
    });
  }

  useEffect(() => {
    if (context.users.usersList) {
      setAllClients(sortByUpdateDate(context.users.usersList.filter(user => user.type === "client")))
    }
  }, [context.users.usersList]);

  useEffect(() => {
    if (context.users.usersList) {
      if (search) {
        var newList = context.users.usersList.filter(user => user.type === "client").filter(user => {
          if (user.Email) {
            if (
              user.Email.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.Name) {
            if (
              user.Name.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.FirstName) {
            if (
              user.FirstName.toLowerCase().includes(search.toLowerCase()) ||
              user.LastName.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.LegalRepresentativeFirstName) {
            if (
              user.LegalRepresentativeFirstName.toLowerCase().includes(search.toLowerCase()) ||
              user.LegalRepresentativeLastName.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        });
        setAllClients(sortByUpdateDate(newList));
      } else {
        setAllClients(sortByUpdateDate(context.users.usersList.filter(user => user.type === "client")))
      }
    }
  }, [search , context.users.usersList]);

  function renderClientsList() {
    if (allClients) {
      return allClients.map((client, i) => (<ClientRow key={i + client._id} client={client} editClient={editClient} dispatch={dispatch} path={match.path}/>))
    }
  }

  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="row">
          <div className="col-1">

            <div className="header">
              <h2>Clients</h2>
              <div className="actions">
                <input
                  placeholder="Rechercher un client"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search || ''}
                />
                <button
                  onClick={() => setDisplayAddClientModale(true)}
                  className="btn primary">
                  Nouveau
                </button>
              </div>
            </div>

            <div className="list-container">

              <div className="user-row client label-row bold">
                <div className="label uppercase">
                  <p>Prénom</p>
                </div>
                <div className="label uppercase">
                  <p>Nom</p>
                </div>
                <div className="label uppercase">
                  <p>E-mail</p>
                </div>
                <div className="label uppercase">
                  <p>Téléphone</p>
                </div>
                <div className="label uppercase">
                  <p>Chiffre d'affaires</p>
                </div>
                <div className="label uppercase">
                  <p>Retard</p>
                </div>
              </div>

              {renderClientsList()}
            </div>

          </div>
          <Modale
            modaleIsOpen={displayAddClientModale}
            modaleToggle={closeClientModale}
            className={"user-modale"}
            >
            {displayAddClientModale && <EditUser type={"client"} closeModale={closeClientModale} userId={clientId} context={context} dispatch={dispatch}/>}
          </Modale>
        </div>
      </div>
    </div>
  )
}

