import { getData , postData , putData , deleteData , getFile } from './index';

import {
  GET_QUOTES,
  GET_QUOTE,
  ERROR_QUOTES,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  SEND_QUOTE,
  GET_QUOTES_FILTERED
} from "./types"

export async function getQuotesAction(dispatch) {
  let url = "/quote";
  var quotes;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    quotes = response.quotes
  });

  dispatch({
    type : GET_QUOTES,
    payload : quotes
  });

};

export async function getQuotesFilteredAction(dispatch , data ) {
  let url = "/quote/filtered";
  var receipt;

  await postData( GET_QUOTES_FILTERED , ERROR_QUOTES ,  url , dispatch, data , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : GET_QUOTES_FILTERED,
    payload : receipt
  });

};



export async function getQuoteAction(dispatch , id) {
  let url = "/quote/" + id ;
  var quotes;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    quotes = response.quote
  });

  dispatch({
    type : GET_QUOTE,
    payload : quotes
  });

};

export async function sendQuoteMailAction(dispatch , id) {
  let url = `/quote/${id}/mail`;
  var quotes;

  await getData(ERROR_QUOTES, url, dispatch , true ).then((response)=>{
    quotes = response
  });

  dispatch({
    type : SEND_QUOTE,
    payload : quotes
  });

  dispatch({
    type: 'SET_TOAST',
    payload: {
      message: "La proposition a bien été envoyée",
      type: "success"
    }
  });

};


export async function createQuoteAction(dispatch , data ) {
  let url = "/quote";
  var quote;

  await postData( CREATE_QUOTE , ERROR_QUOTES ,  url , dispatch, data , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : CREATE_QUOTE,
    payload : quote
  });

};

export async function getQuoteFileAction(dispatch , id , number){
  let url = `/quote/${id}/pdf`
  let quote;
  await getFile( ERROR_QUOTES , url, dispatch , true).then((response)=>{
    quote = response
    var fileURL = URL.createObjectURL(quote);
    var downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = `Proposition n°${number} - Ma petite Conciergerie.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
};


export async function updateQuoteAction(dispatch , id , data ) {
  let url = "/quote/" + id;
  var quote;

  await putData( UPDATE_QUOTE , ERROR_QUOTES ,  url, dispatch, data , true ).then((response)=>{
    quote = response
  });

  dispatch({
    type : UPDATE_QUOTE,
    payload : quote
  });

};

export async function deleteQuoteAction(dispatch , id) {
  let url = "/quote/" + id;
  var quotes;

  await deleteData(ERROR_QUOTES, url, dispatch, true ).then((response)=>{
    quotes = response
  });

  console.log(quotes);
  dispatch({
    type : DELETE_QUOTE,
    payload : id
  });

};
