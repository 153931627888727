import React , { useEffect , useState } from 'react';
import Select from "react-select";
import { customSort } from '../../../../../utils/styles';
import { sortByUpdateDate , filterByDatePeriodKey , sortByCreditPrice } from '../../../../../utils/utils';

import CreditRow from './CreditRow';

import './credits.scss';
import PeriodPicker from '../../AdminPartials/PeriodPicker';
import { getCreditsAction } from '../../../../../actions/creditsActions';

export default function CreditsList(props) {

  const { setActivePage , context , match , dispatch } = props;

  const sortOptions = [
    { value : "date" , label : "Trier par date (les plus récentes)" },
    { value : "dateReverse" , label : "Trier par date (les moins récentes)" },
    { value : "priceCroissant" , label : "Trier par montant croissant" },
    { value : "priceDécroissant" , label : "Trier par montant décroissant" }
  ];

  const [ allCredits , setAllCredits ] = useState();
  const [ initCredits , setInitCredits ] = useState();
  const [ search , setSearch ] = useState();
  const [ startDate , setStartDate ] = useState();
  const [ endDate , setEndDate ] = useState();
  const [ sort , setSort ] = useState(sortOptions[0]);
  const [ initDates ,  setInitDate ] = useState();

  useEffect(() => {
    setActivePage("credits");
    getCreditsAction(dispatch);
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (context.credits.creditsList && context.users.usersList) {
      var start = new Date();
      var end;
      context.credits.creditsList.forEach(credit => {
        //set periode for all
        var creditDate = new Date(credit.createdAt)
        if (creditDate < start) start = creditDate;
        if (end && creditDate > end) end = creditDate;
        if (!end) end = creditDate;
        if (context.users && context.users.usersList && credit.invoice && credit.invoice.client) {
          let client = context.users.usersList.find(user => user._id === credit.invoice.client);
          if (client) {
            credit.invoice.clientName = client.LastName ? `${client.FirstName} ${client.LastName}` : `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`
          }
          let provider = context.users.usersList.find(user => user._id === credit.invoice.provider);
          if (provider) {
            credit.invoice.providerName = provider.LastName ? `${provider.FirstName} ${provider.LastName}` : `${provider.LegalRepresentativeFirstName} ${provider.LegalRepresentativeLastName}`
          }
          if (credit.content) {
            credit.content.forEach(product => {
              product.providers.forEach( productProvider => {
                var found = context.users.usersList.find(user => user._id === productProvider._id);
                productProvider.tax = found.withTVA ? product.tax : 0 
              })
            })
          }
        }
      });
      setStartDate(start);
      setEndDate(end);
      setInitDate({ start: start , end: end  })
      setInitCredits(context.credits.creditsList)
      setAllCredits(sortByUpdateDate(context.credits.creditsList))
    }
  }, [context.credits.creditsList , context.users]);

  useEffect(() => {
    if (initCredits) {
      if (search) {
        var newList = initCredits.filter(credit =>
          credit.invoice.clientName.toLowerCase().includes(search.toLowerCase()) ||
          credit.invoice.providerName.toLowerCase().includes(search.toLowerCase() ||
          credit.number.toString().toLowerCase().includes(search.toLowerCase())
        ))
        setAllCredits(filterByDatePeriodKey("createdAt" ,newList , startDate , endDate));
      } else {
        setAllCredits(filterByDatePeriodKey("createdAt" ,initCredits , startDate , endDate));
      }
    }
  }, [search , initCredits , startDate , endDate ]);

  function resetFilters() {
    setStartDate(initDates.start);
    setEndDate(initDates.end);
    setSearch();
    setSort(sortOptions[0])
  }

  function renderCreditsList() {
    if (allCredits) {
      var filtred = allCredits; 
      if (sort.value === "date") {
        filtred = sortByUpdateDate(allCredits)
      } if (sort.value === "dateReverse") {
        filtred = sortByUpdateDate(allCredits).reverse();
      } else if (sort.value === "priceCroissant") {
        filtred = sortByCreditPrice(allCredits).reverse();
      } else if (sort.value === "priceDécroissant") {
        filtred = sortByCreditPrice(allCredits)
      }
      return filtred.map((credit, i) => (<CreditRow key={i + credit._id} credit={credit} dispatch={dispatch} users={context.users.usersList} path={match.path}/>))
    }
  }

  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="filters">

          <div className="col-4">
            <div className="header">
              <h2>Filtres</h2>
              <button
                className={"btn primary"}
                onClick={() => resetFilters()}
                >Réinitialiser
              </button>
            </div>
            <PeriodPicker
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              />
          </div>

          <div className="col-4">
            <Select
              styles={customSort}
              isSearchable={false}
              value={sort}
              options={sortOptions}
              onChange={(client) => setSort(client)}
            />
          </div>
          <div className="col-4">
            <input
              placeholder="Rechercher..."
              onChange={(e) => setSearch(e.target.value)}
              value={search || ''}
            />
          </div>

          </div>
        <div className="row">
          <div className="col-1">

            <div className="header">
              <h2>Avoirs</h2>
              <div className="actions">
              </div>
            </div>

            <div className="list-container">

              <div className="credit-row label-row bold">

                <div className="credit-label uppercase">
                  <p>N°</p>
                </div>

                <div className="credit-label uppercase">
                  <p>FACTURE</p>
                </div>

                <div className="credit-label uppercase">
                  <p>CLIENT</p>
                </div>

                <div className="credit-label uppercase">
                  <p>Indépendant</p>
                </div>

                <div className="credit-label uppercase">
                  <p>Date</p>
                </div>

                <div className="credit-label uppercase">
                <p>MONTANT</p>
                </div>

                <div className="icon">
                </div>

              </div>

              {renderCreditsList()}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

