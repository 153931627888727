import React from 'react';
import { Link } from 'react-router-dom';
import { formatPhone } from '../../../../../utils/utils';

export default function ClientRow({ client }) {

  return (
    <div className="user-row">
      <Link to={"/admin/client/" + client._id} className="user-row client border">

        <div className="label uppercase">
          <p>{client.LegalRepresentativeFirstName ? client.LegalRepresentativeFirstName : client.FirstName }</p>
        </div>

        <div className="label uppercase">
          <p>{client.LegalRepresentativeLastName ? client.LegalRepresentativeLastName : client.LastName }</p>
        </div>

        <div className="label">
          <p>{client.Email}</p>
        </div>

        <div className="label">
          <p>{formatPhone(client.tel)}</p>
        </div>

        <div className="label">
          <p>{client.ca > 0 && `${client.ca.toFixed()} €` }</p>
        </div>

        <div className="label">
          <p>{client.late > 0 && `${client.late.toFixed()} €` }</p>
        </div>

      </Link>
    </div>
  )
}
