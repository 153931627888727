import React , { useState } from 'react';

import { addTVA , removeTVA , calcReceiptPrice, calcDiscount } from '../../../../../utils/utils';

import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';

import Modale from '../../../../partials/Modale';

import pen from '../../../../../assets/images/icons/pen.svg';
import deleteIcon from '../../../../../assets/images/icons/delete.svg';
import userPlus from '../../../../../assets/images/icons/user-plus.svg';

import ProviderRow from './ProviderRow';

export default function EditReceiptProductRow({ product , index , handleChangeProduct , removeProduct , providerOptions , productList , isPreview }) {

  const [ displayDescriptionModale , setDisplayDescriptionModale ] = useState(false);
  const [ addProvider , setAddProvider ] = useState(false);

  function totalsAreEquals() {
    let totalProviders = parseFloat(calcReceiptPrice(productList).totalProduct[index].priceTTC.toFixed(2)) ;
    let totalProduct = (product.quantity * addTVA(product.priceHT , product.tax)).toFixed(2);
    if (totalProviders === calcDiscount(parseFloat(totalProduct) , product.discount)) {
      return true;
    } else {
      return false;
    }
  }

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  function renderProductProviders() {
    if (product.providers && product.providers.length) {
      return product.providers.map((provider ,i ) => <ProviderRow
        productProviders={product.providers}
        setAddProvider={setAddProvider}
        providerOptions={providerOptions}
        provider={provider}
        product={product}
        isPreview={isPreview}
        handleChangeProduct={handleChangeProduct}
        productIndex={index}
        key={i +'Provider'}
        index={i} /> )
    }
  }

  return (
    <>
      <div id={"product" + index} className="edit-product-row uppercase">
        <div className="name bold">
          <p>{product.name}</p>
          {!isPreview &&
            <div className="btn-container">
              <button onClick={() => setAddProvider(true)}>
                <img src={userPlus} alt="add-user"/>
              </button>
              <button onClick={() => setDisplayDescriptionModale(true)}>
                <img src={pen} alt="edit"/>
              </button>
              <button onClick={() => removeProduct(index)}>
                <img src={deleteIcon} alt="delete"/>
              </button>
            </div>
          }
        </div>
        <div className="value"><p>{product.tax} %</p></div>

        <div className="quantity value">
          {!isPreview &&
            <input
              type="number"
              step="any"
              id={"quantity" + index}
              onChange={(e) => handleChangeProduct(index , "quantity" , e.target.value ) }
              defaultValue={product.quantity}
              />
          }
          {isPreview && <p>{product.quantity}</p>}
        </div>

        <div className="value">
          {!isPreview &&
            <input
              type="text"
              id={"unit" + index}
              className="uppercase"
              onChange={(e) => handleChangeProduct(index , "unit" , e.target.value )}
              defaultValue={product.unit}
              />
          }
          {isPreview && <p>{product.unit}</p>}
        </div>

        <div className="quantity value">
          {!isPreview &&
            <input
              type="number"
              step="any"
              id={"priceHT" + index}
              onChange={(e) => handleChangeProduct(index , "priceHT" , removeTVA( e.target.value , product.tax))}
              defaultValue={addTVA(product.priceHT, product.tax).toFixed(2)}
              />
          }
          {isPreview && <p>{addTVA(product.priceHT, product.tax).toFixed(2)}</p>}
        </div>

        <div className="value">
          {!isPreview &&
            <input
              type="number"
              max="100"
              min="0"
              id={"discount" + index}
              onChange={(e) => handleChangeProduct( index , "discount" , e.target.value )}
              value={product.discount}
              />
          }
          {isPreview && <p>{product?.discount && `${product?.discount} %` }</p>}
        </div>

        <div className={`value total bold ${totalsAreEquals() ? "success" : "error"}`}>
          <p>{calcDiscount(product.quantity * addTVA(product.priceHT, product.tax) , product.discount ).toFixed(2)} €</p>
        </div>
      </div>

      {renderProductProviders()}
      {addProvider && <ProviderRow
        providerOptions={providerOptions}
        isPreview={isPreview}
        setAddProvider={setAddProvider}
        productProviders={product.providers} 
        product={product}
        handleChangeProduct={handleChangeProduct}
        productIndex={index}
        index={product.providers.length + 1 }
        addProvider={true} />}

      <Modale
        modaleIsOpen={displayDescriptionModale}
        modaleToggle={setDisplayDescriptionModale}
        className={"quote-modale"}
        >
        <div className="form-container">

          <label htmlFor="name">Dénomination</label>
          <input
            name="name"
            onChange={(e) => handleChangeProduct( index , "name" , e.target.value)}
            value={product.name}
          />

          <label htmlFor="tax">TVA</label>
          <input
            name="tax"
            onChange={(e) => handleChangeProduct( index , "tax" , e.target.value)}
            value={product.tax}
          />

          <label htmlFor="description">Description</label>
          <ReactQuill 
            theme={'snow'}
            onChange={(val => handleChangeProduct( index , "description" , val))}
            value={product.description || ''}
            modules={modules}
            formats={formats}
            placeholder={"Description"}
          />
          <div className="btn-container">
            <button className="btn primary"
              onClick={() => setDisplayDescriptionModale(false)}>
              Enregistrer
            </button>
          </div>        
        </div>

      </Modale>
    </>
  )
}
