import React , { useState } from 'react';

import { addTVA, calcDiscount } from '../../../../../utils/utils';

import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';

import Modale from '../../../../partials/Modale';

import pen from '../../../../../assets/images/icons/pen.svg';
import deleteIcon from '../../../../../assets/images/icons/delete.svg';

export default function EditQuoteProductRow(props) {
  const { product , index , handleChangeProduct , removeProduct } = props;

  const [ displayDescriptionModale , setDisplayDescriptionModale ] = useState(false);

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

  return (
    <>
      <div id={"product" + index} className="edit-product-row uppercase">
        <div className="name bold">
          <p>{product.name}</p>
          <div className="btn-container">
            <button onClick={() => setDisplayDescriptionModale(true)}>
              <img src={pen} alt="edit"/>
            </button>
            <button onClick={() => removeProduct(index)}>
              <img src={deleteIcon} alt="delete"/>
            </button>
          </div>
        </div>
        <div className="value">
        <input
          type="number"
          step="any"
          id={"quantity" + index}
          onChange={(e) => handleChangeProduct( index , "quantity" , e.target.value)}
          value={product.quantity}
          />
        </div>
        <div className="value">
          <input
            type="text"
            id={"unit" + index}
            onChange={(e) => handleChangeProduct( index , "unit" , e.target.value )}
            defaultValue={product.unit}
            />
        </div>
        <div className="value">
          <input
            type="number"
            step="any"
            id={"price" + index}
            onChange={(e) => handleChangeProduct( index , "priceTTC" , e.target.value )}
            defaultValue={addTVA(product.priceHT, product.tax).toFixed(2)}
            />
        </div>
        <div className="value">
          <input
            type="number"
            max="100"
            min="0"
            id={"discount" + index}
            onChange={(e) => handleChangeProduct( index , "discount" , e.target.value )}
            value={product.discount}
            />
        </div>
        <div className="value total bold">
          <p>{calcDiscount(addTVA(product.priceHT, product.tax) * product.quantity, product.discount ).toFixed(2)} €</p>
        </div>
      </div>
      <Modale
        modaleIsOpen={displayDescriptionModale}
        modaleToggle={setDisplayDescriptionModale}
        className={"quote-modale"}
        >
        <div className="form-container">

          <label htmlFor="name">Dénomination</label>
          <input
            name="name"
            onChange={(e) => handleChangeProduct( index , "name" , e.target.value)}
            value={product.name}
          />

          <label htmlFor="tax">TVA</label>
          <input
            name="tax"
            onChange={(e) => handleChangeProduct( index , "tax" , e.target.value)}
            value={product.tax}
          />

          <label htmlFor="description">Description</label>
          <ReactQuill 
            theme={'snow'}
            onChange={(val => handleChangeProduct( index , "description" , val))}
            value={product.description || ''}
            modules={modules}
            formats={formats}
            placeholder={"Description"}
          />

          <div className="btn-container">
            <button className="btn primary"
              onClick={() => setDisplayDescriptionModale(false)}>
              Enregistrer
            </button>
          </div>            
        </div>
      </Modale>
    </>
  )
}
