import React from 'react';
import { Link } from 'react-router-dom';
import { formatPhone } from '../../../../../utils/utils';

export default function ProviderRow({ provider }) {
  return (
    <div className="user-row">

      <Link to={"/admin/provider/" + provider._id} className="user-row label-row border">
        <div className="label uppercase">
          <p>
            {provider.Name ? provider.Name : `${provider.FirstName} ${provider.LastName}` }
          </p>
        </div>
        <div className="label">
          <p>{provider.Email}</p>
        </div>
        <div className="label">
          <p>{formatPhone(provider.tel)}</p>
        </div>
        <div className="label">
          <p>{provider.ca > 0 && `${provider.ca.toFixed(2)} €` }</p>
        </div>
      </Link>

    </div>
  )
}
