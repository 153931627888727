import React from 'react';
import { useSpring, animated } from 'react-spring';
import { fnFormatFixed } from '../../../../../../utils/utils';

export default function SpringNumber({ value }) {

  const animatedNumber = useSpring({ number: value , from: { number: 0 } });

  return (
    <animated.span>{animatedNumber.number.interpolate(x => fnFormatFixed(x))}</animated.span>
  )
}
