import { postData , getData , getFile } from './index';

import {
  IS_AUTH,
  ERROR_AUTH,
  USER_AUTH,
  GET_LIST,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_DASHBOARD_BY_PERIOD,
  GET_DASHBOARD_CA,
  GET_ATTESTATIONS
} from "./types"

export async function loginAction(dispatch , obj){
  let url = "/auth/login";
  var login;

  await postData(IS_AUTH , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    login = response
  });

  localStorage.setItem("token" , login.token);

  dispatch({
    type : IS_AUTH,
    payload : true
  });

};

export async function getUserAuthAction(dispatch) {
  let url = "/profile/";
  var user;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    user = response.user
  });

  dispatch({
    type : USER_AUTH,
    payload : user
  });

};

export async function getAttestationsListAction(dispatch) {
  let url = "/profile/attestation/list";
  var attestations;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    attestations = response.attestations
  });

  dispatch({
    type : GET_ATTESTATIONS,
    payload : attestations
  });

};

export async function getAttestationFileAction(dispatch , year ) {
  let url = `/profile/attestation/${year}/pdf`;
  var attestation;

  await getFile(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    attestation = response;
    var fileURL = URL.createObjectURL(attestation);
    window.open(fileURL);
  });

};

export async function getListAction(dispatch) {
  let url = "/utils/list/";
  var lists;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    lists = response.lists
  });

  dispatch({
    type : GET_LIST,
    payload : lists
  });

};

export async function forgotPasswordAction(dispatch , obj){
  let url = "/auth/forgot-password";
  var mailSend;

  await postData(FORGOT_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    mailSend = response
  });

  dispatch({
    type : FORGOT_PASSWORD,
    payload : mailSend
  });
};

export async function resetPasswordAction(dispatch , obj){
  let url = "/auth/reset-password/" + obj.token;
  var reset;

  await postData(RESET_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    reset = response
  });
  
  dispatch({
    type : RESET_PASSWORD,
    payload : reset
  });
  
};


export async function getDashboardByPeriodAction(dispatch , obj){
  let url = "/utils/dashboard/filtered";
  var dashboard;

  await postData( GET_DASHBOARD_BY_PERIOD , ERROR_AUTH, url, dispatch , obj, true ).then((response)=>{
    dashboard = response
  });

  dispatch({
    type : GET_DASHBOARD_BY_PERIOD,
    payload : dashboard
  });

};

export async function getDashboardCaAction(dispatch ){
  let url = "/utils/dashboard";
  var dashboard;

  await getData( ERROR_AUTH, url, dispatch , true ).then((response)=>{
    dashboard = response
  });

  dispatch({
    type : GET_DASHBOARD_CA,
    payload : dashboard
  });

};