import React , { useEffect , useState , useCallback } from 'react';
import useDimensions from "react-use-dimensions";
import { Switch, Route , useLocation } from "react-router-dom";
import { useTransition, animated } from 'react-spring';

import { useGlobalContext } from '../../context/GlobalContext';
import { getUsersAction } from '../../../actions/userActions';
import { getAttestationsListAction } from '../../../actions/authActions';
import { getReceiptsByClientAction } from '../../../actions/receiptsActions';
import { getCreditsByProviderAction } from '../../../actions/creditsActions';

import User from '../ADMIN/AdminPartials/User';
import InvoicesList from '../ADMIN/AdminPages/Invoices/InvoicesList';
import ReceiptsListClient from '../ADMIN/AdminPages/Receipts/ReceiptsListClients';
import Nav from '../ADMIN/AdminPartials/Nav';
import Toaster from '../../partials/Toaster';
import AttestationsList from './Attestations/AttestationsList';
import CreditsList from '../ADMIN/AdminPages/Credits/CreditsList';
import MobilePage from '../MOBILE/MobilePage';
import EditReceipt from '../ADMIN/AdminPages/Receipts/EditReceipt';

export default function MemberRouter(props) {

  const { logout , match } = props;

  const [ context, dispatch ] = useGlobalContext();
  const [ref, { width }] = useDimensions();

  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(0, 500px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0px, -500px,0)'},
  })

  var token = localStorage.getItem('token');

  const [ activePage , setActivePage ] = useState();

  const getDatas = useCallback(() => {
      getUsersAction(dispatch);
  },[dispatch],)

  useEffect(() => {
    if(!token) {
      logout();
    } else {
      getDatas();
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (context.auth.userAuth && context.auth.userAuth.type && context.auth.userAuth.type === 'client') {
      getReceiptsByClientAction(dispatch , context.auth.userAuth._id );
      getAttestationsListAction(dispatch);
    }
    if (context.auth.userAuth && context.auth.userAuth.type && context.auth.userAuth.type === 'provider') {
      getCreditsByProviderAction(dispatch , context.auth.userAuth._id );
    }
  // eslint-disable-next-line
  },[context.auth.userAuth]);

  return (
    <>

    <div ref={ref}>
      {width >= 1024 ?
        <div className="page-container">
        <Nav
          path={match.path}
          activePage={activePage}
          logout={logout}/>
          {transitions.map(({ item: location, props, key }) => (
            <animated.div key={key} style={props}>
              <Switch location={location}>

                <Route path={`${match.path}/receipts-client/:userId`} render={
                  (props) => <ReceiptsListClient
                  context={context}
                  dispatch={dispatch}
                  setActivePage={setActivePage}
                  {...props}
                  />
                }/>

                <Route path={`${match.path}/client/:userId`} render={
                  (props) => <User
                  type={"client"}
                  context={context}
                  dispatch={dispatch}
                  setActivePage={setActivePage}
                  {...props}
                  />
                }/>

                {context.auth.attestations &&
                  <Route path={`${match.path}/attestations`} render={
                    (props) => <AttestationsList
                    context={context}
                    dispatch={dispatch}
                    setActivePage={setActivePage}
                    {...props}
                    />
                  }/>
                }

              <Route path={`${match.path}/credits`} render={
                (props) => <CreditsList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

                <Route path={`${match.path}/provider/:userId`} render={
                  (props) => <User
                  type={"provider"}
                  context={context}
                  dispatch={dispatch}
                  setActivePage={setActivePage}
                  {...props}
                  />
                }/>

              <Route path={`${match.path}/receipt-view/:receiptId`} render={
                (props) => <EditReceipt
                context={context}
                dispatch={dispatch}
                isPreview={true}
                path={match.path}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

                <Route path={`${match.path}/invoices/:userId`} render={
                  (props) => <InvoicesList
                  context={context}
                  dispatch={dispatch}
                  setActivePage={setActivePage}
                  {...props}
                  />
                }/>

                <Route path={`${match.path}/credits/:userId`} render={
                  (props) => <CreditsList
                  context={context}
                  dispatch={dispatch}
                  setActivePage={setActivePage}
                  {...props}
                  />
                }/>

              </Switch>
            </animated.div>
          ))}
          <Toaster toast={context.users.toast} dispatch={dispatch}/>
        </div>
        :
        <>
        <MobilePage />
        </>
      }
    </div>
    </>
  )
}
