import React , { useEffect , useState } from 'react';
import Select from "react-select";
import PeriodPicker from '../../AdminPartials/PeriodPicker';
import { customSelect } from '../../../../../utils/styles';
import { getDashboardByPeriodAction , getDashboardCaAction} from '../../../../../actions/authActions';

import { IoMdTrendingUp , IoMdCheckmark } from "react-icons/io";
import { BsExclamation } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";

import './Dashboard.scss';
import BarChart from './Partials/BarChart';
import SpringNumber from './Partials/SpringNumber';

export default function Dashboard({ setActivePage , context , dispatch }) {

  const [ selectedProvider , setSelectedProvider ] = useState();
  const [ displayPaid , setDisplayPaid ] = useState(false);

  const [ startDate , setStartDate ] = useState();
  const [ endDate , setEndDate ] = useState();

  useEffect(() => {
    setActivePage("dashboard");
    let year = new Date();
    let start = new Date(`01/01/${year.getFullYear()}`);
    let end = new Date(`12/31/${year.getFullYear()}`);
    setStartDate(start);
    setEndDate(end);
    getDashboardCaAction(dispatch);
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (startDate && endDate && context.auth.userAuth) {
      getDashboardByPeriodAction(dispatch , { startDate : startDate , endDate: endDate });
    }
    // eslint-disable-next-line 
  }, [startDate , endDate , context.auth.userAuth ]);

  useEffect(() => {
    if (context.auth.dashboard && context.auth.dashboard.caByProvider ) setSelectedProvider(context.auth.dashboard.caByProvider[0])
  }, [context.auth.dashboard , setSelectedProvider]);

  return (
    <div className="router-transition-container">
      <div className="page-content dashboard">
        <div className="row-2">

          <div className="col-content">

            {context.auth.dashboard && selectedProvider &&
              <div className="box">
                <div className="select">
                  <Select
                    styles={customSelect}
                    value={selectedProvider}
                    placeholder={'Sélectionner un fournisseur'}
                    options={context.auth.dashboard.caByProvider}
                    onChange={(provider) => setSelectedProvider(provider)}/>
                </div>
                <BarChart data={selectedProvider.value}/>
              </div>
            }

          {context.auth.dashboard && context.auth.dashboard.receiptsTotals && 
            <div className="box">
              <div className="row">
                <div className="col-2">
                  <PeriodPicker
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    />
                </div>
              </div>

                  <h2>Reçus</h2>
                    {displayPaid ?
                      <div className="receipts-row" onClick={() => setDisplayPaid(false)}>
                        <div className="receipt-value bar">
                          <div className="icon paid">
                            <span className="bold">CB</span>
                          </div>
                          <p>
                            CB<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.paid.cb}/> €</span>
                          </p>
                        </div>
                        <div className="receipt-value bar">
                          <div className="icon paid">
                            <span className="bold">CEPA</span>
                          </div>
                          <p>
                            SEPA<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.paid.sepa}/> €</span>
                          </p>
                        </div>
                        <div className="receipt-value">
                          <div className="icon paid">
                            <BiTransfer size={24} color={'#BADC62'}/>
                          </div>
                          <p>
                            VIREMENT<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.paid.transfer}/> €</span>
                          </p>
                        </div>
                      </div>
                    :
                      <div className="receipts-row">
                        <div className="receipt-value bar">
                          <div className="icon global">
                            <IoMdTrendingUp size={24} color={'#000000'}/>
                          </div>
                          <p>
                            GLOBAL<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.global}/> €</span>
                          </p>
                        </div>
                        <div className="receipt-value bar" onClick={() => setDisplayPaid(false)}>
                          <div className="icon paid">
                            <IoMdCheckmark size={24} color={'#BADC62'}/>
                          </div>
                          <p>
                            PAYÉ<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.paid.total}/> €</span>
                          </p>
                        </div>
                        <div className="receipt-value">
                          <div className="icon late">
                            <BsExclamation size={28} color={'#580014'}/>
                          </div>
                          <p>
                            RETARD<br/>
                            <span className="bold"><SpringNumber value={context.auth.dashboard.receiptsTotals.late}/> €</span>
                          </p>
                        </div>
                      </div>
                    }
            </div>
          }
          </div>
                  
          <div className="col-aside">
            {context.auth.dashboard && context.auth.dashboard.topFiveProducts &&
              <div className="box top-five">
                <h2>TOP 5 PRODUITS</h2>
                {context.auth.dashboard.topFiveProducts.sort((a , b ) => b.total - a.total ).map((product, i ) => (<div key={'product' +i } className="top-row"><p>{product.name}</p> <p className="bold"><SpringNumber value={product.total}/> €</p></div>))}
              </div>
            }

            {context.auth.dashboard && context.auth.dashboard.topFiveClients &&
              <div className="box top-five">
                <h2>TOP 5 CLIENTS</h2>
                {context.auth.dashboard.topFiveClients.sort((a , b ) => b.total - a.total ).map((product, i ) => (<div key={'product' +i } className="top-row"><p>{product.name}</p> <p className="bold"><SpringNumber value={product.total}/> €</p></div>))}
              </div>
            }

          </div>

        </div>
      </div>
    </div>
  )
}
