import React , { useEffect , useState } from 'react';
import { useParams , Prompt } from 'react-router-dom';
import { removeTVA } from '../../../../../utils/utils';
import Select from "react-select";

import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';

import DatePicker , { registerLocale } from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want

import { getQuoteAction , createQuoteAction , updateQuoteAction , sendQuoteMailAction , getQuoteFileAction } from "../../../../../actions/quotesActions";

import countriesOptions from '../../../../..//utils/countries.json'

import { customSelect } from '../../../../../utils/styles';
import { calcPrice } from '../../../../../utils/utils';

import './quotes.scss';
import EditQuoteProductRow from './EditQuoteProductRow';

export default function EditQuote({ setActivePage , context , dispatch }) {

  registerLocale("fr", fr);

  const today = new Date()

  let initEmail = `Comme promis, je vous transmets notre proposition commerciale. <br/>Pour la voir, cliquez directement sur le lien ci-dessous.<br/>Afin d'officialiser notre collaboration, merci de nous la retourner datée, paraphée et signée, accompagnée de la mention "bon pour accord".<br/>A très bientôt pour organiser votre future prestation.`

  const params = useParams();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'indent': '-1'}, {'indent': '+1'}],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  const [ notes , setNotes] = useState("");
  const [ state , setState ] = useState("draft");
  const [ quote , setQuote ] = useState();
  const [ mail , setMail ] = useState(initEmail);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ validUntilDate , setValidUntilDate ] = useState(new Date(today.setMonth(today.getMonth()+1)));
  const [ sendDate , setSendDate ] = useState(new Date());

  const [ isCreated , setIsCreated ] = useState(false)
  
  const [ clientOptions , setClientOptions ] = useState();
  const [ seletedClient , setSeletedClient ] = useState();

  const [ productsOptions , setProductsOptions ] = useState();
  const [ productToAdd , setProductToAdd ] = useState();

  const [ productList , setProductList ] = useState([]);


  useEffect(() => {
    setActivePage("quotes");
    
    if (params.quoteId) {
      getQuoteAction( dispatch , params.quoteId)
      setIsCreated(true)
    }

    return () => dispatch({
      type: 'GET_QUOTE',
      payload: null
    })
    // eslint-disable-next-line 
  }, []);

  useEffect(( ) => {
    if (context.quotes.quote) {
      setNotes(context.quotes.quote.description || '');
      setQuote(context.quotes.quote);
      setState(context.quotes.quote.state);
      setProductList(context.quotes.quote.content);
      if (context.quotes.quote.email) setMail(context.quotes.quote.email);
      if (context.quotes.quote.sendDate) {
        setSendDate(new Date(context.quotes.quote.sendDate))
      }
      if (context.quotes.quote.validUntilDate) {
        setValidUntilDate(new Date(context.quotes.quote.validUntilDate));
      } else {
        var createdAt = new Date(context.quotes.quote.createdAt);
        setValidUntilDate(new Date(createdAt.setMonth(createdAt.getMonth()+1)));
      }
    }
  }, [context.quotes.quote]);

  useEffect(() => {

    if (context.quotes.message === "Quote successfully updated!" || context.quotes.message === "quote saved") {
      
      setIsSaved(true);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La proposition a bien été enregistrée",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_QUOTES',
        payload: null
      });

    }

    if (context.quotes.message === "Quote mail send") {
      
      setIsSaved(true);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La proposition a bien été envoyée",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_QUOTES',
        payload: null
      });

    }

  },[context.quotes.message , dispatch ]);

  useEffect(() => {
    if (context.products.productsList) {
      let options = [];
      context.products.productsList.forEach(product => {
        options.push({
          label: product.name,
          value: product
        })
      })
      setProductsOptions(options);
    }
  }, [context.products.productsList]);

  useEffect(() => {
    if (context.users.usersList) {
      let options = [];
      var clientList = context.users.usersList.filter(user => user.type === "client")
      clientList.forEach(client => {
        options.push({
          label: client.FirstName ? `${client.FirstName} ${client.LastName}` : `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`,
          value: client
        })
      })
      setClientOptions(options)
    }
  }, [context.users.usersList]);

  useEffect(() => {
    if (quote && quote.client && clientOptions) {
      setSeletedClient(clientOptions.find(client => client.value._id === quote.client));
    }
  },[quote, clientOptions]);

  useEffect(() => {
    if(seletedClient?.value) {
      setIsSaved(false);
      if (!context.quotes?.quote?.email) {
        let firstName = seletedClient.value.FirstName ? seletedClient.value.FirstName : seletedClient.value.LegalRepresentativeFirstName 
        setMail(firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() + ',<br/>' + initEmail)
      }
    }
    // eslint-disable-next-line
  },[seletedClient])

  function addProduct() {
    setIsSaved(false);
    var addToList = [...productList]
    var product = {...productToAdd }
    product.value.quantity = 1
    addToList.push(product.value);
    setProductList(addToList);
    setProductToAdd();
  }

  function handleChangeProduct(index , key , value ) {
    setIsSaved(false);
    let updatedList = [...productList];
    let updatedProduct = {...updatedList[index]}

    switch (key) {

      case "name":
        updatedProduct.name = value;
        break;

      case "discount":
        updatedProduct.discount = parseFloat(value);
        break;
        
      case "tax":
        updatedProduct.tax = value;
        break;

      case "unit":
        updatedProduct.unit = value;
        break;

      case "description":
        updatedProduct.description = value;
        break;

      case "quantity":
        updatedProduct.quantity = value;
        break;

      case "priceTTC":
        updatedProduct.priceHT = removeTVA(value , updatedProduct.tax)
        break;
    
      default:
        break;
    }
    updatedList[index] = updatedProduct
    setProductList(updatedList)
  }

  function removeProduct(index) {
    setIsSaved(false);
    let removedList = productList.filter((product , i ) => i !== index);
    setProductList(removedList)
  }

  function submitQuote() {
    if (seletedClient && productList.length) {
      let quoteData = {
        client: seletedClient.value._id,
        description: notes,
        content: productList,
        state: state,
        validUntilDate: validUntilDate,
        sendDate: sendDate ? sendDate.toISOString() : new Date().toISOString(),
        email: mail
      }
      if (isCreated) {
  
        updateQuoteAction( dispatch , quote._id ,  quoteData );
      } else {
        setIsCreated(true)
        createQuoteAction(dispatch , quoteData);
      }
    }
  }

  function renderQuoteHeader() {
    return (
      <div className="quote-container">

        <div className="quote-content">

          <div className="header">

            <h1>PROPOSITION COMMERÇIALE {context.quotes && context.quotes.quote && `N° ${context.quotes.quote.number}`}</h1>

            {seletedClient && productList.length > 0 && 
              <div className="quote-actions">

                <button className="btn primary" onClick={() => submitQuote()}>
                  Enregistrer
                </button>

                {context.quotes && context.quotes.quote && context.quotes.quote._id &&
                  <button className="btn primary" onClick={() => getQuoteFileAction(dispatch , context.quotes.quote._id , context.quotes.quote.number )}>
                    Aperçu
                  </button>
                }

                {isSaved &&
                  <button className={`btn ${state !== "draft" ? "success" : "primary"}`} onClick={() => sendQuoteMailAction(dispatch  , context.quotes.quote._id)}>
                    Envoyer
                  </button>
                }

              </div>

            }
          </div>

          <div className="quote-client">

            <div className="col-2">
              <h2>CLIENT</h2>
              <Select
                styles={customSelect}
                value={seletedClient}
                placeholder={'Sélectionner un client'}
                options={clientOptions}
                onChange={(client) => setSeletedClient(client)}
              />

              <h2>ADRESSE</h2>
              {seletedClient && seletedClient.value &&
                <>
                  {seletedClient.value.HeadquartersAddress && 
                    <p className="address">
                      {seletedClient.value.Name && <span>{seletedClient.value.Name}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.AddressLine1 && <span>{seletedClient.value.HeadquartersAddress.AddressLine1}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.AddressLine2 && <span>{seletedClient.value.HeadquartersAddress.AddressLine2}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.Region && <span>{seletedClient.value.HeadquartersAddress.Region}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.City && seletedClient.value.HeadquartersAddress.PostalCode && <span>{seletedClient.value.HeadquartersAddress.PostalCode} {seletedClient.value.HeadquartersAddress.City}</span>}<br/>
                      {seletedClient.value.HeadquartersAddress.Country && countriesOptions.find(item => item.value.toUpperCase() === seletedClient.value.HeadquartersAddress.Country).label}
                    </p>
                  }

                  {seletedClient.value.Address && 
                    <p className="address">
                      {seletedClient.value.Name && <span>{seletedClient.value.Name}<br/></span>}
                      {seletedClient.value.Address.AddressLine1 && <span>{seletedClient.value.Address.AddressLine1}<br/></span>}
                      {seletedClient.value.Address.AddressLine2 && <span>{seletedClient.value.Address.AddressLine2}<br/></span>}
                      {seletedClient.value.Address.Region && <span>{seletedClient.value.Address.Region}<br/></span>}
                      {seletedClient.value.Address.City && seletedClient.value.Address.PostalCode && <span>{seletedClient.value.Address.PostalCode} {seletedClient.value.Address.City}</span>}<br/>
                      {seletedClient.value.Address.Country && countriesOptions.find(item => item.value.toUpperCase() === seletedClient.value.Address.Country).label}
                    </p>
                  }

                  <h2>EMAIL</h2>
                  {seletedClient.value.email && <p className="address">{seletedClient.value.email}</p>}

                  <h2>ENTREPRISE</h2>
                  <p className="address">
                    {seletedClient.value.companyType && <span>{seletedClient.value.companyType}<br/></span>}
                    {seletedClient.value.siret && <span>siret : {seletedClient.value.siret}<br/></span>}
                  </p>
                </>
              }

            </div>

            <div className="col-2">
              <div className="date-row">
                <div className="date">
                  <h2>Date</h2>
                  <DatePicker
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    selected={sendDate}
                    onChange={setSendDate}
                  />
                </div>

                <div className="date">
                  <h2>Validité</h2>
                  <DatePicker
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    selected={validUntilDate}
                    onChange={setValidUntilDate}
                  />
                </div>
              </div>

              <h2>NOTE PERSONNELLE</h2>
              <ReactQuill 
                theme={'snow'}
                value={notes || ''}
                onChange={(val) => setNotes(val)}
                modules={modules}
                formats={formats}
              />
          
            </div>

          </div>

        </div>

      </div>
    )
  }

  return (
    <div className="router-transition-container">
      <Prompt when={!isSaved} message={"Voulez-vous vraiment quitter cette page ?"}/> 
      <div className="page-content">
      <div className="row">
          <div className="col-1">
            {renderQuoteHeader()}
          </div>

          <div className="col-1">
            <div className="quote-container">
              <div className="title">
                <div className="bar"></div>
                <h2>{"PRESTATIONS & PRODUITS"}</h2>
                <div className="bar"></div>
              </div>
            </div>

            <div className="quote-container">
              <div className="add-product">
                <div className="select-product">
                  <Select
                    styles={customSelect}
                    placeholder={'Sélectionner un produit'}
                    value={productToAdd || ''}
                    options={productsOptions}
                    onChange={(product) => setProductToAdd(product)}
                  />
                </div>

                {productToAdd &&
                  <button
                    onClick={() => addProduct()}
                    className="btn primary">
                    Ajouter
                  </button>
                }
              </div>
            </div>

            <div className="edit-product-row uppercase">
              <div className="name"><p>TYPE DE PRESTA / PRODUIT</p></div>
              <div className="value"><p>Quantité</p></div>
              <div className="value"><p>Unités</p></div>
              <div className="value"><p>Prix unitaire TTC</p></div>
              <div className="value"><p>Remise (%)</p></div>
              <div className="value total"><p>Total TTC</p></div>
            </div>

            {productList.map(( product , i) => <EditQuoteProductRow product={product} handleChangeProduct={handleChangeProduct} removeProduct={removeProduct} key={i} index={i}/>)}

            <div className="total-row">
              <p className="bold">Total TTC {calcPrice(productList).totalTTC} €</p>
            </div>
          </div>

          <div className="col-1">
              <div className="quote-container">
                <div className="title">
                  <div className="bar"></div>
                  <h2>{"EMAIL PERSONNALISé"}</h2>
                  <div className="bar"></div>
                </div>
                

              </div>
              <ReactQuill 
                theme={'snow'}
                value={mail}
                onChange={(val) => setMail(val)}
                modules={modules}
                formats={formats}
              />
            </div>


        </div>
      </div>
    </div>
  )
}
