import {
  IS_AUTH,
  ERROR_AUTH,
  USER_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_LIST,
  GET_DASHBOARD_BY_PERIOD,
  GET_DASHBOARD_CA,
  GET_ATTESTATIONS
} from "../actions/types";

const initialState = {
  isAuth: false,
  error: null,
  userAuth: null,
  message: null,
  lists: null,
  dashboard: null,
  attestations: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ATTESTATIONS:
      return {...state , attestations: action.payload }
    case GET_DASHBOARD_CA:
      return {...state , dashboard: {...state.dashboard , 
        caByProvider: action.payload.caByProvider,
      }}
    case GET_DASHBOARD_BY_PERIOD:
      return {...state , dashboard: {...state.dashboard , 
        receiptsTotals: action.payload.receiptsTotals,
        topFiveClients: action.payload.topFiveClients,
        topFiveProducts: action.payload.topFiveProducts
      }}
    case IS_AUTH:
      return {...state , isAuth: action.payload}
    case GET_LIST:
      return {...state , lists: action.payload}
    case ERROR_AUTH:
      return {...state , error: action.payload}
    case USER_AUTH:
      return {...state , userAuth: action.payload}
    case FORGOT_PASSWORD:
      return {...state , message : { forgot: action.payload.message}}
    case RESET_PASSWORD:
      return {...state , message : { reset: action.payload.message}}
    default:
      return {...state};
  }
}