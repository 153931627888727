import React , { useEffect , useState } from 'react';
import Select from "react-select";
import { customSort } from '../../../../../utils/styles';
import { sortByUpdateDate , sortByReceiptPrice , filterByDatePeriodKey } from '../../../../../utils/utils';
import { payReceiptAction , updateReceiptAction } from '../../../../../actions/receiptsActions';

import ReceiptRow from './ReceiptRow';
import Loader from '../../../../partials/Loader';
import Modale from '../../../../partials/Modale';

import './receipts.scss';
import PeriodPicker from '../../AdminPartials/PeriodPicker';

export default function ReceiptsList({ setActivePage , context , match , dispatch }) {

  const sortOptions = [
    { value : "date" , label : "Trier par date" },
    { value : "paid" , label : "Afficher les reçus payés" },
    { value : "sent" , label : "Afficher les reçus envoyés" },
    { value : "price" , label : "Trier par montant" }
  ]

  const [ allReceipts , setAllReceipts ] = useState();
  const [ search , setSearch ] = useState();
  const [ startDate , setStartDate ] = useState();
  const [ endDate , setEndDate ] = useState();
  const [ sort , setSort ] = useState(sortOptions[0]);
  const [ receiptToPay , setReceiptToPay ] = useState();
  
  const [ payementIsLoading , setPayementIsLoading ] = useState();
  const [ displayPaymentModale , setDisplayPaymentModale ] = useState(false);

  useEffect(() => {
    setActivePage("receipts");
    setSearch();
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (context.receipts.receiptsList && context.users.usersList) {
      var start = new Date();
      var end;
      context.receipts.receiptsList.forEach(receipt => {
        //set periode for all
        var receiptDate = new Date(receipt.date)
        if (receiptDate < start) start = receiptDate;
        if (end && receiptDate >= end) end = receiptDate;
        if (!end) end = receiptDate;
        if (context.users && context.users.usersList && receipt.client) {
          let client = context.users.usersList.find(user => user._id === receipt.client);
          if (client) {
            receipt.clientName = client.LastName ? client.LastName : client.LegalRepresentativeLastName
          }
          if (receipt.content) {
            receipt.content.forEach(product => {
              product.providers.forEach( productProvider => {
                var found = context.users.usersList.find(user => user._id === productProvider._id);
                productProvider.tax = found && found.withTVA ? product.tax : 0 
              })
            })
          }
        }
      });
      setStartDate(start);
      setEndDate(end);

      setAllReceipts(sortByUpdateDate(context.receipts.receiptsList))
    }
  }, [context.receipts.receiptsList , context.users]);

  useEffect(() => {
    if (context.receipts.receiptsList) {
      if (search) {
        var newList = context.receipts.receiptsList.filter(receipt => receipt.clientName.toLowerCase().includes(search.toLowerCase()))
        setAllReceipts(filterByDatePeriodKey("date" , newList , startDate , endDate));
      } else {
        setAllReceipts(filterByDatePeriodKey("date" , context.receipts.receiptsList , startDate , endDate))
      }
    }
  }, [search , context.receipts.receiptsList , startDate , endDate ]);

  function renderReceiptsList() {
    if (allReceipts) {
      var filtred = allReceipts; 
      if (sort.value === "sent" || sort.value === "paid"  ) {
        filtred = allReceipts.filter(item => item.state === sort.value)
      } else if (sort.value === "date") {
        filtred = sortByUpdateDate(allReceipts)
      } else if (sort.value === "price") {
        filtred = sortByReceiptPrice(allReceipts)
      }
      return filtred.filter(item => item.state === "sent" || item.state === "paid" || item.state === "seen" ).map((receipt, i) => (<ReceiptRow key={i + receipt._id} openPaymentModale={openPaymentModale} receipt={receipt} dispatch={dispatch} context={context} path={match.path}/>))
    }
  }

  useEffect(() => {
    if (context.receipts.message === "Receipt paiement send" || context.receipts.message === 'Receipt successfully updated!') {
      removePaymentModale();
    }
  }, [context.receipts.message] );

  function openPaymentModale(receipt) {
    setReceiptToPay(receipt);
    setDisplayPaymentModale(true);
  }

  function removePaymentModale() {
    setReceiptToPay();
    setDisplayPaymentModale(false);
    setPayementIsLoading(false)
  }

  function validPayment(type) {
    if (type !== "paid") {
      payReceiptAction( dispatch , receiptToPay , type )
    } else {
      updateReceiptAction(dispatch , receiptToPay._id , { state: "paid" })
    }
    setPayementIsLoading(true);
  }

  return (
    <>
      <div className="router-transition-container">
        <div className="page-content">
          <div className="filters">
            <div className="col-4">
              <PeriodPicker
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                />
            </div>
            <div className="col-4">
              <Select
                styles={customSort}
                isSearchable={false}
                value={sort}
                options={sortOptions}
                onChange={(client) => setSort(client)}
              />
            </div>

          </div>
  
          <div className="row">
            <div className="col-1">

              <div className="header">
                <h2>Reçus</h2>
                <div className="actions">
                </div>
              </div>

              <div className="list-container">

                <div className={`receipt-row label-row bold ${context.auth.userAuth && context.auth.userAuth.type && context.auth.userAuth.type === "client" ? "client-label" : ""}`}>

                  <div className="receipt-label uppercase">
                    <p>N°</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>Date</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>PAIEMENT</p>
                  </div>

                  {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                    <div className="receipt-label uppercase">
                      <p>MangoPay</p>
                    </div>
                  }

                  {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                    <div className="receipt-label uppercase">
                      <p>Payé</p>
                    </div>
                  }

                  <div className="receipt-label uppercase">
                    <p>Montant</p>
                  </div>

                  <div className="icon">
                  </div>

                </div>

                {renderReceiptsList()}
              </div>

            </div>
          </div>
        </div>
      </div>
      <Modale
        modaleIsOpen={displayPaymentModale}
        modaleToggle={removePaymentModale}
        hideClose={false}
        className={"valid-receipt-modale"}
        >
          {payementIsLoading ?

            <div className="content"> 
              <Loader />
            </div>
            
            :
            
            <div className="content">
              <h2>Choisissez le mode de paiement </h2>

              <button className="btn primary"
                onClick={() => validPayment("card")}>
                Paiement par carte bancaire
              </button>

              <button className="btn primary"
                onClick={() => validPayment("mandate")}>
                Paiement par mandat
              </button>

              <h2>Valider un paiement </h2>

              <button className="btn primary"
                onClick={() => validPayment("paid")} >
                Valider un paiement
              </button>

            </div>
          }
      </Modale>
    </>
  )
}

