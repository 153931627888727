import {
  GET_QUOTES,
  GET_QUOTE,
  ERROR_QUOTES,
  MESSAGE_QUOTES,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  SEND_QUOTE,
  SET_QUOTES_FILTERS,
  GET_QUOTES_FILTERED
} from "../actions/types";

// const month = new Date().getMonth();
// const year = new Date().getFullYear();
// const end = new Date(year, month + 1, 0);
// const start = new Date(year, month - 1, 0);

const initialState = {
  quotesList: [],
  quote: null,
  error: null,
  message: null,
  count: 0,
  filters: {
    search:"",
    start: null,
    end: null,
    page:1,
    orderBy: { key: "number", order: "desc" },
    number:10
  }
};

export const defaultFilters = initialState.filters;

export default function quotesReducer(state = initialState, action) {
  let updatedList ;
  switch (action.type) {

    case SEND_QUOTE:
        updatedList = state.quotesList.filter(quote => quote._id !== action.payload.quote._id);
        updatedList.push(action.payload.quote);
        return {...state , quotesList: updatedList , quote: action.payload.quote }

    case UPDATE_QUOTE:
      updatedList = state.quotesList;
      const index =  state.quotesList.findIndex(quote => quote._id === action.payload.quote._id);
      if (index !== -1) {
        updatedList[index] = action.payload.quote;
      }
      return {...state ,
        message : action.payload.message,
        quotesList: updatedList,
        quote: action.payload.quote
      }

    case CREATE_QUOTE:
      var addToList = state.quotesList;
      addToList.unshift(action.payload.quote )
      return {...state ,
        message : action.payload.message,
        quotesList: addToList,
        quote: action.payload.quote
      }

    case GET_QUOTE:
      return {...state , quote: action.payload}

    case DELETE_QUOTE:
      var deleteList = state.quotesList.filter(quote => quote._id !== action.payload)
      return {...state , quotesList: deleteList }

    case SET_QUOTES_FILTERS:
      return {...state, filters: action.payload }
      
    case GET_QUOTES_FILTERED:
      return {...state , quotesList: action.payload.quotes , count: action.payload.count }

    case GET_QUOTES:
      return {...state , quotesList: action.payload}

    case ERROR_QUOTES:
      return {...state , error: action.payload}

    case MESSAGE_QUOTES:
      return {...state , message : action.payload}

    default:
      return {...state};

  }
}