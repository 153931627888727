import React , { useEffect , useState } from 'react';
import { useParams } from  'react-router-dom';
import Select from "react-select";
import { customSort } from '../../../../../utils/styles';
import { sortByUpdateDate , sortByPrice , filterByCreateDatePeriod } from '../../../../../utils/utils';
import { getInvoicesAction  , getUsersAction } from '../../../../../actions/userActions';

import InvoiceRow from './InvoiceRow';

import './invoices.scss';
import PeriodPicker from '../../AdminPartials/PeriodPicker';

export default function InvociesList({ setActivePage , context , dispatch } ) {

  const param = useParams();

  const sortOptions = [
    { value : "date" , label : "Trier par date (les plus récentes)" },
    { value : "dateReverse" , label : "Trier par date (les moins récentes)" },
    { value : "priceCroissant" , label : "Trier par montant (croissant)" },
    { value : "priceDécroissant" , label : "Trier par montant (décroissant)" }
  ];

  const [ allInvoices , setInvocies ] = useState();
  const [ search , setSearch ] = useState();
  const [ startDate , setStartDate ] = useState();
  const [ endDate , setEndDate ] = useState();
  const [ sort , setSort ] = useState(sortOptions[0]);

  useEffect(() => {
    setActivePage("invoices");
    getUsersAction(dispatch);
    getInvoicesAction(dispatch , "provider" , param.userId )
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (context.users.invoices && context.users.usersList) {
      var start = new Date();
      var end;
      context.users.invoices.forEach(invoice => {
        //set periode for all
        var invoiceDate = new Date(invoice.createdAt)
        if (invoiceDate < start) start = invoiceDate;
        if (end && invoiceDate > end) end = invoiceDate;
        if (!end) end = invoiceDate;
        if (context.users && context.users.usersList && invoice.client) {
          let client = context.users.usersList.find(user => user._id === invoice.client);
          if (client) {
            invoice.clientName = client.LastName ? `${client.LastName} ${client.FirstName}` : `${client.LegalRepresentativeLastName} ${client.LegalRepresentativeFirstName}`
          }
        }
      });
      setStartDate(start);
      setEndDate(end);
      setInvocies(sortByUpdateDate(context.users.invoices))
    }
  }, [context.users.invoices , context.users]);

  useEffect(() => {
    if (context.users.invoices) {
      if (search) {
        var newList = context.users.invoices.filter(invoice => invoice.clientName.toLowerCase().includes(search.toLowerCase()) || parseInt(search.toLowerCase()) === invoice.number)
        setInvocies(filterByCreateDatePeriod(newList , startDate , endDate));
      } else {
        setInvocies(filterByCreateDatePeriod(context.users.invoices , startDate , endDate))
      }
    }
  }, [search , context.users.invoices , startDate , endDate ]);

  function renderInvoicesList() {

    if (allInvoices) {
      var filtred = allInvoices; 
      if (sort.value === "date") {
        filtred = sortByUpdateDate(allInvoices)
      } if (sort.value === "dateReverse") {
        filtred = sortByUpdateDate(allInvoices).reverse();
      } else if (sort.value === "priceCroissant") {
        filtred = sortByPrice(allInvoices).reverse();
      } else if (sort.value === "priceDécroissant") {
        filtred = sortByPrice(allInvoices)
      }
      return filtred.map((invoice, i) => (<InvoiceRow key={i + invoice._id} invoice={invoice} dispatch={dispatch} context={context} />))
    }
  }

  return (
    <>
      <div className="router-transition-container">
        <div className="page-content">
          <div className="filters">
            <div className="col-4">
              <PeriodPicker
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                />
            </div>
            <div className="col-4">
              <Select
                styles={customSort}
                isSearchable={false}
                value={sort}
                options={sortOptions}
                onChange={(client) => setSort(client)}
              />
            </div>
            <div className="col-4">
              <input
                placeholder="Rechercher..."
                onChange={(e) => setSearch(e.target.value)}
                value={search || ''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-1">

              <div className="header">
                <h2>FACTURES</h2>
                <div className="actions">
                  
                
                </div>
              </div>

              <div className="list-container">

                <div className="invoice-row label-row bold">

                  <div className="invoice-label uppercase">
                    <p>N° Facture</p>
                  </div>

                  <div className="invoice-label uppercase">
                    <p>Nom du client</p>
                  </div>

                  <div className="invoice-label uppercase">
                    <p>date d’émission</p>
                  </div>

                  <div className="invoice-label uppercase">
                    <p>MONTANT TTC</p>
                  </div>

                  <div className="icon">
                </div>

              </div>
                {renderInvoicesList()}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

