import React, { useEffect , useState } from 'react';
import Select from "react-select";
import { customSelect } from '../../../../../utils/styles';
import { createProductAction , updateProductAction } from '../../../../../actions/productsActions';
import { useForm } from "react-hook-form";

import { addTVA , removeTVA } from '../../../../../utils/utils';

import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';


export default function EditProducts(props) {
  const { product , context , dispatch , closeModale } = props;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'indent': '-1'}, {'indent': '+1'}],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockreceipt',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const [ description , setDescription ] = useState();
  const [ unitsOptions , setUnitsOptions ] = useState();
  const [ selectedUnit , setSelectedUnit ] = useState();

  const { handleSubmit, register, errors } = useForm();

  useEffect(() => {
    if(product && product.description) setDescription(product.description);
    if (product && product.unit && unitsOptions && unitsOptions.find(item => item.value === product.unit )) setSelectedUnit(unitsOptions.find(item => item.value === product.unit ))
  }, [product , unitsOptions])

  useEffect(() => {
    if (context.auth.lists && context.auth.lists.units) {
      let options = [];
      context.auth.lists.units.forEach(units => {
        options.push({
          label: units,
          value: units
        })
      })
      setUnitsOptions(options);
    }
  }, [context.auth.lists]);


  const submitProduct = (values)  => {
    values.tax = parseFloat(values.tax)
    values.unit = selectedUnit.value
    values.priceHT = removeTVA(values.priceTTC  , values.tax )
    values.description = description
    if (product) {
      updateProductAction(dispatch , product._id , values )
    } else {
      createProductAction(dispatch , values )
    }
  }

  useEffect(() => {
    if (context.products.message === "product saved" || context.products.message === "Product successfully updated!" ) {
      closeModale(false);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le produit a bien été enregistré",
          type: "success"
        }
      })

      dispatch({
        type:'MESSAGE_PRODUCTS',
        payload: null
      })
    }
  }, [context.products.message , closeModale, dispatch])

  return (
    <div className="edit-user-container">
      <form className="product" onSubmit={handleSubmit(submitProduct)}>

        <div className="form-container">

          <div className="row-2">

            <div className="col-2">
              <label htmlFor="name">Dénomination</label>
              <input
                name="name"
                style={{ height: 38, marginTop: 7 }}
                defaultValue={product && product.name}
                ref={register({
                  required: "Compléter le nom du produit",
                })}
              />
              <p className="text-error">{errors.name && errors.name.message}</p>

              <label htmlFor="unit">Unité</label>
              
              <Select
                styles={customSelect}
                placeholder={'Unité'}
                value={selectedUnit|| ''}
                options={unitsOptions}
                onChange={(val) => setSelectedUnit(val)}
              />
            </div>

            <div className="col-2">
              <label htmlFor="tax">TVA</label>
              <input
                name="tax"
                type="number"
                step="any"
                style={{ height: 38, marginTop: 7 }}
                defaultValue={product && product.tax}
                ref={register({
                  required: "Compléter la TVA en %",
                })}
              />
              <p className="text-error">{errors.tax && errors.tax.message}</p>

              <label htmlFor="priceTTC">Prix TTC</label>
              <input
                name="priceTTC"
                defaultValue={product &&  addTVA(product.priceHT , product.tax).toFixed(2)}
                type="number"
                style={{ height: 38, marginTop: 7 }}
                step="any"
                ref={register({
                  required: "Compléter le prix TTC",
                })}
              />
              <p className="text-error">{errors.priceTTC && errors.priceTTC.message}</p>
              </div>
          </div>

          <label htmlFor="description">Description</label>
          <ReactQuill 
            theme={'snow'}
            onChange={(val => setDescription(val))}
            value={description || ''}
            modules={modules}
            formats={formats}
            placeholder={"Description"}
          />

        </div>

        <div className="btn-container">
          <button className="btn primary" type="submit">Enregistrer</button>
        </div>

      </form>
    </div>
  )
}
