import React , { useEffect , useState } from 'react';

import { sortByUpdateDate } from '../../../../../utils/utils';

import ProviderRow from './ProviderRow';
import Modale from '../../../../partials/Modale';

import '../../AdminPartials/users.scss';
import EditUser from '../../AdminPartials/EditUser';

export default function ProvidersList(props) {
  const { setActivePage , context , dispatch , match } = props;

  const [ allProviders , setAllProviders ] = useState();
  const [ search , setSearch ] = useState();

  const [ displayAddProviderModale , setDisplayAddProviderModale ] = useState(false);
  const [ providerId , setProviderId ] = useState();

  useEffect(() => {
    setActivePage("providers");
    // eslint-disable-next-line 
  }, [])

  function editProvider(id) {
    setProviderId(id);
    setDisplayAddProviderModale(true);
    dispatch({
      type : 'GET_USER',
      payload : null
    });
  }

  function closeProviderModale() {
    setProviderId();
    setDisplayAddProviderModale(false);
    dispatch({
      type : 'GET_USER',
      payload : null
    });
  }

  useEffect(() => {
    if (context.users.usersList) {
      setAllProviders(sortByUpdateDate(context.users.usersList.filter(user => user.type === "provider")))
    }
  }, [context.users.usersList]);

  useEffect(() => {
    if (context.users.usersList) {
      if (search) {
        var newList = context.users.usersList.filter(user => user.type === "provider").filter(user => {
          if (user.Email) {
            if (
              user.Email.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.Name) {
            if (
              user.Name.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.FirstName) {
            if (
              user.FirstName.toLowerCase().includes(search.toLowerCase()) ||
              user.LastName.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          if (user.LegalRepresentativeFirstName) {
            if (
              user.LegalRepresentativeFirstName.toLowerCase().includes(search.toLowerCase()) ||
              user.LegalRepresentativeLastName.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            }
          }
          return false;
        });
        setAllProviders(sortByUpdateDate(newList));
      } else {
        setAllProviders(sortByUpdateDate(context.users.usersList.filter(user => user.type === "provider")))
      }
    }
  }, [search , context.users.usersList]);

  function renderProvidersList() {
    if (allProviders) {
      return allProviders.map((provider, i) => (<ProviderRow key={i + provider._id} provider={provider} editProvider={editProvider} path={match.path}/>))
    }
  }

  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="row">
          <div className="col-1">

            <div className="header">
              <h2>INDÉPENDANTS</h2>
              <div className="actions">
                <input
                  placeholder="Rechercher un indépendant"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <button
                  onClick={() => setDisplayAddProviderModale(true)}
                  className="btn primary">
                  Nouveau
                </button>
              </div>
            </div>

            <div className="list-container">

              <div className="user-row label-row bold">
                <div className="label uppercase">
                  <p>Nom de société</p>
                </div>
                <div className="label uppercase">
                  <p>E-mail</p>
                </div>
                <div className="label uppercase">
                  <p>Téléphone</p>
                </div>
                <div className="label uppercase">
                  <p>Chiffre d’affaires</p>
                </div>
              </div>

              {renderProvidersList()}
            </div>

          </div>
          <Modale
            modaleIsOpen={displayAddProviderModale}
            modaleToggle={closeProviderModale}
            className={"user-modale"}
            >
            {displayAddProviderModale && <EditUser type={"provider"} closeModale={closeProviderModale} userId={providerId} context={context} dispatch={dispatch}/>}
          </Modale>
        </div>
      </div>
    </div>
  )
}

