import {
  GET_RECEIPTS,
  GET_RECEIPT,
  ERROR_RECEIPTS,
  MESSAGE_RECEIPTS,
  CREATE_RECEIPT,
  UPDATE_RECEIPT,
  DELETE_RECEIPT,
  VALID_RECEIPT,
  PAY_RECEIPT,
  GET_RECEIPT_FILTERED,
  GET_RECEIPT_INVOICES,
  PAY_BY_CARD,
  SEND_RECEIPT,
  SET_RECEIPT_FILTERS
} from "../actions/types";

import { sortByUpdateDate } from '../utils/utils';

// const month = new Date().getMonth();
// const year = new Date().getFullYear();
// const end = new Date(year, month + 1, 0);
// const start = new Date(year, month - 1, 0);

const initialState = {
  receiptsList: [],
  receipt: null,
  error: null,
  message: null,
  invoices: null,
  payByCardHtml: null,
  count: 0,
  filters: {
    search:"",
    start: null,
    end: null,
    page:1,
    orderBy: { key: "number", order: "desc" },
    number:10
  }
};

export const defaultFilters = initialState.filters;

export default function receiptsReducer(state = initialState, action) {
  switch (action.type) {

    case SEND_RECEIPT:
        return {...state , message: action.payload.message }

    case PAY_BY_CARD :
      return {...state , payByCardHtml: action.payload }

    case PAY_RECEIPT:
      var paidList = state.receiptsList.filter(receipt => receipt._id !== action.payload.receipt._id);
      paidList.push(action.payload.receipt);
      return {...state ,
        message : action.payload.message,
        receiptsList: paidList
      }

    case VALID_RECEIPT:
      var validList = state.receiptsList.filter(receipt => receipt._id !== action.payload.receipt._id);
      validList.push(action.payload.receipt);
      return {...state ,
        message : action.payload.message,
        receiptsList: validList
      }

    case UPDATE_RECEIPT:
      var updatedList = state.receiptsList.filter(receipt => receipt._id !== action.payload.receipt._id);
      updatedList.push(action.payload.receipt);
      return {...state ,
        message : action.payload.message,
        receiptsList: updatedList,
        receipt: action.payload.receipt
      }

    case CREATE_RECEIPT:
      var addToList = state.receiptsList;
      addToList.push(action.payload.receipt)
      return {...state ,
        message : action.payload.message,
        receiptsList: sortByUpdateDate(addToList),
        receipt: action.payload.receipt
      }

    case GET_RECEIPT_INVOICES:
      return {...state , invoices: action.payload}

    case GET_RECEIPT:
      return {...state , receipt: action.payload}

    case DELETE_RECEIPT:
      var deleteList = state.receiptsList.filter(receipt => receipt._id !== action.payload)
      return {...state , receiptsList: deleteList }

    case SET_RECEIPT_FILTERS:
      return {...state, filters: action.payload }
      
    case GET_RECEIPT_FILTERED:
      return {...state , receiptsList: action.payload.receipts , count: action.payload.count }

    case GET_RECEIPTS:
      return {...state , receiptsList: action.payload}

    case ERROR_RECEIPTS:
      return {...state , error: action.payload}

    case MESSAGE_RECEIPTS:
      return {...state , message : action.payload}

    default:
      return {...state};

  }
}