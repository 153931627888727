import { getData , postData , putData , deleteData } from './index';

import {
  GET_PRODUCTS,
  ERROR_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from "./types"

export async function getProductsAction(dispatch) {
  let url = "/product";
  var products;

  await getData(ERROR_PRODUCTS, url, dispatch , true ).then((response)=>{
    products = response.products
  });

  dispatch({
    type : GET_PRODUCTS,
    payload : products
  });

};

export async function createProductAction(dispatch , data ) {
  let url = "/product";
  var product;

  await postData( CREATE_PRODUCT , ERROR_PRODUCTS ,  url , dispatch, data , true ).then((response)=>{
    product = response
  });

  dispatch({
    type : CREATE_PRODUCT,
    payload : product
  });

};

export async function updateProductAction(dispatch , id , data ) {
  let url = "/product/" + id;
  var product;

  await putData( UPDATE_PRODUCT , ERROR_PRODUCTS ,  url, dispatch, data , true ).then((response)=>{
    product = response
  });

  dispatch({
    type : UPDATE_PRODUCT,
    payload : product
  });

};

export async function deleteProductAction(dispatch , id) {
  let url = "/product/" + id;
  var product;


  await deleteData(ERROR_PRODUCTS, url, dispatch, true ).then((response)=>{
    product = response
  });

  console.log(product);
  dispatch({
    type : DELETE_PRODUCT,
    payload : id
  });

};