import React , { useEffect , useState , useCallback } from 'react';
import { Link } from  'react-router-dom';
import Select from "react-select";
import { customSort } from '../../../../../utils/styles';
import { payReceiptAction , updateReceiptAction , sendReceiptMailAction, getReceiptsFilteredAction } from '../../../../../actions/receiptsActions';

import { defaultFilters } from '../../../../../reducers/receiptsReducer';
import { SET_RECEIPT_FILTERS } from '../../../../../actions/types';

import ReceiptRow from './ReceiptRow';
import PeriodPicker from '../../AdminPartials/PeriodPicker';
import Loader from '../../../../partials/Loader';
import Modale from '../../../../partials/Modale';

import searchImg from '../../../../../assets/images/icons/search.svg';
import './receipts.scss';


export default function ReceiptsList({ setActivePage , context , match , dispatch }) {

  const sortOptions = [
    { value : { key: "paid" , order: "desc" }, label : "Payé" },
    { value : { key: "lates" , order: "desc" }, label : "En retard" },
    { value : { key: "waiting", order: "desc" } , label : "En attente" },
    { value : { key: "draft", order: "desc" }  , label : "Brouillon" },
    // { value : "sent" , label : "Envoyé" },
    // { value : "price" , label : "Montant" },
    { value : { key: "date", order: "desc" } , label : "Trier par date ( les plus récentes) " },
    { value : { key: "date", order: "asc" } , label : "Trier par date ( les moins récentes) " },
    { value : { key: "number", order: "desc" } , label : "Trier par numéro (décroissant)" },
    { value : { key: "number", order: "asc" }  , label : "Trier par numéro (croissant)" },
  ]

  const { filters } = context.receipts;

  const [ allReceipts , setAllReceipts ] = useState();
  const [ search , setSearch ] = useState();
  const [ receiptToPay , setReceiptToPay ] = useState();
  
  const [ paymentMessage , setPaymentMessage ] = useState();
  const [ payementIsLoading , setPayementIsLoading ] = useState();
  const [ displayPaymentModale , setDisplayPaymentModale ] = useState(false);

  function handleChangeFilters(obj) {
    dispatch({
      type: SET_RECEIPT_FILTERS,
      payload: {
        ...filters,
        ...obj
      }
    })
  };

  const getReceipt = useCallback(() => {
    getReceiptsFilteredAction(dispatch, filters)
  },[dispatch, filters]);

  function openPaymentModale(receipt) {
    setReceiptToPay(receipt);
    setDisplayPaymentModale(true);
  }

  function removePaymentModale() {
    setReceiptToPay();
    setDisplayPaymentModale(false);
    setPayementIsLoading(false);
    setPaymentMessage();
  }

  function validPayment(type) {
    if (type !== "paid") {
      payReceiptAction( dispatch , receiptToPay , type )
    } else {
      updateReceiptAction(dispatch , receiptToPay._id , { state: "paid" })
    }
    setPayementIsLoading(true);
  }

  useEffect(() => {
    setActivePage("receipts");
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    getReceipt();
    // eslint-disable-next-line 
  }, [filters])

  useEffect(() => {
    if (context.receipts.receiptsList && context.users.usersList) {
      // var start = new Date();
      // var end;
      context.receipts.receiptsList.forEach(receipt => {
        //set periode for all
        // var receiptDate = new Date(receipt.date ? receipt.date : receipt.createdAt)
        // if (receiptDate < start) start = receiptDate;
        // if (end && receiptDate > end) end = receiptDate;
        // if (!end) end = receiptDate;
        if (context.users && context.users.usersList && receipt.client) {
          let client = context.users.usersList.find(user => user._id === receipt.client);
          if (client) {
            receipt.clientName = client.LastName ? client.LastName : client.LegalRepresentativeLastName
            receipt.clientFirstName = client.FirstName ? client.FirstName : client.LegalRepresentativeFirstName
          }
          if (receipt.content) {
            receipt.content.forEach(product => {
              product.providers.forEach( productProvider => {
                var found = context.users.usersList.find(user => user._id === productProvider._id);
                productProvider.tax = found && found.withTVA ? product.tax : 0 
              })
            })
          }
        }
      });
      // setStartDate(start);
      // setEndDate(end);
      // setInitDate({ start: start , end: end  });
      setAllReceipts(context.receipts.receiptsList);
    }
  }, [context.receipts.receiptsList , context.users]);

  // useEffect(() => {
  //   if (context.receipts.receiptsList) {
  //     if (search) {
  //       var newList = context.receipts.receiptsList.filter(receipt =>
  //         receipt.clientFirstName.toLowerCase().includes(search.toLowerCase()) || 
  //         receipt.clientName.toLowerCase().includes(search.toLowerCase()) ||
  //         receipt.number.toString().toLowerCase().includes(search.toLowerCase())
  //       )
  //       setAllReceipts(filterByDatePeriodKey("date" , newList , startDate , endDate));
  //     } else {
  //       setAllReceipts(filterByDatePeriodKey("date" ,context.receipts.receiptsList , startDate , endDate))
  //     }
  //   }
  // }, [search , context.receipts.receiptsList , startDate , endDate ]);

  useEffect(() => {
    if (context.receipts.error?.data?.error === "Mandate not found") {
      setPaymentMessage("Le mandat n'a pas été trouvé")
    }
  }, [context.receipts.error] );

  useEffect(() => {
    
    if (context.receipts.message === "Receipt paiement send") {
      removePaymentModale();
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été envoyé en paiement",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });
    }

    if (context.receipts.message === 'Receipt successfully updated!') {
      removePaymentModale();
      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });
    }

    if (context.receipts.message === "Receipt mail send") {
      removePaymentModale();
      
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été envoyé",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });

    }
    // eslint-disable-next-line 
  }, [context.receipts.message] );


  // function filterLatesReceipt(list) {
  //   return list.filter(d => {
  //     var issue = new Date(d.validUntilDate);
  //     var today = new Date()
  //     if(d.state === "draft" || d.state === "paid" ) {
  //       return false;
  //     } else {
  //       return issue.getTime() - today.getTime() >  0 ? false : true ;
  //     }
  //   })
  // }

  // function filterWaitingReceipt(list) {
  //   return list.filter(d => {
  //     var issue = new Date(d.validUntilDate);
  //     var today = new Date()
  //     if(d.state === "draft" || d.state === "paid" || d.state === "valid" ) {
  //       return false;
  //     } else {
  //       return issue.getTime() - today.getTime() >  0 ? true : false ;
  //     }
  //   })
  // }

  function renderReceiptsList() {
    if (allReceipts) {
      // if (sort.value === "paid") {
      //   filtred = allReceipts.filter(item => item.state === "paid")
      // } else if (sort.value === "draft") {
      //   filtred = allReceipts.filter(item => item.state === "draft")
      // } else if (sort.value === "date") {
      //   filtred = sortByDate(allReceipts)
      // } else if (sort.value === "dateReverse") {
      //   filtred = sortByDate(allReceipts).reverse();
      // } else if (sort.value === "number") {
      //   filtred = sortByKey("number" , allReceipts )
      // } else if (sort.value === "numberReverse") {
      //   filtred = sortByKey("number" , allReceipts ).reverse();
      // } else if (sort.value === "price") {
      //   filtred = sortByReceiptPrice(allReceipts)
      // } else if (sort.value === "lates") {
      //   filtred = filterLatesReceipt(allReceipts)
      // } else if (sort.value === "waiting") {
      //   filtred = filterWaitingReceipt(allReceipts)
      // }
      return allReceipts.map((receipt, i) => (<ReceiptRow key={i + receipt._id} openPaymentModale={openPaymentModale} receipt={receipt} dispatch={dispatch} context={context} path={match.path}/>))
    }
  }

  function renderPagination() {
    const folios = [];
    if (context?.receipts?.count > context.receipts?.receiptsList?.length) {
      for (let i = 1 ; i - 1 < context?.receipts?.count / context?.receipts.filters.number ; i++) {
        folios.push(i);
      }
      return <ul className="pagination">
        {folios.map((f) => <li key={'page' + f}>
          <button
            className={`${filters.page === f && 'active'}`}
            onClick={() => handleChangeFilters({ page: f })}>
            {f}
          </button>
        </li>)}
      </ul>
    }
    return null;
  };

  return (
    <>
      <div className="router-transition-container">
        <div className="page-content">
        <div className="filters">
            <div className="col-4">
              <div className="header">
                <h2>Filtres</h2>
                <button
                  className={"btn primary"}
                  onClick={() => handleChangeFilters(defaultFilters)}
                  >Réinitialiser
                </button>
              </div>
              <PeriodPicker
                isFilter
                startDate={filters.start ? new Date(filters.start) : null }
                endDate={filters.end ? new Date(filters.end) : null }
                setEndDate={(date) => handleChangeFilters({ end: date.toISOString() , page: 1 })}
                setStartDate={(date) => handleChangeFilters({ start: date.toISOString() , page: 1 })}
                />
            </div>
            <div className="col-4">
              <Select
                styles={customSort}
                isSearchable={false}
                value={sortOptions.find((opt) => opt.value.key === filters.orderBy.key && opt.value.order === filters.orderBy.order)}
                options={sortOptions}
                onChange={(opt) => handleChangeFilters({ orderBy: opt.value , page: 1})}
              />
            </div>
            <div className="col-4 search">   
              <input
                placeholder="Rechercher..."
                onChange={(e) => setSearch(e.target.value)}
                value={search || ''}
              />
              <button
                onClick={() => handleChangeFilters({ search: search , page: 1 })}
                >
                <img src={searchImg} alt='search' />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-1">

              <div className="header">
                <h2>Reçus</h2>
                <div className="actions">
                  <Link to={"/admin/create-receipt"}>
                    <button className="btn primary">
                      Nouveau
                    </button>
                  </Link>
                </div>
              </div>

              <div className="list-container">

                <div className={`receipt-row label-row bold ${context.auth.userAuth && context.auth.userAuth.type && context.auth.userAuth.type === "client" ? "client-label" : ""}`}>

                  <div className="receipt-label uppercase">
                    <p>STATUT</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>N°</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>CLIENT</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>DATE</p>
                  </div>

                  <div className="receipt-label uppercase">
                    <p>échéance</p>
                  </div>

                  {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                    <div className="receipt-label uppercase">
                      <p>Paiement</p>
                    </div>
                  }

                  {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                    <div className="receipt-label uppercase">
                      <p>MangoPay</p>
                    </div>
                  }

                  <div className="receipt-label uppercase">
                    <p>Montant</p>
                  </div>

                  <div className="icon">
                  </div>

                </div>

                {renderReceiptsList()}
              </div>
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>
      <Modale
        modaleIsOpen={displayPaymentModale}
        modaleToggle={removePaymentModale}
        hideClose={false}
        className={"valid-receipt-modale"}
        >
          {payementIsLoading ?

            <div className="content"> 
              {paymentMessage ? <p>{paymentMessage}</p>:<Loader />}
            </div>
            
            :
            
            <div className="content">
              <h2>ENVOYER EN PAIEMENT</h2>

              <button
                type='button'
                className="btn primary"
                onClick={() => validPayment("card")}>
                Paiement par carte bancaire
              </button>

              <button
                type='button'
                className="btn primary"
                onClick={() => validPayment("mandate")}>
                Paiement par mandat
              </button>

              <h2>AUTRES ACTIONS</h2>

              <button
                type='button'
                className="btn primary"
                onClick={() => sendReceiptMailAction(dispatch , receiptToPay?._id )} >
                Envoyer le reçu par mail
              </button>

              <button
                type='button'
                className="btn primary"
                onClick={() => validPayment("paid")} >
                Passer en payer
              </button>

            </div>
          }
      </Modale>
    </>
  )
}

