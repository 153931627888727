import React, { useEffect , useCallback } from "react";
import { Router, Switch, Route } from "react-router-dom";

import history from "../utils/history";

import { useGlobalContext } from './context/GlobalContext';
import { getUserAuthAction, getListAction } from '../actions/authActions';

import LoginPage from './pages/AUTH/LoginPage';
import ResetPasswordPage from './pages/AUTH/ResetPasswordPage';

import AdminRouter from './pages/ADMIN/AdminRouter';
import MemberRouter from './pages/MEMBERS/MemberRouter';

export default function GlobalRouter() {

  const [ context, dispatch ] = useGlobalContext();

  var token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    getListAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if(context.auth.isAuth) {
      getUser();
    }
  // eslint-disable-next-line 
  }, [context.auth.isAuth]);

  useEffect(() => {
    if(context.auth.error === 401 ) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);


  useEffect(() => {
    if(token) {
      getUser();
    }
  // eslint-disable-next-line
  }, []);

  function logout() {
    history.push("/");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <Router history={history}>

      <Switch>

        <Route exact path="/" render={
          (props) => <LoginPage
            {...props}
            />
        }/>

        <Route exact path="/reset-password/:token" render={
          (props) => <ResetPasswordPage
            {...props}
            />
        }/>

        <Route path="/admin" render={
          (props) => <AdminRouter
            logout={logout}
            {...props}
            />
        }/>

        <Route path="/member" render={
          (props) => <MemberRouter
            logout={logout}
            {...props}
            />
        }/>

      </Switch>
    </Router>
  );
}