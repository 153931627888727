import React from 'react'
import logo from '../../../assets/images/logo.png';

export default function MobilePage() {
  return (
    <div className="page-container mobile-page">
      <img src={logo} alt="logo" className="logo" />
      <p>Application non disponible<br/>sur mobile et tablette</p>
    </div>
  )
}
