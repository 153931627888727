import React from 'react';
import * as moment from 'moment';
import optIcon from '../../../../../assets/images/icons/options-icon.svg';
import { calcPrice } from '../../../../../utils/utils';
import {  getCreditFileAction } from '../../../../../actions/creditsActions';

export default function CreditRow({ credit , dispatch }) {

  function renderValid() {
    return (
      <div className="credit-row">
        <div className="credit-row border">
          <div className="credit-label uppercase">
            <p>{credit.number}</p>
          </div>

          <div className="credit-label uppercase">
            <p>Facture {credit.invoice.number}</p>
          </div>

          <div className="credit-label uppercase">
            <p>{credit.invoice.clientName ? credit.invoice.clientName  :  "---"}</p>
          </div>

          <div className="credit-label uppercase">
            <p>{credit.invoice.providerName ? credit.invoice.providerName :  "---"}</p>
          </div>

          <div className="credit-label uppercase">
            <p>{moment(credit.createdAt).format("DD/MM/YYYY")}</p>
          </div>

          <div className="credit-label uppercase">
            <p>-{calcPrice(credit.invoice.content).totalTTC} €</p>
            {/* <p>{calcReceiptPrice(credit.content).totalTTC.toFixed(2)} €</p> */}
          </div>
        </div>

        <div className="dropdown">
          <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
          <div className="dropdown-content">
            <div>
              <button onClick={() => getCreditFileAction(dispatch , credit._id , credit.number )}>
                Apercu
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderValid()}
    </>
  )
}
