import React , { useState , useEffect , useCallback  } from 'react';
import { Link } from  'react-router-dom';
import * as moment from 'moment';

import optIcon from '../../../../../assets/images/icons/options-icon.svg';

import { deleteReceiptAction , payReceiptByCardAction , getInvoiceFileAction , getReceiptInvoicesAction , getReceiptFileAction , createReceiptAction } from '../../../../../actions/receiptsActions';
import { createCreditAction , getCreditFileAction , getCreditsAction } from '../../../../../actions/creditsActions'

import { calcReceiptPrice , calcPrice } from '../../../../../utils/utils';
import Modale from '../../../../partials/Modale';

export default function ReceiptRow({ receipt , dispatch , context , openPaymentModale , path }) {
  const [ displayInvoicesModale , setDisplayInvoicesModale ] = useState(false);
  const [ creditIsLoading ,  setCreditIsLoading ] = useState(false);

  const rolePath = context?.auth?.userAuth?.role?.toLowerCase();

  const getCredit = useCallback(() => {
    getCreditsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {

    if (context.credits.message === "credit saved") {
      
      getCredit();
      setCreditIsLoading(false);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'avoir a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_CREDITS',
        payload: null
      });

    }
    // eslint-disable-next-line 
  },[context.credits.message]);

  useEffect(() => {
    if (context.receipts.invoices?.find(i => i.receipt === receipt._id )) {
      setDisplayInvoicesModale(true) 
    }
  },[context.receipts.invoices , receipt._id])

  function duplicateReceipt() {
    var receiptCopy = {...receipt}
    delete receiptCopy._id;
    delete receiptCopy.state;
    delete receiptCopy.createdAt;
    delete receiptCopy.updatedAt;
    delete receiptCopy.mangopayPayIn
    createReceiptAction(dispatch , receiptCopy )
  }

  function displayInvoices() {
    getReceiptInvoicesAction(dispatch , receipt._id )
  }

  function removeInvoices() {
    setDisplayInvoicesModale(false);
    dispatch({
      type : 'GET_RECEIPT_INVOICES',
      payload : null
    });
  };

  function createCredit(invoiceId) {
    createCreditAction(dispatch , { invoice:  invoiceId } )
    setCreditIsLoading(true);
  }

  function renderClientName() {
    if(receipt && receipt.client && context.users.usersList && context.users.usersList.find(user => user._id === receipt.client)) {
      let client = context.users.usersList.find(user => user._id === receipt.client);
      if (client && client.LegalRepresentativeFirstName) {
        return `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`
      } else if (client.FirstName) {
        return `${client.FirstName} ${client.LastName}`
      }
    }
  }

  var issue = new Date(receipt.validUntilDate);
  var today = new Date()

  let isLate = false;
  if (receipt.state !== "draft" || receipt.state !== "paid" || receipt.state !== "valid") {
    isLate = issue.getTime() - today.getTime() >  0 ? false : true ;
  }

  function renderPaidState(receipt) {

    if (isLate && receipt.state !== "paid") {
      return <p className="late">En retard</p>
    } else if (receipt.state === "paid") {
      return <p className="paid">Payé</p>;
    } else if (receipt.state === "sent") {
      return <p className="draft">En attente</p>
    } else if (receipt.state === "seen") {
      return <p className="draft">En attente</p>
    } else if (receipt.state === "refused") {
      return <p className="late">Refuser</p>
    } else {
      return "---"
    }

  }

  function renderDraft() {
    return (
      <div className="receipt-row">
        <Link to={"/admin/edit-receipt/" + receipt._id} className="receipt-row border">
          <div className="receipt-label uppercase">
            <p className="draft">Brouillon</p>
          </div>

          <div className="receipt-label uppercase">
            <p>{receipt.number}</p>
          </div>

          <div className="receipt-label uppercase">
            <p>{renderClientName()}</p>
          </div>

          <div className="receipt-label uppercase">
            <p>{receipt.date && moment(receipt.date).format("DD/MM/YYYY")}</p>
          </div>

          <div className="receipt-label uppercase">
            <p>---</p>
          </div>

          <div className="receipt-label uppercase">
            <p>---</p>
          </div>

          <div className="receipt-label uppercase">
            <p>---</p>
          </div>

          <div className="receipt-label uppercase">
            <p>{calcReceiptPrice(receipt.content).totalTTC.toFixed(2)} €</p>
          </div>
        </Link>

        <div className="dropdown">
          <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
          <div className="dropdown-content">
            <div>
              <button onClick={() => deleteReceiptAction(dispatch , receipt._id )}>
                Supprimer
              </button>
              {receipt.quote &&
                <Link to={"/admin/edit-quote/" + receipt.quote}>
                Voir la proposition
                </Link>
              }
              <Link to={"/admin/edit-receipt/" + receipt._id}>
                Editer
              </Link>
              <button onClick={() => duplicateReceipt()}>
                Dupliquer
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderValid(state) {
    return (
      <>
        <div className="receipt-row">
          <Link to={`/${rolePath}/receipt-view/${receipt._id}`} className={`receipt-row border ${context.auth.userAuth?.type && context.auth.userAuth.type === "client" ? "client" : ""}`}>

            {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
              <div className="receipt-label uppercase">
                {state === "valid" && <p className={state}>Facturé</p>}
                {state === "sent" && <p className={state}>Envoyé</p>}
                {state === "paid" && <p className={"seen"}>VU</p>}
                {state === "seen" && <p className={"seen"}>VU</p>}
                {state === "refused" && <p className={state}>Paiment refusé</p>}
              </div>
            }

            <div className="receipt-label uppercase">
              <p>{receipt.number}</p>
            </div>

            {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
              <div className="receipt-label uppercase">
                <p>{renderClientName()}</p>
              </div>
            }

            <div className="receipt-label uppercase">
              <p>{moment(receipt.date).format("DD/MM/YYYY")}</p>
            </div>

            {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
              <div className="receipt-label uppercase">
                {/* {receipt.dateOfIssue ? <p>{moment(receipt.dateOfIssue).format("DD/MM/YYYY")}</p> : <p>---</p>} */}
                <p>{moment(receipt.validUntilDate).format("DD/MM/YYYY")}</p>
              </div>
            }

            <div className="receipt-label uppercase">
              {renderPaidState(receipt)}
            </div>

            {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
              <div className="receipt-label uppercase">
                <p>{receipt.mangopayPayIn ? receipt.mangopayPayIn : "---"}</p>
              </div>
            }
            
            <div className="receipt-label uppercase">
              <p>{calcReceiptPrice(receipt.content).totalTTC.toFixed(2)} €</p>
            </div>
          </Link>

          <div className="dropdown">
            <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
            <div className="dropdown-content">
              <div>
                <button onClick={() => getReceiptFileAction(dispatch , receipt._id , receipt.number )}>
                  Apercu
                </button>

                <button onClick={() => displayInvoices()}>
                  Voir les factures
                </button>

                {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                  <>
                    <Link to={"/admin/receipt-view/" + receipt._id}>
                      Ouvrir le reçu
                    </Link>
                    {state !== "paid" &&
                      <button onClick={() => openPaymentModale(receipt)}>
                        {state !== "valid" ? "Renvoyer en paiement" : "Envoyer en paiement" }
                      </button>
                    }
                    <button onClick={() => duplicateReceipt()}>
                      Dupliquer
                    </button>
                  </>
                }

                {context.auth.userAuth && context.auth.userAuth.type === "client" &&
                  <>
                    {state !== "paid" &&
                      <button onClick={() => payReceiptByCardAction(dispatch , receipt._id )}>
                        Payer par carte
                      </button>
                    }
                  </>
                }

              </div>
            </div>
          </div>
        </div>
      </> 
    )
  }

  const Invoice = ({ invoice , creditsList }) => {
    var credit = creditsList ? creditsList.find(item => item.invoice._id === invoice._id ) : null ;
    var provider = context.users && context.users.usersList ? context.users.usersList.find(item => item._id === invoice.provider ) : null ;
    if (provider && provider.LegalRepresentativeLastName) {
      provider = `${provider.LegalRepresentativeFirstName} ${provider.LegalRepresentativeLastName}`
    }
    if (provider && provider.LastName) {
      provider = `${provider.FirstName} ${provider.LastName}`
    }

    return (
      <div className="invoice">
        <div className="invoice-row">
          <p>Facture {invoice.number}</p>
          <p>{moment(invoice.createdAt).format("DD/MM/YYYY")}</p>
          <p>{calcPrice(invoice.content).totalTTC} €</p>
          <p>{provider && provider}</p>
          {!creditsList.find(item => item.invoice._id === invoice._id ) && context.auth.userAuth.role === 'Admin'?
            <>
              <button
                className="btn grey"
                onClick={() => getInvoiceFileAction(dispatch , invoice )}>
                Voir la facture
              </button>
              <button
                disabled={credit || creditIsLoading ? true : false }
                className={`btn ${credit || creditIsLoading ? 'disabled' : 'primary'}`}
                onClick={() => createCredit(invoice._id)}>
                Transformer en avoir
              </button>
            </>
            :
            <>
              <button
                className="btn grey"
                onClick={() => getInvoiceFileAction(dispatch , invoice )}>
                Voir la facture
              </button>
            </>
          }
        </div>

        {creditsList.find(item => item.invoice._id === invoice._id ) &&

          <div className="invoice-row" >
            <p>Avoir {credit.number}</p>
            <p>{moment(invoice.createdAt).format("DD/MM/YYYY")}</p>
            <p>- {calcPrice(invoice.content).totalTTC} €</p>
            <p>{provider}</p>
            <button
              className="btn grey"
              onClick={() => getCreditFileAction(dispatch , credit._id )}>
              Voir l'avoir
            </button>
          </div>
        }
      </div>
    )
  }

  return (
    <>
      {receipt && receipt.state === "draft" ?  renderDraft() : renderValid(receipt.state) }
      <Modale
        modaleIsOpen={displayInvoicesModale}
        modaleToggle={() => removeInvoices()}
        className={"invoices-modale"}
        >
         {context.receipts.invoices && 
          <div className="invoices-list">
            {context.receipts.invoices.map((item , i ) => <Invoice key={'invoice' + i } 
            creditsList={context.credits.creditsList}
            invoice={item} />)} 
          </div>
         }
      </Modale>
    </>
  )
}
