import React from 'react';
import DatePicker , { registerLocale } from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want

export default function PeriodPicker({ startDate , endDate , setStartDate , setEndDate }) {
  registerLocale("fr", fr);
  return (
    <div className="periode-picker">
      <div className="row">
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        selectsStart
        locale="fr"
        dateFormat="dd/MM/yyyy"
        startDate={startDate}
        endDate={endDate}
      />
      <p className="bold">au</p>
      <DatePicker
        selected={endDate}
        onChange={date => setEndDate(date)}
        selectsEnd
        locale="fr"
        dateFormat="dd/MM/yyyy"
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        todayButton="Aujourd'hui"
      />
      </div>
    </div>
  );
}
