import React from 'react';
import optIcon from '../../../../../assets/images/icons/options-icon.svg';
import { deleteProductAction } from '../../../../../actions/productsActions';
import { addTVA } from '../../../../../utils/utils';

export default function ProductRow(props) {
  
  const { product , editProduct , dispatch } = props;

  return (
    <div className="product-row">
      <div className="product-row border" onClick={() => editProduct(product)}>
        <div className="label uppercase">
          <p>{product.name }</p>
        </div>
        <div className="label uppercase">
          <p>{product.unit }</p>
        </div>
        <div className="label">
          <p>{addTVA(product.priceHT , product.tax).toFixed(2)} €</p>
        </div>
        <div className="label">
          <p>{product.totalQuantity}</p>
        </div>
        <div className="label">
          <p>{product?.totalPrice ? product?.totalPrice?.toFixed() : 0 } €</p>
        </div>
      </div>

      <div className="dropdown">
        <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
        <div className="dropdown-content">
          <div>
            <button onClick={() => deleteProductAction(dispatch , product._id )}>
              Supprimer
            </button>
            <button onClick={() => editProduct(product)}>
              Modifier
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}
