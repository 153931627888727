import React, { useState , useEffect } from 'react';
import { useParams } from 'react-router-dom';
import countriesOptions from '../../../../utils/countries.json';
import { formatPhone, sortByCreateDate } from '../../../../utils/utils'; 

import { getUserAction , getInvoicesAction, postUserCaAction , createUserMandateAction } from '../../../../actions/userActions';

import Modale from '../../../partials/Modale';

import EditUser from './EditUser';
import EditIban from './EditIban.jsx';
import PeriodPicker from './PeriodPicker';
import InvoiceRow from '../../ADMIN/AdminPages/Invoices/InvoiceRow';

import ReceiptRow from '../../ADMIN/AdminPages/Receipts/ReceiptRow';

import Loader from '../../../partials/Loader';

import './users.scss'
import pen from '../../../../assets/images/icons/pen.svg';
import { format } from 'date-fns';
import { getCreditsByProviderAction } from '../../../../actions/creditsActions';
import CreditRow from '../AdminPages/Credits/CreditRow';

export default function User({ setActivePage , context , dispatch , type }) {

  const param = useParams();

  const initDates = {
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear(), 11, 31)
  }

  const [ displayAddUserModale , setDisplayAddUserModale ] = useState(false);
  const [ displayEditIbanModale , setDisplayEditIbanModale ] = useState(false);

  const [ userId , setUserId ] = useState();
  const [ user , setUser ] = useState();
  const [ startDate , setStartDate ] = useState(initDates.startDate);
  const [ endDate , setEndDate ] = useState(initDates.endDate);

  useEffect(() => {
    setActivePage(type === "client" ? "clients" : "providers");
    getUserAction(dispatch , param.userId );
    if (type === "provider") {
      getInvoicesAction(dispatch , "provider" , param.userId );
      getCreditsByProviderAction(dispatch, param.userId );
    }
    return () => { 
      dispatch({
        type: 'POST_USER_CA',
        payload: null
      });
      dispatch({
        type: 'GET_USER',
        payload: null
      });
      dispatch({
        type: 'MESSAGE_USERS',
        payload: null
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (context.users.user) {
      setUser(context.users.user);
    }
  }, [context.users.user]);

  useEffect(() => {
    if (type === "provider") {
      postUserCaAction(dispatch , param.userId , { startDate: startDate , endDate: endDate })
    }
    // eslint-disable-next-line
  },[startDate , endDate])

  function editIban() {
    setDisplayEditIbanModale(true);
  }

  function editUser(id) {
    setUserId(id);
    setDisplayAddUserModale(true);
  }

  function closeUserModale() {
    setUserId();
    setDisplayAddUserModale(false);
  }

  function renderReceiptsList() {
    if (context.receipts.receiptsList  && param.userId ) {
      context.receipts.receiptsList.forEach(receipt => {
        //set periode for all
        if (context.users && context.users.usersList && receipt.client) {
          let client = context.users.usersList.find(user => user._id === receipt.client);
          if (client) {
            receipt.clientName = client.LastName ? client.LastName : client.LegalRepresentativeLastName
          }
          if (receipt.content) {
            receipt.content.forEach(product => {
              product.providers.forEach( productProvider => {
                var found = context.users.usersList.find(user => user._id === productProvider._id);
                productProvider.tax = found && found.withTVA ? product.tax : 0 
              })
            })
          }
        }
      });

      var filtred = sortByCreateDate(context.receipts.receiptsList).filter(item => item.client === param.userId); 
      return filtred.map((receipt, i) => (<ReceiptRow key={i + receipt._id} receipt={receipt} dispatch={dispatch} context={context} />))
    }
  }

  function renderInvoicesList() {
    if (context.users.invoices  && param.userId && context.users.usersList ) {
      return sortByCreateDate(context.users.invoices).map((invoice, i) => (<InvoiceRow key={i + invoice._id} invoice={invoice} dispatch={dispatch} context={context} />))
    }
  }

  function renderCreditList() {
    if (context.credits?.creditsList && context.users?.usersList) {
      const creditsList = context.credits.creditsList.map((c) =>  {
        const credit = {...c};
        let client = context.users.usersList.find(user => user._id === credit.invoice.client);
        if (client) {
          credit.invoice.clientName = client.LastName ? `${client.FirstName} ${client.LastName}` : `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`
        }
        let provider = context.users.usersList.find(user => user._id === credit.invoice.provider);
        if (provider) {
          credit.invoice.providerName = provider.LastName ? `${provider.FirstName} ${provider.LastName}` : `${provider.LegalRepresentativeFirstName} ${provider.LegalRepresentativeLastName}`
        }
        return credit;
      });
      return sortByCreateDate(creditsList).map((c, i) => (<CreditRow key={i + c._id} credit={c} dispatch={dispatch} />))
    }
  }

  function renderClientList() {
    return (
      <div className="row list">
        <div className="col-1">
          <h2>Reçus</h2>
          <div className="list-container">

            <div className="receipt-row label-row bold">

              <div className="receipt-label uppercase">
                <p>état</p>
              </div>

              <div className="receipt-label uppercase">
                <p>N°</p>
              </div>

              <div className="receipt-label uppercase">
                <p>CLIENT</p>
              </div>

              <div className="receipt-label uppercase">
                <p>DATE</p>
              </div>

              <div className="receipt-label uppercase">
                <p>échéance</p>
              </div>

              {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                <div className="receipt-label uppercase">
                  <p>Paiement</p>
                </div>
              }

              {context.auth.userAuth && context.auth.userAuth.role === 'Admin' &&
                <div className="receipt-label uppercase">
                  <p>MangoPay</p>
                </div>
              }

              <div className="receipt-label uppercase">
                <p>Montant</p>
              </div>

              <div className="icon">
              </div>

            </div>
            {renderReceiptsList()}
          </div>
        </div>
      </div>
    )
  }

  function renderProviderInvoicesList() {
    return (
      <div className="row list">
        <div className="col-1">
          <h2>Factures</h2>
          <div className="list-container">

            <div className="invoice-row label-row bold">

              <div className="invoice-label uppercase">
                <p>N°</p>
              </div>

              <div className="invoice-label uppercase">
                <p>CLIENT</p>
              </div>

              <div className="invoice-label uppercase">
                <p>Date</p>
              </div>

              <div className="invoice-label uppercase">
                <p>Prix TTC</p>
              </div>

              <div className="icon">
              </div>

            </div>

            {renderInvoicesList()}
          </div>
        </div>
      </div>
    )
  }

  function renderProviderCreditsList() {
    return (
      <div className="row list">
        <div className="col-1">
          <h2>Avoirs</h2>
          <div className="list-container">

            <div className="invoice-row label-row bold">

                <div className="invoice-label uppercase">
                  <p>N°</p>
                </div>

                <div className="invoice-label uppercase">
                  <p>FACTURE</p>
                </div>

                <div className="invoice-label uppercase">
                  <p>CLIENT</p>
                </div>

                <div className="invoice-label uppercase">
                  <p>Indépendant</p>
                </div>

                <div className="invoice-label uppercase">
                  <p>Date</p>
                </div>

                <div className="invoice-label uppercase">
                <p>MONTANT</p>
                </div>


              <div className="icon">
              </div>

            </div>

            {renderCreditList()}
          </div>
        </div>
      </div>
    )
  }


  return (
    <div className="router-transition-container">
      <div className="page-content">
        {user?
          <>
            <div className="row">
              <div className="col-1 user-container">

                <div className="name">
                  <div className="user-icon">
                    {user.FirstName && <span>{user.FirstName.charAt(0)}{user.LastName.charAt(0)}</span>}
                    {console.log(user)}
                    {user?.Name && <span>{user.Name.charAt(0)}</span>}
                  </div>

                  {user.FirstName && <h1 className="bold">{user.FirstName.toLowerCase()} {user.LastName.toLowerCase()}</h1>}
                  {user?.Name && <h1 className="bold">{user.Name}</h1>}

                  <div className="buttons">
                    <button
                      type="button"
                      className="btn edit-icon"
                      onClick={() => editUser(param.userId)}>
                      <img src={pen} alt="edit"/>
                    </button>
                  </div>
                </div>

                <div className="user-content">

                  <div className="user-infos">
                      
                    {user.tel &&
                      <p className="label">
                        <span className="bold">Téléphone</span><br/>
                        <span className="tel">{formatPhone(user.tel)}</span>
                      </p>
                    }
  
                    {user.HeadquartersAddress && 
                      <p className="label">
                        <span className="bold">Adresse</span><br/>
                        {user.Name && <span>{user.Name}<br/></span>}
                        {user.HeadquartersAddress.AddressLine1 && user.HeadquartersAddress.AddressLine1}<br/>
                        {user.HeadquartersAddress.AddressLine2 && <span>{user.HeadquartersAddress.AddressLine2}<br/></span>}
                        {user.HeadquartersAddress.Region && <span>{user.HeadquartersAddress.Region}<br/></span>}
                        {user.HeadquartersAddress.City && user.HeadquartersAddress.PostalCode && <span>{user.HeadquartersAddress.PostalCode} {user.HeadquartersAddress.City}</span>}<br/>
                        {user.HeadquartersAddress.Country && countriesOptions.find(item => item.value.toUpperCase() === user.HeadquartersAddress.Country).label}
                      </p>
                    }

                    {user.Address && 
                      <p className="label">
                        <span className="bold">Adresse</span><br/>
                        {user.Name && <span>{user.Name}<br/></span>}
                        {user.Address.AddressLine1 && <span>{user.Address.AddressLine1}<br/></span>}
                        {user.Address.AddressLine2 && <span>{user.Address.AddressLine2}<br/></span>}
                        {user.Address.Region && <span>{user.Address.Region}<br/></span>}
                        {user.Address.City && user.Address.PostalCode && <span>{user.Address.PostalCode} {user.Address.City}</span>}<br/>
                        {user.Address.Country && countriesOptions.find(item => item.value.toUpperCase() === user.Address.Country).label}
                      </p>
                    }

                    <p>
                      <span className="bold label">Mail</span><br/>
                      <span className="tel">{user.Email}</span>
                    </p>

                    {user.type === 'provider' 
                      && <p>
                        <span className="bold label"> Date d’adhésion</span><br/>
                        <span className="tel">{format(new Date(user.createdAt) , 'dd/MM/yyyy')}</span>
                      </p>
                    }
                  </div>

                  <div className="user-infos">

                    {user.companyType && 
                      <p>
                        <span className="bold label">TYPE ENTREPRISE</span><br/>
                        <span className="label">{user.companyType}<br/></span>
                      </p>
                    }

                    {user.siret && 
                      <p>
                        <span className="bold label">SIRET</span><br/>
                        <span className="label">{user.siret}<br/></span>
                      </p>
                    }

                    {user.withTVA && type !== "client" && 
                      <p className="label">
                        <span className="bold">TVA</span><br/>
                        {user.withTVA ? <span>soumis à la TVA<br/></span> : <span>non soumis à la TVA<br/></span>}
                      </p>
                    }

                    {user.ca && type === 'client' &&
                      <p className="ca">
                        <span className="bold label">Année en cours : {user.ca.toFixed(2)} €</span><br/>
                      </p>
                    }

                    {type === 'provider' &&
                      <div>
                        <p className="bold label">Chiffre d'affaire</p>
                        <div className="dates-ca">
                        <PeriodPicker
                          startDate={startDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          setStartDate={setStartDate}
                          />
                        </div>
                        <p className="ca bold label">{context.users?.userCa.toFixed(2)} €</p>
                      </div>
                    }


                  </div>
                  
                </div>
                          
              </div>
            </div>
            
            <div className="row">
              <div className="col-1 user-container bank">
                <div className="user-content">
                  <div className="buttons">
                    {context.auth.userAuth && context.auth.userAuth.role === "Admin" &&
                      <button
                        className="btn edit-icon"
                        onClick={() => editIban()}>
                        <img src={pen} alt="edit"/>
                      </button>
                    } 
                  </div>
                  <div className="user-infos">
                    <div>
                      <h3 className="bold">BANQUE</h3>
                      {user.bankAccounts ?
                        <div>
                          <p>
                            IBAN : {user.bankAccounts.IBAN}<br/>
                            BIC : {user.bankAccounts.BIC}
                          </p>
                          {type === "client" && context.auth.userAuth && context.auth.userAuth.role === "Admin" &&
                            <>
                              {user.mandates ?
                                <p className="mandat">
                                  Mandat {user?.mandates?.Status === "SUBMITTED" ? 'envoyé' : 'accepté'}
                                </p>
                                :
                                <button className="btn primary"
                                  onClick={() => createUserMandateAction(dispatch , user._id)}>
                                  Créer un mandat
                                </button>
                              }
                            </>
                          }
                        </div>
                        :
                        <p>Le compte n'a pas été enregistré</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modale
              modaleIsOpen={displayAddUserModale}
              modaleToggle={closeUserModale}
              className={"user-modale"}
              >
              {displayAddUserModale && <EditUser type={type} closeModale={closeUserModale} userId={userId} context={context} dispatch={dispatch}/>}
            </Modale>

            {context.auth.userAuth && context.auth.userAuth.role === "Admin" &&
              <Modale
                modaleIsOpen={displayEditIbanModale}
                modaleToggle={setDisplayEditIbanModale}
                className={"account-modale"}
                >
                {displayEditIbanModale && <EditIban closeModale={setDisplayEditIbanModale} user={user} context={context} dispatch={dispatch}/>}
              </Modale>
            } 
          </>
        :
          <div className="row">
            <div className="col-1 user-container loader">
              <Loader />
            </div>
          </div>
        }
        {context.auth.userAuth && context.auth.userAuth.role === "Admin" &&
          <>
          {type === 'client' && context.receipts.receiptsList && context.receipts.receiptsList.filter(item => item.client === param.userId).length > 0 &&  <>{renderClientList()}</>}
          {type === 'provider' && context.users.invoices && context.users.invoices.length > 0 && <>{renderProviderInvoicesList()}</>}
          {type === 'provider' && context.credits.creditsList && context.credits.creditsList.length > 0 && <>{renderProviderCreditsList()}</>}
          </>
        }
      </div>
    </div>
  )
}
