import React , { useEffect } from 'react';
import { getAttestationFileAction } from '../../../../actions/authActions';

import './attestations.scss';

export default function AttestationsList(props) {

  const { setActivePage , context , dispatch } = props;

  useEffect(() => {
    setActivePage("attestations");
    // eslint-disable-next-line 
  }, []);

  const { attestations } = context.auth

  function renderAttestationList() {
    if (attestations) {
      return attestations.map((year , i ) => {
        return (
          <div key={'attestation' + i }
            className="attestation-row">
            <p>Attestation {year}</p>
            <button
              onClick={() => getAttestationFileAction(dispatch , year )}
              className="btn primary">Télécharger l'attestation</button>
          </div>
        )
      })
    }
  }
 
  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="row">
          <div className="col-1">

            <div className="header">
              <h2>Attestations</h2>
            </div>

            <div className="list-container">
              {renderAttestationList()}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

