import {
  GET_PRODUCTS,
  ERROR_PRODUCTS,
  MESSAGE_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from "../actions/types";

const initialState = {
  productsList: false,
  error: null,
  message: null,
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    
    case DELETE_PRODUCT:
      var deleteList = state.productsList.filter(product => product._id !== action.payload)
      return {...state , productsList: deleteList }

    case UPDATE_PRODUCT:
      var updatedList = state.productsList.filter(product => product._id !== action.payload.product._id);
      updatedList.push(action.payload.product)
      return {...state ,
        message : action.payload.message,
        productsList: updatedList
      }

    case CREATE_PRODUCT:
      var addToList = state.productsList;
      addToList.push(action.payload.product)
      return {...state ,
        message : action.payload.message,
        productsList: addToList
      }

    case GET_PRODUCTS:
      return {...state , productsList: action.payload}

    case ERROR_PRODUCTS:
      return {...state , error: action.payload}

    case MESSAGE_PRODUCTS:
      return {...state , message : action.payload}

    default:
      return {...state};

  }
}