import React , { useState , useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useForm } from "react-hook-form";
import Select from "react-select";

import nationalitylist from '../../../../utils/nationalitylist.json';

import DatePicker , { registerLocale } from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want

import Loader from  '../../../partials/Loader';

import { getUserAction , createUserAction , updateUserAction } from '../../../../actions/userActions';

import { customSelect } from '../../../../utils/styles';
import './modales.scss';

export default function EditUser(props) {

  registerLocale("fr", fr);

  const { type , userId , dispatch , context , closeModale } = props;

  const typologyOptions = [
    { value: 'person', label: 'Personne' },
    { value: 'company', label: 'Entreprise' },
  ];

  const legalTypeOptions = [
    { value: 'BUSINESS', label: 'Société' },
    { value: 'SOLETRADER', label: 'Auto-entrepreneur' },
  ];

  var countriesOptions = [];
  var nationalitiesOptions = []

  nationalitylist.forEach(country => { 
    countriesOptions.push({ value: country.value, label: country.country });
    nationalitiesOptions.push({ value: country.value , label: country.nationality })
  });

  const { handleSubmit, register, errors , setValue, watch } = useForm();

  const [ typologyValue , setTypologyValue ] = useState();
  const [ typologyError , setTypologyError ] = useState();

  const [ legalTypeValue , setLegalTypeValue ] = useState();
  const [ legalTypeError , setLegalTypeError ] = useState();

  const [ nationalityValue , setNationalityValue ] = useState();
  const [ nationalityError , setNationalityError ] = useState();

  const [ countryValue , setCountryValue ] = useState();
  const [ countryError , setCountryError ] = useState();

  const [ countryOfResidenceValue , setCountryOfResidenceValue ] = useState();
  const [ countryOfResidenceError , setCountryOfResidenceError ] = useState();

  const [ user , setUser ] = useState();
  const initUser = type === "provider" ? { typology: "company" } : {};
  const [ userForm , setUserForm ] = useState(initUser);

  const [ birthday , setBirthday ] = useState();
  const [ BirthdayError , setBirthdayError ] = useState();

  const [ formStep , setFormStep ] = useState(1);

  // const [ endMessage , setEndMessage ] = useState();

  useEffect(() => {
    if(userId) {
      getUserAction(dispatch , userId );
    }
  }, [userId , dispatch]);

  useEffect(() => {
    if(context.users.user) {

      var userToUpdate = {}
      userToUpdate.MangoPay = {};

      var userData = context.users.user

      if (userData.PersonType === "NATURAL") {
        userToUpdate.typology = 'person'
        handleChangeTypology(typologyOptions.find(opt => opt.value === 'person'));
      }
      if (userData.PersonType === "SOLETRADER") {
        userToUpdate.typology = 'person'
        handleChangeTypology(typologyOptions.find(opt => opt.value === 'person'));
      }
      if (userData.LegalPersonType === "BUSINESS") {
        userToUpdate.typology = 'company'
        handleChangeTypology(typologyOptions.find(opt => opt.value === 'company'));
        handleChangeLegalType(legalTypeOptions.find(opt => opt.value === "BUSINESS"));
      }
      if (userData.LegalPersonType === "SOLETRADER") {
        userToUpdate.typology = 'company'
        handleChangeTypology(typologyOptions.find(opt => opt.value === 'company'));
        handleChangeLegalType(legalTypeOptions.find(opt => opt.value === "SOLETRADER"));
      }

      if (userData.FirstName) userToUpdate.MangoPay.FirstName = userData.FirstName
      if (userData.LastName) userToUpdate.MangoPay.LastName = userData.LastName

      if (userData.LegalRepresentativeFirstName) userToUpdate.MangoPay.FirstName = userData.LegalRepresentativeFirstName
      if (userData.LegalRepresentativeLastName) userToUpdate.MangoPay.LastName = userData.LegalRepresentativeLastName

      if (userData.Email) userToUpdate.MangoPay.Email = userData.Email
      if (userData.sap) userToUpdate.sap = userData.sap
      
      if (userData.Nationality) {
        userToUpdate.MangoPay.Nationality = userData.Nationality
        handleChangeNationality(nationalitiesOptions.find(opt => opt.value === userData.Nationality))
      }
      
      if (userData.LegalRepresentativeNationality && nationalitiesOptions.length ) {
        userToUpdate.MangoPay.Nationality = userData.LegalRepresentativeNationality
        handleChangeNationality(nationalitiesOptions.find(opt => opt.value === userData.LegalRepresentativeNationality))
      }

      if (userData.CountryOfResidence) {
        userToUpdate.MangoPay.CountryOfResidence = userData.CountryOfResidence
        handleChangeCountryOfResidence(countriesOptions.find(opt => opt.value === userData.CountryOfResidence))
      }

      if (userData.LegalRepresentativeCountryOfResidence) {
        userToUpdate.MangoPay.CountryOfResidence = userData.LegalRepresentativeCountryOfResidence
        handleChangeCountryOfResidence(countriesOptions.find(opt => opt.value === userData.LegalRepresentativeCountryOfResidence))
      }

      if (userData.Birthday) {
        var date = new Date( userData.Birthday * 1000);
        userToUpdate.MangoPay.Birthday = userData.Birthday
        handleChangeBirthday(date)
      }

      if (userData.LegalRepresentativeBirthday) {
        userToUpdate.MangoPay.Birthday = userData.LegalRepresentativeBirthday
        var dateCompany = new Date( userData.LegalRepresentativeBirthday * 1000);
        handleChangeBirthday(dateCompany)
      }

      if (userData.tel) userToUpdate.tel = userData.tel

      if (userData.Occupation) userToUpdate.MangoPay.Occupation = userData.Occupation

      if (userData.withTVA) {
        userToUpdate.withTVA = userData.withTVA
      } else {
        userToUpdate.withTVA = false
      }

      if (userData.Address) {
        userToUpdate.MangoPay.Address = userData.Address;
        if (userData.Address.Country) {
          handleChangeCountry(countriesOptions.find(opt => opt.value === userData.Address.Country))
        }
      }

      if (userData.HeadquartersAddress) {
        userToUpdate.MangoPay.Address = userData.HeadquartersAddress;
        if (userData.HeadquartersAddress.Country) {
          handleChangeCountry(countriesOptions.find(opt => opt.value === userData.HeadquartersAddress.Country))
        }
      }

      if (userData.Name) userToUpdate.companyName = userData.Name
      if (userData.siret) userToUpdate.siret = userData.siret
      if (userData.companyType) userToUpdate.companyType = userData.companyType
      setUser(userToUpdate);
      setUserForm(userToUpdate);
    }
      // eslint-disable-next-line
  }, [context.users.user]);

  useEffect(() => {
    if (context.users.message === "User successfully updated!" || context.users.message === "user register !") {

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "L'utilisateur a bien été enregistré",
          type: "success"
        }
      });

      closeModale();

      dispatch({
        type:'MESSAGE_USERS',
        payload: null
      });

    }
  },[context.users.message , closeModale , dispatch ]);


  useEffect(() => {
    register({ name: "typology" });
    register({ name: "LegalPersonType" });
    register({ name: "MangoPay.Birthday" });
    register({ name: "MangoPay.Nationality" });
    register({ name: "MangoPay.CountryOfResidence" });
    register({ name: "MangoPay.Address.Country" });
  }, [register , formStep]);

  const handleChangeTypology = selectedOption => {
    setValue("typology", selectedOption.value );
    handleChange("typology", selectedOption.value );
    setTypologyError();
    setTypologyValue(selectedOption);
  };

  const handleChangeLegalType = selectedOption => {
    setValue("LegalPersonType", selectedOption.value );
    handleChange("LegalPersonType", selectedOption.value );
    setLegalTypeError();
    setLegalTypeValue(selectedOption);
  };

  const handleChangeBirthday = date => {
    setValue("MangoPay.Birthday" , Date.parse(date) / 1000 );
    handleChange("Birthday" , Date.parse(date) / 1000 )
    setBirthdayError();
    setBirthday(date);
  };

  const handleChangeCountry = selectedOption => {
    setValue("MangoPay.Address.Country", selectedOption.value );
    handleChange("Country", selectedOption.value );
    setCountryError();
    setCountryValue(selectedOption);
  };

  const handleChangeNationality = selectedOption => {
    setValue("MangoPay.Nationality", selectedOption.value );
    handleChange("Nationality", selectedOption.value );
    setNationalityError();
    setNationalityValue(selectedOption);
  };

  const handleChangeCountryOfResidence = selectedOption => {
    setValue("MangoPay.CountryOfResidence", selectedOption.value );
    handleChange("CountryOfResidence", selectedOption.value );
    setCountryOfResidenceError();
    setCountryOfResidenceValue(selectedOption);
  };

  const submitUser = (values)  => {

    if(!userForm.typology) {
      setTypologyError('Sélectionner un status')
    }

    if(type === 'provider' && !userForm.LegalPersonType) {
      setLegalTypeError('Sélectionner un type de personne morale')
    }

    if(!userForm.MangoPay.Birthday) {
      setBirthdayError('Enter une date de naissance')
    }

    if(!userForm.MangoPay.Nationality) {
      setNationalityError('Enter un pays de naissance')
    }

    if(!userForm.MangoPay.CountryOfResidence) {
      setCountryOfResidenceError('Enter un pays de résidence')
    }

    if(formStep === 2 && !values.MangoPay.Address.Country) {
      setCountryError('Séléctionner un pays')
    }

    if (userForm.typology && userForm.MangoPay.Birthday &&  userForm.MangoPay.Nationality && userForm.MangoPay.CountryOfResidence && formStep === 1 ) {
      setFormStep(formStep + 1 );
    }
    if (userForm.MangoPay.Address.Country && userForm.typology === "company" && formStep === 2 ) {
      setFormStep(formStep + 1);
    }

    if (userForm.MangoPay.Address.Country && userForm.typology === "person" && formStep === 2 ) {
      setFormStep(5);
      dispatch({
        type: 'MESSAGE_USERS',
        payload: null
      })
      if (userId) {
        updateUserAction(dispatch , userId , userForm)
      } else {
        createUserAction(dispatch , "natural" , userForm )
      }
    }

    if (userForm.MangoPay.Address.Country && userForm.typology === "company" && formStep === 2 ) {
      dispatch({
        type: 'MESSAGE_USERS',
        payload: null
      })
      setFormStep(5)
      var companyForm = setDataForCompanies(userForm);
      if (userId) {
        updateUserAction(dispatch , userId , companyForm)
      } else {
        createUserAction(dispatch , "legal" , companyForm )
      }
    }
  };

  function handleChange(key , value ) {
    var updatedUser = {...userForm };

    updatedUser.role = "Member"
    updatedUser.type = type;

    if (user && user.MangoPay) {
      updatedUser.MangoPay = user.MangoPay
    }

    if (!updatedUser.MangoPay) {
      updatedUser.MangoPay = {};
      if (!updatedUser.MangoPay.Address) {
        updatedUser.MangoPay.Address = {};
      }
    }

    switch (key) {
      case 'LegalPersonType':
        updatedUser.LegalPersonType = value;
        break;
      case 'typology':
        updatedUser.typology = value;
        break;
      case 'FirstName':
        updatedUser.MangoPay.FirstName = value;
        break;
      case 'LastName':
        updatedUser.MangoPay.LastName = value;
        break;
      case 'Email':
          updatedUser.MangoPay.Email = value;
          break;
      case 'Nationality':
          updatedUser.MangoPay.Nationality = value.toUpperCase();
          break;
      case 'CountryOfResidence':
          updatedUser.MangoPay.CountryOfResidence = value.toUpperCase();
          break;
      case 'Birthday':
          updatedUser.MangoPay.Birthday = value;
          break;
      case 'tel':
          updatedUser.tel = value;
          break;
      case 'AddressLine1':
          updatedUser.MangoPay.Address.AddressLine1 = value;
          break;
      case 'AddressLine2':
          updatedUser.MangoPay.Address.AddressLine2 = value;
          break;
      case 'City':
          updatedUser.MangoPay.Address.City = value;
          break;
      case 'PostalCode':
        updatedUser.MangoPay.Address.PostalCode = value;
        break;
      case 'Region':
        updatedUser.MangoPay.Address.Region = value;
        break;
      case 'Country':
        updatedUser.MangoPay.Address.Country = value.toUpperCase();
        break;
      case 'companyName':
        updatedUser.companyName = value;
        break;
      case 'companyType':
        updatedUser.companyType = value;
        break;
      case 'siret':
        updatedUser.siret = value;
        break;
      case 'sap':
        updatedUser.sap = value;
        break;
      case 'withTVA':
        updatedUser.withTVA = value;
        break;
      case 'occupation':
        updatedUser.MangoPay.occupation = value;
        break;
      default:
        updatedUser = {...userForm}
    }
    setUserForm(updatedUser);
    setUser(updatedUser);
  }

  function setDataForCompanies(data) {
    
    var company = {...data}
    var mangoPayCompany = {}

    mangoPayCompany.LegalPersonType = data.LegalPersonType || "BUSINESS"
    mangoPayCompany.Name = data.companyName
    mangoPayCompany.Email = data.MangoPay.Email
    mangoPayCompany.LegalRepresentativeFirstName = data.MangoPay.FirstName
    mangoPayCompany.LegalRepresentativeLastName = data.MangoPay.LastName
    mangoPayCompany.LegalRepresentativeAddress = data.MangoPay.Address
    mangoPayCompany.HeadquartersAddress = data.MangoPay.Address
    mangoPayCompany.LegalRepresentativeBirthday = data.MangoPay.Birthday
		mangoPayCompany.LegalRepresentativeNationality = data.MangoPay.Nationality
    mangoPayCompany.LegalRepresentativeCountryOfResidence = data.MangoPay.CountryOfResidence

    company.MangoPay = mangoPayCompany
    return company
  }
  console.log(userForm);
  function renderStep1() {
    return (
      <>
        <div className={`form-container ${formStep !== 1 ? "d-none" : ""}`}>

          {type === 'client' && <>
            <label htmlFor="typology">Type</label>
            <Select
              styles={customSelect}
              value={typologyValue}
              isDisabled={context.users.user}
              placeholder={'Sélectionner'}
              options={typologyOptions}
              onChange={handleChangeTypology}
            />
            <p className="text-error">{typologyError}</p>
            </>
          }

          {type === 'provider' && <>
            <label htmlFor="LegalPersonType">Type</label>
            <Select
              styles={customSelect}
              value={legalTypeValue}
              isDisabled={context.users.user}
              placeholder={'Sélectionner'}
              options={legalTypeOptions}
              onChange={handleChangeLegalType}
            />
            <p className="text-error">{legalTypeError}</p>
            </>
          }

          {(watch('typology') === "company" || watch('LegalPersonType') === 'BUSINESS') && <>

            <label htmlFor="companyName">Nom de l'entreprise</label>
            <input
              name="companyName"
              onChange={(e) => handleChange("companyName" , e.target.value)}
              defaultValue={user && user.companyName && user.companyName}
              ref={register({
                required: "Compléter le nom de l'entreprise",
              })}
            />
            <p className="text-error">{errors.companyName && errors.companyName.message}</p>
          
          </>}

          {(watch('typology') === "company" || watch('LegalPersonType') === 'BUSINESS' || watch('LegalPersonType') === 'SOLETRADER') && <>
            <label htmlFor="companyType">Forme juridique</label>
            <input
              name="companyType"
              placeholder="SARL..."
              onChange={(e) => handleChange("companyType" , e.target.value)}
              defaultValue={user && user.companyType && user.companyType}
              ref={register({
                required: "Compléter la forme juridique",
              })}
            />
            <p className="text-error">{errors.companyType && errors.companyType.message}</p>

            <label htmlFor="siret">SIRET</label>
            <input
              name="siret"
              onChange={(e) => handleChange("siret" , e.target.value)}
              defaultValue={user && user.siret && user.siret}
              ref={register({
                required: "Compléter le numéro de siret",
              })}
            />
            <p className="text-error">{errors.siret && errors.siret.message}</p>

            {type !== 'client' &&
              <>
                <label htmlFor="sap">N° SAP</label>
                <input
                  name="sap"
                  onChange={(e) => handleChange("sap" , e.target.value)}
                  defaultValue={user && user.sap && user.sap}
                  ref={register}
                />
                <p className="text-error">{errors.sap && errors.sap.message}</p>

                <label className="checkbox">
                  <span className="label">Avec TVA</span>
                  <input
                    name="withTVA"
                    type="checkbox"
                    onChange={(e) => handleChange("withTVA" , e.target.checked)}
                    defaultChecked={user && user.withTVA ? user.withTVA : false }
                    ref={register}
                  />
                  <span htmlFor="withTVA" className="checkmark"></span>
                </label>
                <p className="text-error">{errors.withTVA && errors.withTVA.message}</p>
              </>
            }
          </>}

          <label htmlFor="MangoPay.FirstName">Prénom</label>
          <input
            name="MangoPay.FirstName"
            onChange={(e) => handleChange("FirstName" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.FirstName ?  user.MangoPay.FirstName : null }
            ref={register({
              required: "Compléter le prénom",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.FirstName && errors.MangoPay.FirstName.message}</p>

          <label htmlFor="MangoPay.LastName">Nom</label>
          <input
            name="MangoPay.LastName"
            onChange={(e) => handleChange("LastName" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.LastName}
            ref={register({
              required: "Compléter le nom",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.LastName && errors.MangoPay.LastName.message}</p>
          
          <label htmlFor="MangoPay.Email">Email</label>
          <input
            name="MangoPay.Email"
            onChange={(e) => handleChange("Email" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Email}
            ref={register({
              required: "Compléter l'email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Email && errors.MangoPay.Email.message}</p>

          <label htmlFor="MangoPay.Nationality">Nationalité</label>
          <Select
            styles={customSelect}
            value={nationalityValue}
            placeholder={'Sélectionner'}
            options={nationalitiesOptions}
            onChange={handleChangeNationality}
          />
          <p className="text-error">{nationalityError}</p>


          <label htmlFor="MangoPay.CountryOfResidence">Pays de résidence</label>
          <Select
            styles={customSelect}
            value={countryOfResidenceValue}
            placeholder={'Sélectionner'}
            options={countriesOptions}
            onChange={handleChangeCountryOfResidence}
          />
          <p className="text-error">{countryOfResidenceError}</p>

          <label htmlFor="Birthday">Date de naissance</label>
          <DatePicker
            locale="fr"
            dateFormat="dd/MM/yyyy"
            selected={birthday}
            onChange={handleChangeBirthday}
          />
          <p className="text-error">{BirthdayError}</p>
        </div>

        <div className={`btn-container ${formStep !== 1 ? "d-none" : ""}`}>
          <button className="btn primary" type="submit">Etape suivante</button>
        </div>
      </>
    )
  }

  function renderStep2() {
    return (
      <>
        <div className={`form-container ${formStep !== 2 ? "d-none" : ""}`}>

          {/* <label htmlFor="MangoPay.Occupation">Métier</label>
          <input
            name="MangoPay.Occupation"
            ref={register}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Occupation && errors.MangoPay.Occupation.message}</p>   */}
          
          <label htmlFor="tel">Téléphone</label>
          <NumberFormat
            name="tel"
            type="tel"
            format="## ## ## ## ##"
            onValueChange={(values) => handleChange("tel" , values.formattedValue )}
            value={user && user.tel ? user.tel : null }
            ref={register({
              required: "Compléter le numéro de téléphone",
              pattern: {
                value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                message: "le format est invalide"
              }
            })}
          />
          <p className="text-error">{errors.tel && errors.tel.message}</p>

          <label htmlFor="MangoPay.Address.AddressLine1">Adresse</label>
          <input
            name="MangoPay.Address.AddressLine1"
            onChange={(e) => handleChange("AddressLine1" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Address && user.MangoPay.Address.AddressLine1 }
            ref={register({
              required: "Compléter l'adresse",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.AddressLine1 && errors.MangoPay.Address.AddressLine1.message}</p>
          
          <label htmlFor="MangoPay.Address.AddressLine2">Adresse ligne 2</label>
          <input
            name="MangoPay.Address.AddressLine2"
            onChange={(e) => handleChange("AddressLine2" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Address && user.MangoPay.Address.AddressLine2}
            ref={register}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.AddressLine2 && errors.MangoPay.Address.AddressLine2.message}</p>

          <label htmlFor="MangoPay.Address.City">Ville</label>
          <input
            name="MangoPay.Address.City"
            onChange={(e) => handleChange("City" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Address && user.MangoPay.Address.City}
            ref={register({
              required: "Compléter la ville",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.City && errors.MangoPay.Address.City.message}</p>

          <label htmlFor="MangoPay.Address.PostalCode">Code postal</label>
          <input
            name="MangoPay.Address.PostalCode"
            onChange={(e) => handleChange("PostalCode" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Address && user.MangoPay.Address.PostalCode}
            ref={register({
              required: "Compléter le code postal",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.PostalCode && errors.MangoPay.Address.PostalCode.message}</p>

          {/* <label htmlFor="MangoPay.Address.Region">Région</label>
          <input
            name="MangoPay.Address.Region"
            onChange={(e) => handleChange("Region" , e.target.value)}
            defaultValue={user && user.MangoPay && user.MangoPay.Address && user.MangoPay.Address.Region}
            ref={register({
              required: "Compléter la région",
            })}
          />
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.Region && errors.MangoPay.Address.Region.message}</p> */}

          <label htmlFor="MangoPay.Address.Country">Pays</label>
          <Select
            styles={customSelect}
            value={countryValue}
            placeholder={'Sélectionner'}
            options={countriesOptions}
            onChange={handleChangeCountry}
          />
          <p className="text-error">{countryError}</p>
          <p className="text-error">{errors.MangoPay && errors.MangoPay.Address && errors.MangoPay.Address.Country && errors.MangoPay.Address.Country.message}</p>

        </div>

        <div className={`btn-container ${formStep !== 2 ? "d-none" : ""}`}>
          <button className="btn primary" onClick={() => setFormStep(1)}>Retour</button>
          <button className="btn primary" type="submit">Enregistrer</button>
        </div>
      </>
    )
  }

  // function renderStepCompany() {
  //   return (
  //     <>
  //       <div className={`form-container ${formStep !== 3 ? "d-none" : ""}`}>

  //         <label htmlFor="companyType">Forme juridique</label>
  //         <input
  //           name="companyType"
  //           placeholder="SARL..."
  //           onChange={(e) => handleChange("companyType" , e.target.value)}
  //           defaultValue={user && user.companyType && user.companyType}
  //           ref={register({
  //             required: "Compléter la forme juridique",
  //           })}
  //         />
  //         <p className="text-error">{errors.companyType && errors.companyType.message}</p>

  //         <label htmlFor="siret">SIRET</label>
  //         <input
  //           name="siret"
  //           onChange={(e) => handleChange("siret" , e.target.value)}
  //           defaultValue={user && user.siret && user.siret}
  //           ref={register({
  //             required: "Compléter le numéro de siret",
  //           })}
  //         />
  //         <p className="text-error">{errors.siret && errors.siret.message}</p>

  //         {type !== 'client' &&
  //           <>
  //             <label htmlFor="sap">N° SAP</label>
  //             <input
  //               name="sap"
  //               onChange={(e) => handleChange("sap" , e.target.value)}
  //               defaultValue={user && user.sap && user.sap}
  //               ref={register}
  //             />
  //             <p className="text-error">{errors.sap && errors.sap.message}</p>

  //             <label className="checkbox">
  //               <span className="label">Avec TVA</span>
  //               <input
  //                 name="withTVA"
  //                 type="checkbox"
  //                 onChange={(e) => handleChange("withTVA" , e.target.checked)}
  //                 defaultChecked={user && user.withTVA ? user.withTVA : false }
  //                 ref={register}
  //               />
  //               <span htmlFor="withTVA" className="checkmark"></span>
  //             </label>
  //             <p className="text-error">{errors.withTVA && errors.withTVA.message}</p>
  //           </>
  //         }
          
  //       </div>

  //       <div className={`btn-container ${formStep !== 3 ? "d-none" : ""}`}>
  //         <button className="btn primary" onClick={() => setFormStep(2)}>Retour</button>
  //         <button className="btn primary" type="submit">Enregistrer</button>
  //       </div>
  //     </>
  //   )
  // }

  function renderStepPerson() {
    return (
      <>
        <div className={`form-container ${formStep !== 4 ? "d-none" : ""}`}>

          {type !== 'client' &&
            <>
              <label htmlFor="sap">N° SAP</label>
              <input
                name="sap"
                onChange={(e) => handleChange("sap" , e.target.value)}
                defaultValue={user && user.sap && user.sap}
                ref={register}
              />
              <p className="text-error">{errors.sap && errors.sap.message}</p>
              
              <label className="checkbox">
                <span className="label">Avec TVA</span>
                <input
                  name="withTVA"
                  type="checkbox"
                  onChange={(e) => handleChange("withTVA" , e.target.checked)}
                  defaultChecked={user && user.withTVA ? user.withTVA : false }
                  ref={register}
                />
                <span htmlFor="withTVA" className="checkmark"></span>
              </label>
              <p className="text-error">{errors.withTVA && errors.withTVA.message}</p>
            </>
          }

          
        </div>

        <div className={`btn-container ${formStep !== 4 ? "d-none" : ""}`}>
          <button className="btn primary" onClick={() => setFormStep(2)}>Retour</button>
          <button className="btn primary" type="submit">Enregistrer</button>
        </div>
      </>
    )
  }

  function renderEnd() {
    return (
      <div className={`form-container end ${formStep !== 5 ? "d-none" : ""}`}>
        {context.users?.error?.data?.error === "That email address is already in use." ? 
          <>
          <p>Cet email est déjà utilisé</p>
          <button className="btn primary" onClick={() => setFormStep(1)}>Retour</button>
          </>
        :
          <Loader />
        }
      </div>
    )
  }

  return (
    <div className="edit-user-container">
      <form onSubmit={handleSubmit(submitUser)}>
        {renderStep1()}
        {formStep > 1 && renderStep2()}
        {/* {formStep === 3 && renderStepCompany()} */}
        {formStep === 4 && renderStepPerson()}
        {formStep === 5 && renderEnd()}
      </form>
    </div>
  )
}
