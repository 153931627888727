import { getData , postData , getFile } from './index';

import {
  GET_CREDITS,
  ERROR_CREDITS,
  CREATE_CREDIT,
} from "./types"

export async function getCreditsAction(dispatch) {
  let url = "/credit";
  var credits;

  await getData(ERROR_CREDITS, url, dispatch , true ).then((response)=>{
    credits = response.credits
  });

  dispatch({
    type : GET_CREDITS,
    payload : credits
  });

};

export async function getCreditsByProviderAction(dispatch , providerId ) {
  let url = `/credit/provider/${providerId}`;
  var credits;

  await getData(ERROR_CREDITS, url, dispatch , true ).then((response)=>{
    credits = response.credits
  });

  dispatch({
    type : GET_CREDITS,
    payload : credits
  });

};

export async function createCreditAction(dispatch , data ) {
  let url = "/credit";
  var credit;

  await postData( CREATE_CREDIT , ERROR_CREDITS ,  url , dispatch, data , true ).then((response)=>{
    credit = response
  });

  dispatch({
    type : CREATE_CREDIT,
    payload : credit
  });

};

export async function getCreditFileAction(dispatch , id  , number ){
  let url = `/credit/${id}/pdf`
  let credit;
  await getFile( ERROR_CREDITS , url, dispatch , true).then((response)=>{
    credit = response ;
    var fileURL = URL.createObjectURL(credit);
    var downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = `Avoir n°${number} - Ma petite Conciergerie.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
};

