import format from 'format-number';

export function sortByUpdateDate(array) {
  return array.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
}

export function sortByCreateDate(array) {
  return array.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
}

export function sortByDate(array) {
  return array.sort((a,b) => new Date(b.date) - new Date(a.date));
}

export function sortByKey( key , array) {
  return array.sort((a,b) => b[key] - a[key])
}

export function sortByDateKey( key , array) {
  return array.sort((a,b) => new Date(b[key]) - new Date(a[key]));
}

export function sortByReceiptPrice(array) {
  return array.sort((a,b) => calcReceiptPrice(b.content).totalTTC - calcReceiptPrice(a.content).totalTTC );
}

export function sortByPrice(array) {
  return array.sort((a,b) => calcPrice(b.content).totalTTC - calcPrice(a.content).totalTTC );
}

export function sortByCreditPrice(array) {
  return array.sort((a,b) => calcPrice(b.invoice.content).totalTTC - calcPrice(a.invoice.content).totalTTC );
}

export function calcDiscount(price, discount) {
  if (discount) {
    let value = (price * discount) / 100;
    return price - value;
  } 
  return price;
}

export function calcPrice(array) {
  var totalHT = 0;
  var totalTTC = 0 ;
  array.forEach(item => {
    totalHT += calcDiscount(item.priceHT * item.quantity , item.discount);
    totalTTC += calcDiscount((item.priceHT * item.quantity) * (item.tax / 100  + 1 ) , item.discount);
  });
  return {
    totalHT: totalHT.toFixed(2),
    totalTTC: totalTTC.toFixed(2),
    totalTVA: (totalTTC - totalHT).toFixed(2)
  }
}

export function calcReceiptPrice(productArray) {
  
  var totalTTC = 0 ;
  var totalProduct = [];
  var totalTVA = [];

  var isValid = [];

  productArray.forEach(item => {

    var productPriceTTC = 0
    var productTVA = 0 
    var productQuantity = 0
    var productPriceHT = 0

    item.providers.forEach(provider => {

      productPriceTTC = (provider.quantity * addTVA(provider.priceHT , item.tax)) + productPriceTTC;
      productPriceHT = (provider.quantity * provider.priceHT) + productPriceHT
      productQuantity = productQuantity + provider.quantity;

      var TVA = provider.tax > 0 ? addTVA(provider.priceHT * provider.quantity , item.tax) - (provider.quantity * provider.priceHT) : 0 ;

      productTVA = TVA + productTVA; 

    });

    if (item.quantity * addTVA(item.priceHT , item.tax) !== productPriceTTC) {
      isValid.push("false");
    } 

    var foundIndexTVA = findWithAttr(totalTVA , 'tax' , item.tax );
    if (totalTVA[foundIndexTVA]) {
      totalTVA[foundIndexTVA].total = totalTVA[foundIndexTVA].total + productTVA
    } else {
      totalTVA.push({
        tax: item.tax,
        total: productTVA
      });
    }

    totalTTC = productPriceTTC + totalTTC ;

    totalProduct.push({
      quantity: productQuantity,
      priceTTC: productPriceTTC
    });

  });

  return {
    totalTTC: totalTTC ,
    totalTVA: totalTVA ,
    totalProduct: totalProduct,
    isValid: isValid.length ? false : true
  }
}

function findWithAttr(array, attr, value) {
  for(var i = 0; i < array.length; i += 1) {
      if(array[i][attr] === value) {
          return i;
      }
  }
  return -1;
}

export function addTVA(priceHT , tax ) {
  return (priceHT * ( tax / 100  + 1 ))
}

export function removeTVA(priceHT , tax ) {
  return (priceHT / ( tax / 100  + 1 ))
}

export function filterByCreateDatePeriod(array , start , end) {
  var endDate = new Date(end);
  var result = array.filter(item => {
    if (item.createdAt && new Date(item.createdAt) >= start && new Date(item.createdAt) <= endDate  ) {
      return true
    } else {
      return false
    }
  });
  return sortByUpdateDate(result)
}

export function filterByDatePeriodKey(key ,array , start , end) {
  let startDate = new Date(start).setHours(0,0,0,0);
  let endDate = new Date(end).setHours(24,59,59,59);
  var result = array.filter(item => {
    let date = item[key] ? new Date(item[key]) : new Date(item.createdAt ) ;
    if (date < startDate || date > endDate ) {
      return false
    } else {
      return item
    }
  });
  return sortByDateKey( key , result )
}


export function filterBySendDatePeriod(array , start , end) {
  var endDate = new Date(end);
  var result = array.filter(item => {
    if (item.date && new Date(item.date) >= start && new Date(item.date) <= endDate  ) {
      return true
    } else {
      return false
    }
  });
  return sortByUpdateDate(result)
}


export function fnFormatFixed(number) {
  let value = format({integerSeparator: " "})(number.toFixed(2))
  if(/.0/.test(value)){
    return value.replace(".",",")
  }else{
    return value
  }
}

export function formatPhone(val) {
  let phone = val.replace( /\s/g, '')
  let str = "" ;
  for (let i = 0; i < phone.length - 1; i = i + 2) {
    str = str + phone[i] + phone[i + 1] + " ";
  }
  return str ;
}