import React , { useEffect , useState , useCallback } from 'react';
import { Switch, Route , useLocation } from "react-router-dom";
import { useTransition, animated } from 'react-spring';

import { useGlobalContext } from '../../context/GlobalContext';
import { getUsersAction } from '../../../actions/userActions';
import { getProductsAction } from '../../../actions/productsActions';
// import { getQuotesAction } from '../../../actions/quotesActions';
// import { getReceiptsAction } from '../../../actions/receiptsActions';
// import { getCreditsAction } from '../../../actions/creditsActions';

import ProvidersList from './AdminPages/Providers/ProvidersList';

import ClientList from './AdminPages/Clients/ClientsList';
import User from './AdminPartials/User';

import ProductsList from './AdminPages/Products/ProductsList';

import QuotesList from './AdminPages/Quotes/QuotesList';
import EditQuote from './AdminPages/Quotes/EditQuote';

import ReceiptsList from './AdminPages/Receipts/ReceiptsList';
import EditReceipt from './AdminPages/Receipts/EditReceipt';

import CreditsList from './AdminPages/Credits/CreditsList';

import Dashboard from './AdminPages/Dashboard/Dashboard';
import Nav from './AdminPartials/Nav';
import Toaster from '../../partials/Toaster';

export default function AdminRouter(props) {

  const { logout , match } = props;

  const [ context, dispatch ] = useGlobalContext();

  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(0, 500px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0px, -500px,0)'},
  })

  var token = localStorage.getItem('token');

  const [ activePage , setActivePage ] = useState("dashboard");

  const getDatas = useCallback(() => {
      getUsersAction(dispatch);
      getProductsAction(dispatch);
      // getQuotesAction(dispatch);
      // getReceiptsAction(dispatch);
      // getCreditsAction(dispatch);
    },[dispatch],)

  useEffect(() => {
    if(!token) {
      logout();
    } else {
      getDatas();
    }
  // eslint-disable-next-line
  }, []);

  return (
    <>
    <div className="page-container">
      <Nav
        path={match.path}
        activePage={activePage}
        logout={logout}/>
        {transitions.map(({ item: location, props, key }) => (
          <animated.div key={key} style={props}>
            <Switch location={location}>

              <Route path={`${match.path}/receipt-view/:receiptId`} render={
                (props) => <EditReceipt
                context={context}
                dispatch={dispatch}
                isPreview={true}
                path={match.path}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/edit-receipt/:receiptId`} render={
                (props) => <EditReceipt
                context={context}
                dispatch={dispatch}
                path={match.path}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/create-receipt`} render={
                (props) => <EditReceipt
                context={context}
                path={match.path}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/receipts`} render={
                (props) => <ReceiptsList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/credits`} render={
                (props) => <CreditsList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/edit-quote/:quoteId`} render={
                (props) => <EditQuote
                context={context}
                dispatch={dispatch}
                path={match.path}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/create-quote`} render={
                (props) => <EditQuote
                context={context}
                path={match.path}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/quotes`} render={
                (props) => <QuotesList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/products`} render={
                (props) => <ProductsList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/clients`} render={
                (props) => <ClientList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/client/:userId`} render={
                (props) => <User
                type={"client"}
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/provider/:userId`} render={
                (props) => <User
                type={"provider"}
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={`${match.path}/providers`} render={
                (props) => <ProvidersList
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>

              <Route path={match.path} render={
                (props) => <Dashboard
                context={context}
                dispatch={dispatch}
                setActivePage={setActivePage}
                {...props}
                />
              }/>
            </Switch>
          </animated.div>
        ))}
        <Toaster toast={context.users.toast} dispatch={dispatch}/>
    </div>
    </>
  )
}
