import React from 'react';
import { Link } from "react-router-dom";
import { useGlobalContext } from '../../../context/GlobalContext';

import logo from '../../../../assets/images/logo.png'; 

export default function Nav({ logout , path , activePage }) {

  const context = useGlobalContext()[0];

  return (
    <div className="nav-container">

      <div className="logo">
        <img src={logo} className="img" alt="logo"/>
      </div>

      <button
        onClick={() => logout()}
        className="btn">Déconnexion
      </button>

      {context && context.auth && context.auth.userAuth && context.auth.userAuth.role === "Admin" &&
        <ul>
          <li>
            <Link to={path} className={`bold ${activePage === "dashboard" ? "isActive" : ""}`}>
              <h2>Dashboard</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/quotes`} className={`bold ${activePage === "quotes" ? "isActive" : ""}`}>
              <h2>PROPOSITIONS</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/receipts`} className={`bold ${activePage === "receipts" ? "isActive" : ""}`}>
              <h2>REçUS</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/credits`} className={`bold ${activePage === "credits" ? "isActive" : ""}`}>
              <h2>AVOIRS</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/products`} className={`bold ${activePage === "products" ? "isActive" : ""}`}>
              <h2>Produits</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/clients`} className={`bold ${activePage === "clients" ? "isActive" : ""}`}>
              <h2>Clients</h2>
            </Link>
          </li>
          <li>
            <Link to={`${path}/providers`} className={`bold ${activePage === "providers" ? "isActive" : ""}`}>
              <h2>Indépendants</h2>
            </Link>
          </li>
        </ul>
      }

      {context && context.auth && context.auth.userAuth && context.auth.userAuth.role  === "Member" &&
        <ul>
          <li>
            {context.auth.userAuth.type === "client" &&
              <>
                <Link to={path + `/client/${context.auth.userAuth._id}`} className={`bold ${activePage === "clients" || activePage === "providers" ? "isActive" : ""}`}>
                  <h2>Informations</h2>
                </Link>

                <Link to={path + `/receipts-client/${context.auth.userAuth._id}`} className={`bold ${activePage === "receipts" ? "isActive" : ""}`}>
                  <h2>Reçus</h2>
                </Link>

                {context.auth.attestations &&
                  <Link to={path + `/attestations`} className={`bold ${activePage === "attestations" ? "isActive" : ""}`}>
                    <h2>Attestations</h2>
                  </Link>
                }

              </>
            }
            {context.auth.userAuth.type === "provider" &&
              <>
                <Link to={path + `/provider/${context.auth.userAuth._id}`} className={`bold ${activePage === "clients" || activePage === "providers" ? "isActive" : ""}`}>
                  <h2>Informations</h2>
                </Link>
                <Link to={path + `/invoices/${context.auth.userAuth._id}`} className={`bold ${activePage === "invoices" ? "isActive" : ""}`}>
                  <h2>Factures</h2>
                </Link>
                <Link to={path + `/credits/${context.auth.userAuth._id}`} className={`bold ${activePage === "credits" ? "isActive" : ""}`}>
                  <h2>Avoirs</h2>
                </Link>
              </>
            }
          </li>
        </ul>
      }  

    </div>
  )
}
