import React , { useEffect , useState } from 'react';

import { sortByUpdateDate } from '../../../../../utils/utils';

import ProductRow from './ProductsRow';
import Modale from '../../../../partials/Modale';

import './products.scss';
import EditProducts from './EditProduct';

export default function ProvidersList(props) {
  const { setActivePage , context , dispatch , match } = props;

  const [ allProducts , setAllProducts ] = useState();
  const [ search , setSearch ] = useState();

  const [ displayAddProductsModale , setDisplayAddProductsModale ] = useState(false);
  const [ product , setProduct ] = useState();

  useEffect(() => {
    setActivePage("products");
    // eslint-disable-next-line 
  }, [])

  function editProduct(product) {
    setProduct(product);
    setDisplayAddProductsModale(true);
    dispatch({
      type : 'GET_PRODUCT',
      payload : null
    });
  }

  function closeProductModale() {
    setProduct();
    setDisplayAddProductsModale(false);
    dispatch({
      type : 'GET_PRODUCT',
      payload : null
    });
  }

  useEffect(() => {
    if (context.products.productsList) {
      setAllProducts(sortByUpdateDate(context.products.productsList))
    }
  }, [context.products.productsList]);

  useEffect(() => {
    if (context.products.productsList) {
      if (search) {
        var newList = context.products.productsList.filter(product => product.name.toLowerCase().includes(search.toLowerCase()))
        setAllProducts(sortByUpdateDate(newList));
      } else {
        setAllProducts(sortByUpdateDate(context.products.productsList))
      }
    }
  }, [search , context.products.productsList]);

  function renderProductsList() {
    if (allProducts) {
      return allProducts.map((product, i) => (<ProductRow key={i + product._id} product={product} dispatch={dispatch} editProduct={editProduct} path={match.path}/>))
    }
  }

  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="row">
          <div className="col-1">

            <div className="header">
              <h2>Produits</h2>
              <div className="actions">
                <input
                  placeholder="Rechercher un produit"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search || ''}
                />
                <button
                  onClick={() => setDisplayAddProductsModale(true)}
                  className="btn primary">
                  Nouveau
                </button>
              </div>
            </div>

            <div className="list-container">

              <div className="product-row label-row bold">
                <div className="label uppercase">
                  <p>DÉNOMINATION</p>
                </div>

                <div className="label uppercase">
                  <p>Unité</p>
                </div>

                <div className="label uppercase">
                  <p>Prix TTC</p>
                </div>

                <div className="label uppercase">
                  <p>VOLUME</p>
                </div>

                <div className="label uppercase">
                  <p>Chiffre d’affaires</p>
                </div>

                <div className="icon">
                </div>

              </div>

              {renderProductsList()}
            </div>

          </div>
          <Modale
            modaleIsOpen={displayAddProductsModale}
            modaleToggle={closeProductModale}
            className={"product-modale"}
            >
            {displayAddProductsModale && <EditProducts closeModale={closeProductModale} product={product} context={context} dispatch={dispatch} />}
          </Modale>
        </div>
      </div>
    </div>
  )
}

