import {
  GET_CREDITS,
  ERROR_CREDITS,
  MESSAGE_CREDITS,
  CREATE_CREDIT,
} from "../actions/types";

const initialState = {
  creditsList: [],
  error: null,
  message: null,
};

export default function creditsReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_CREDIT:
      var addToList = [...state.creditsList];
      // addToList.push(action.payload.credit);
      return {...state ,
        message : action.payload.message,
        creditsList: addToList,
      }

    case GET_CREDITS:
      return {...state , creditsList: action.payload , message : null }

    case ERROR_CREDITS:
      return {...state , error: action.payload}

    case MESSAGE_CREDITS:
      return {...state , message : action.payload}

    default:
      return {...state};

  }
}