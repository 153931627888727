import React, { useEffect , useState } from 'react';
import { useHistory , useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { useGlobalContext } from '../../context/GlobalContext';
import { resetPasswordAction } from '../../../actions/authActions';

import passwordImg from '../../../assets/images/icons/password.svg';
import logo from '../../../assets/images/logo.png';
import './auth.scss';

export default function ResetPasswordPage(props) {

  const history = useHistory();
  const params = useParams();

  const { handleSubmit, register, errors } = useForm();
  const [ context, dispatch ] = useGlobalContext();

  const [ errorReset , setErrorReset] = useState();
  const [ errorPassword , setErrorPassword ] = useState();
  const [ message , setMessage ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  useEffect(() => {
    if (context.auth.message && context.auth.message.reset) {
      setMessage("Le mot de passe a été modifié avec succès. Veuillez vous connecter avec votre nouveau mot de passe.")
    }
    console.log(context.auth.error)
    if (context.auth.error && context.auth.error.data && context.auth.error.data.error === "Your token has expired. Please attempt to reset your password again.") {
      setErrorReset("Votre jeton a expiré. Veuillez réessayer de réinitialiser votre mot de passe.")
    }
    if (context.auth.error && context.auth.error.data && context.auth.error.data.error === "You must enter a password strong enough.") {
      setErrorPassword("Vous devez entrer un mot de passe suffisamment fort.")
    }
  }, [context.auth.message , context.auth.error])

  const submitPassword = (values)  => {
    values.token = params.token
    resetPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  function renderConfirmPassword() {
    return (
      <form className="form-container" onSubmit={handleSubmit(submitPassword)}>
        
        {!errorReset && !message &&
          <>
            <label htmlFor="password">Mot de passe</label>
            <input
              name="password"
              type={showPassword? "text" : "password"}
              ref={register({
                required: "Compléter votre mot de passe",
              })}
            />

            <img src={passwordImg} onClick={() => setShowPassword(!showPassword)} alt="password" />


            {errors.password  && errors.password.message && <p className="text-error">{errors.password.message}</p>}
            {errorPassword && <p className="text-error">{errorPassword}</p> }

            <div className="btn-container">
              <button className="btn primary" type="submit">Enregistrer le nouveau mot de passe</button>
            </div>
          </>
        }

        {errorReset && 
          <>
            <p className="text-center">{errorReset}</p>
            <div className="btn-container">
              <button className="btn primary" onClick={() => history.push("/")}>Retour à la page de connexion</button>
            </div>
          </>
        }

        {message && 
          <>
            <p className="text-center">{message}</p>
            <button className="btn primary" onClick={() => history.push("/")}>Retour à la page de connexion</button>
          </>
        }
    
      </form>
    )
  }

  return (
    <div className="page-container login-page">
      <div className="login-container">
        <img src={logo} alt="logo" className="logo"/>
        {renderConfirmPassword()}
      </div>
    </div>
  );
};