export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              GET_DASHBOARD_BY_PERIOD = 'GET_DASHBOARD_BY_PERIOD',
              GET_DASHBOARD_CA = 'GET_DASHBOARD_CA',
              GET_ATTESTATIONS = 'GET_ATTESTATIONS',
              //USER ACTIONS
              GET_USER = 'GET_USER',
              GET_USERS = 'GET_USERS',
              ERROR_USERS = 'ERROR_USERS',
              CREATE_USER = 'CREATE_USER',
              MESSAGE_USERS = 'MESSAGE_USERS',
              UPDATE_USER = 'UPDATE_USER',
              CREATE_IBAN = 'CREATE_IBAN',
              DELETE_USER = 'DELETE_USER',
              CREATE_USER_MANDATE = 'CREATE_USER_MANDATE',
              GET_INVOICES = 'GET_INVOICES',
              POST_USER_CA = 'POST_USER_CA',
              //PRODUCTS ACTIONS
              GET_PRODUCTS = 'GET_PRODUCTS',
              ERROR_PRODUCTS = 'ERROR_PRODUCTS',
              MESSAGE_PRODUCTS = 'MESSAGE_PRODUCTS',
              CREATE_PRODUCT = 'CREATE_PRODUCT',
              UPDATE_PRODUCT = 'UPDATE_PRODUCT',
              DELETE_PRODUCT = 'DELETE_PRODUCT',
              //QUOTES ACTIONS
              GET_QUOTES = 'GET_QUOTES',
              GET_QUOTE= 'GET_QUOTE',
              SET_QUOTES_FILTERS = 'SET_QUOTES_FILTERS',
              GET_QUOTES_FILTERED = 'GET_QUOTES_FILTERED',
              ERROR_QUOTES = 'ERROR_QUOTES',
              CREATE_QUOTE = 'CREATE_QUOTE',
              UPDATE_QUOTE = 'UPDATE_QUOTE',
              MESSAGE_QUOTES = 'MESSAGE_QUOTES',
              DELETE_QUOTE = 'DELETE_QUOTE',
              SEND_QUOTE = 'SEND_QUOTE',
              //RECEIPTS ACTIONS
              GET_RECEIPTS = 'GET_RECEIPTS',
              GET_RECEIPT = 'GET_RECEIPT',
              SET_RECEIPT_FILTERS = 'SET_RECEIPT_FILTERS',
              GET_RECEIPT_FILTERED = 'GET_RECEIPT_FILTERED',
              ERROR_RECEIPTS = 'ERROR_RECEIPTS',
              CREATE_RECEIPT = 'CREATE_RECEIPT',
              UPDATE_RECEIPT = 'UPDATE_RECEIPT',
              MESSAGE_RECEIPTS = 'MESSAGE_RECEIPTS',
              DELETE_RECEIPT = 'DELETE_RECEIPT',
              VALID_RECEIPT ='VALID_RECEIPT',
              PAY_RECEIPT = 'PAY_RECEIPT',
              GET_RECEIPT_INVOICES = 'GET_RECEIPT_INVOICES',
              PAY_BY_CARD = 'PAY_BY_CARD',
              SEND_RECEIPT = 'SEND_RECEIPT',
              //CREDIT ACTIONS
              GET_CREDITS = 'GET_CREDITS',
              ERROR_CREDITS = 'ERROR_CREDITS',
              CREATE_CREDIT = 'CREATE_CREDIT',
              MESSAGE_CREDITS = 'MESSAGE_CREDITS'