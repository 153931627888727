import React from 'react';
import * as moment from 'moment';
import optIcon from '../../../../../assets/images/icons/options-icon.svg';

import { calcPrice } from '../../../../../utils/utils';
import { getInvoiceFileAction } from '../../../../../actions/receiptsActions';

export default function InvoiceRow({ invoice , dispatch , context }) {

  function renderClientName() {
    if(invoice && invoice.client && context.users.usersList.find(user => user._id === invoice.client)) {
      let client = context.users.usersList.find(user => user._id === invoice.client);
      if (client && client.LegalRepresentativeFirstName) {
        return `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`
      } else if (client.FirstName) {
        return `${client.FirstName} ${client.LastName}`
      }
    }
  }

  return (
    <div className="invoice-row">
      <div className="invoice-row border">

        <div className="invoice-label uppercase">
          <p>{invoice.number}</p>
        </div>

        <div className="invoice-label uppercase">
          <p>{renderClientName()}</p>
        </div>

        <div className="invoice-label uppercase">
          <p>{moment(invoice.createdAt).format("DD/MM/YYYY")}</p>
        </div>

        <div className="invoice-label uppercase">
          <p>{calcPrice(invoice.content).totalTTC} €</p>
        </div>
      </div>

      <div className="dropdown">
        <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
        <div className="dropdown-content">
          <div>
            <button onClick={() => getInvoiceFileAction(dispatch , invoice )}>
              Télécharger la facture
            </button>
          </div>
        </div>
      </div>
     
    </div>
  )
}
