import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";

import  { createIbanAction } from "../../../../actions/userActions";

export default function EditIban(props) {
  const { user , context , dispatch , closeModale } = props;
  console.log(props)

  const { handleSubmit, register, errors } = useForm();

  const submitIban = (values)  => {
    var address = user.Address ? user.Address : user.HeadquartersAddress
    var name = user.FirstName ? `${user.FirstName} ${user.LastName}` : `${user.LegalRepresentativeFirstName} ${user.LegalRepresentativeLastName}`
    const iban = {
      Type: "IBAN",
      OwnerAddress: address,
      OwnerName: name,
      IBAN: values.IBAN,
      BIC: values.BIC
    }
    return createIbanAction( dispatch , `${user._id}/iban` , iban)
  }

  useEffect(() => {
    if (context.users.message === "iban created") {
      closeModale(false);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le compte a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_USERS',
        payload: null
      });

    }
  }, [context.users.message , closeModale , dispatch ])

  return (
    <div className="edit-user-container">
      <form className="account" onSubmit={handleSubmit(submitIban)}>

        <div className="form-container">

          <label htmlFor="IBAN">IBAN</label>
          <input
            name="IBAN"
            defaultValue={null}
            ref={register({
              required: "Compléter l'Iban",
              pattern: {
                value: /^([A-Z]{2}[ ]?[0-9]{2})(?=(?:[ ]?[A-Z0-9]){9,30}$)((?:[ ]?[A-Z0-9]{3,5}){2,7})([ ]?[A-Z0-9]{1,3})?$/,
                message: "Iban invalide"
              }
            })}
          />
          <p className="text-error">{errors.IBAN && errors.IBAN.message}</p>

          <label htmlFor="BIC">BIC</label>
          <input
            name="BIC"
            defaultValue={null}
            ref={register({
              required: "Compléter le Bic",
              pattern: {
                value: /^[a-z]{6}[2-9a-z][0-9a-np-z]([a-z0-9]{3}|x{3})?$/i,
                message: "Bic invalide" 
              }
            })}
          />
          <p className="text-error">{errors.BIC && errors.BIC.message}</p>

        </div>
        
        {context.users.error && 
          <p className="text-error">
            {context.users.error.data && context.users.error.data.error && context.users.error.data.error === "bankAccount already created" && "Le compte à déja été créé"}
          </p>
        }

        <div className="btn-container">
          <button className="btn primary" type="submit">Enregistrer</button>
        </div>

      </form>
    </div>
  )
}
