import React , { useEffect } from 'react';
import close from '../../assets/images/icons/close.svg';

import './modale.scss';
//change module name
export default function Modale({ modaleIsOpen , modaleToggle , className , hideClose , children }) {

  useEffect(() => {
    var element = document.getElementsByClassName("router-transition-container")[0];
    var root = document.getElementsByClassName("nav-container")[0];

    if (modaleIsOpen) {
      root.style = {}
      root.style.pointerEvents = 'none';
      root.style.opacity = 0.2;
      element.style = {}
      element.style.overflow = 'hidden';
    } else {
      element.style = {}
      root.style = {}
    }
    return () => {
      element.style = {}
      root.style = {}
    }
  }, [modaleIsOpen]);

  return (
    <div className={`modale-container ${modaleIsOpen? "isActive" : ""}`} >
      <div className={`modale ${className} ${modaleIsOpen? "isActive" : ""}`}>
        {!hideClose && <button
            onClick={() => modaleToggle(false)}
            className="close">
            <img src={close} alt="close" />
          </button>
        }
        {children}
      </div>
    </div>
  )
}