import React from 'react';
// import useDimensions from "react-use-dimensions";
import { GlobalProvider } from './components/context/GlobalContext';

import Router from './components/Router';

import './styles/index.scss';

import quotesReducer from './reducers/quotesReducer';
import authReducer from './reducers/authReducer';
import usersReducer from './reducers/usersReducer';
import productsReducer from './reducers/productsReducer';
import receiptsReducer from './reducers/receiptsReducer';
import creditsReducer from './reducers/creditsReducer';
// import MobilePage from './components/pages/MOBILE/MobilePage';

export default function App() {

  // const [ref, { width }] = useDimensions();

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    users: usersReducer,
    products: productsReducer,
    quotes: quotesReducer,
    receipts: receiptsReducer,
    credits: creditsReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
        reducers={reducers}
        initialState={initialState}
        >
      <Router />
      {/* <div ref={ref}>
      {width >= 1024 ?
        <Router/>
        :
        <MobilePage />
      }
      </div> */}
    </GlobalProvider>
  );
};
