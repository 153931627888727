export const customSelect = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    marginTop:7,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    padding: "0px 5px"
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: 0
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    boxShadow: "none",
    maxHeight: 30,
    borderRadius: 0,
    border: state.isFocused || state.isSelected ? "1px solid #C9BBB1" : "1px solid #C9BBB1",
    '&:hover': {
      border: state.isFocused || state.isSelected ? "1px solid #C9BBB1" : "1px solid #C9BBB1",
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: -1,
    borderRadius: 0,
    border: "1px solid #C9BBB1"
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isFocused || state.isDisabled || state.isSelected ? "#000000" : "#000000",
    backgroundColor: state.isFocused || state.isDisabled || state.isSelected ? "rgb(243, 227, 215)" : "#FFFFFF"
  })
}

export const customSort = {
  container: (provided, state) => ({
    ...provided,
    height: 39 ,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 32 ,
    padding: "0px 5px"
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: 0
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    boxShadow: "none",
    height: 30,
    borderRadius: 0,
    border: state.isFocused || state.isSelected ? "1px solid #C9BBB1" : "1px solid #C9BBB1",
    '&:hover': {
      border: state.isFocused || state.isSelected ? "1px solid #C9BBB1" : "1px solid #C9BBB1",
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: -1,
    borderRadius: 0,
    border: "1px solid #C9BBB1"
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isFocused || state.isDisabled || state.isSelected ? "#000000" : "#000000",
    backgroundColor: state.isFocused || state.isDisabled || state.isSelected ? "rgb(243, 227, 215)" : "#FFFFFF"
  })
}