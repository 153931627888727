import { getData , postData , putData , deleteData } from './index';

import {
  GET_USERS,
  GET_USER,
  CREATE_USER,
  ERROR_USERS,
  UPDATE_USER,
  CREATE_IBAN,
  DELETE_USER,
  POST_USER_CA ,
  CREATE_USER_MANDATE,
  GET_INVOICES 
} from "./types"

export async function getUsersAction(dispatch) {
  let url = "/user";
  var user;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    user = response.users
  });

  dispatch({
    type : GET_USERS,
    payload : user
  });

};

export async function getUserAction(dispatch , id) {
  let url = "/user/" + id;
  var user;
  var bank;
  var mandates;
  var ca;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    user = response.user
    bank = response.bankAccounts ? response.bankAccounts : null
    mandates = response.mandates ? response.mandates : null
    ca = response.ca ? response.ca : null
  });

  if (bank) user.bankAccounts = bank[0];
  if (mandates) user.mandates = mandates[0];
  if (ca) user.ca = ca;

  dispatch({
    type : GET_USER,
    payload : user
  });

};

export async function getInvoicesAction(dispatch , userType , id) {
  let url = `/invoice/${userType}/${id}`;
  var invoices;

  await getData(ERROR_USERS, url, dispatch , true ).then((response)=>{
    invoices = response.invoices
  });

  dispatch({
    type : GET_INVOICES,
    payload : invoices
  });

};

export async function createIbanAction(dispatch , id , data ) {
  let url = "/user/" + id ;
  var user;
  var bank;

  await postData( CREATE_IBAN , ERROR_USERS ,  url , dispatch, data , true ).then((response)=>{
    user = response.user
    bank = response.bankAccount ? response.bankAccount : null
  });
  if (bank) user.bankAccounts = bank


  dispatch({
    type : CREATE_IBAN,
    payload : {
      message: "iban created",
      user: user
    }
  });

};

export async function createUserAction(dispatch , type , data ) {
  let url = "/user/" + type;
  var user;

  await postData( CREATE_USER , ERROR_USERS ,  url , dispatch, data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : CREATE_USER,
    payload : user
  });

};

export async function createUserMandateAction(dispatch , id ) {
  let url = `/user/${id}/mandate`;
  var user;

  await postData( CREATE_USER_MANDATE , ERROR_USERS ,  url , dispatch, null , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : CREATE_USER_MANDATE ,
    payload : user
  });

};

export async function updateUserAction(dispatch , id , data ) {
  let url = "/user/" + id;
  var user;

  await putData(UPDATE_USER, ERROR_USERS ,  url, dispatch, data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : UPDATE_USER,
    payload : user
  });

};

export async function deleteUserAction(dispatch , id) {
  let url = "/user/" + id;

  await deleteData(ERROR_USERS, url, dispatch, true ).then((response)=>{
    dispatch({
      type : DELETE_USER,
      payload : id
    });
  });

};

export async function postUserCaAction(dispatch , id , data ) {
  let url = `/user/${id}/ca`;
  var ca;

  await postData( POST_USER_CA , ERROR_USERS ,  url , dispatch, data , true ).then((response)=>{
    ca = response.ca
  });

  dispatch({
    type : POST_USER_CA ,
    payload : ca
  });

};