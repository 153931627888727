import { getData , postData , putData , deleteData , getFile } from './index';

import {
  GET_RECEIPTS,
  GET_RECEIPT,
  ERROR_RECEIPTS,
  CREATE_RECEIPT,
  UPDATE_RECEIPT,
  DELETE_RECEIPT,
  VALID_RECEIPT,
  PAY_RECEIPT,
  SEND_RECEIPT,
  GET_RECEIPT_INVOICES,
  GET_RECEIPT_FILTERED,
} from "./types"

export async function getReceiptsAction(dispatch) {
  let url = "/receipt";
  var receipts;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    receipts = response.receipts
  });

  dispatch({
    type : GET_RECEIPTS,
    payload : receipts
  });

};

export async function getReceiptsByClientAction(dispatch , id) {
  let url = `/receipt/client/${id}`;
  var receipts;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    receipts = response.receipts
  });

  dispatch({
    type : GET_RECEIPTS,
    payload : receipts
  });

};

export async function sendReceiptMailAction(dispatch , id) {
  let url = `/receipt/${id}/mail`;
  var receiptMail;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    receiptMail = response
  });

  receiptMail.message = "Receipt mail send"

  dispatch({
    type : SEND_RECEIPT,
    payload : receiptMail
  });

};


export async function getReceiptAction(dispatch , id) {
  let url = "/receipt/" + id ;
  var receipt;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    receipt = response.receipt
  });

  dispatch({
    type : GET_RECEIPT,
    payload : receipt
  });

};

export async function getReceiptsFilteredAction(dispatch , data ) {
  let url = "/receipt/filtered";
  var receipt;

  await postData( GET_RECEIPT_FILTERED , ERROR_RECEIPTS ,  url , dispatch, data , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : GET_RECEIPT_FILTERED,
    payload : receipt
  });

};



export async function createReceiptAction(dispatch , data ) {
  let url = "/receipt";
  var receipt;

  await postData( CREATE_RECEIPT , ERROR_RECEIPTS ,  url , dispatch, data , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : CREATE_RECEIPT,
    payload : receipt
  });

};

export async function validReceiptAction(dispatch , id ) {
  let url = `/receipt/${id}/valid`;
  var receipt;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : VALID_RECEIPT,
    payload : receipt
  });

};

export async function payReceiptAction(dispatch , data , payementType ) {
  let url = `/receipt/${data._id}/paiement/${payementType}`
  var receipt;

  await postData( PAY_RECEIPT , ERROR_RECEIPTS ,  url , dispatch, data.email ? { email: data.email } : null , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : PAY_RECEIPT,
    payload : receipt
  });

};

export async function getReceiptFileAction(dispatch , id , number ){
  let url = `/receipt/${id}/pdf`
  let receipt;
  await getFile( ERROR_RECEIPTS , url, dispatch , true).then((response)=>{
    receipt = response ;
    var fileURL = URL.createObjectURL(receipt);
    var downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = `Reçu n°${number} - Ma petite Conciergerie.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
};

export async function getInvoiceFileAction(dispatch , invoice){
  let url = `/invoice/${invoice._id}/pdf`
  let receipt;
  await getFile( ERROR_RECEIPTS , url, dispatch , true).then((response)=>{
    receipt = response;
    var fileURL = URL.createObjectURL(receipt);
    var downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = `Facture n°${invoice.number} - Ma petite Conciergerie.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  });
};

export async function getReceiptInvoicesAction(dispatch , id) {
  let url = `/invoice/receipt/${id}`;
  var invoices;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    invoices = response.invoices
  });

  dispatch({
    type : GET_RECEIPT_INVOICES,
    payload : invoices
  });

};

export async function updateReceiptAction(dispatch , id , data ) {
  let url = "/receipt/" + id;
  var receipt;

  await putData( UPDATE_RECEIPT , ERROR_RECEIPTS ,  url, dispatch, data , true ).then((response)=>{
    receipt = response
  });

  dispatch({
    type : UPDATE_RECEIPT,
    payload : receipt
  });

};

export async function deleteReceiptAction(dispatch , id) {
  let url = "/receipt/" + id;

  await deleteData(ERROR_RECEIPTS, url, dispatch, true ).then((response)=>{
    dispatch({
      type : DELETE_RECEIPT,
      payload : id
    });
  });

};


export async function  payReceiptByCardAction(dispatch , id ) {
  let url = `/receipt/${id}/paiement/card/direct`;

  await getData(ERROR_RECEIPTS, url, dispatch , true ).then((response)=>{
    window.open(response.url);
  });

};
