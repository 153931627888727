import React, { useEffect , useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction } from '../../../actions/authActions';

import logo from '../../../assets/images/logo.png';
import passwordImg from '../../../assets/images/icons/password.svg';
import './auth.scss';

export default function LoginPage(props) {
  
  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();

  const [ displayLogin , setDisplayLogin ] = useState(true);
  const [ errorForgot , setErrorForgot ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  useEffect(() => {
    if (context.auth.userAuth && context.auth.userAuth.type === "mpc" && context.auth.userAuth.role === "Admin") {
      history.push("/admin")
    } else if (context.auth.userAuth && context.auth.userAuth.type === "client" && context.auth.userAuth.role === "Member") {
      history.push(`/member/client/${context.auth.userAuth._id}`)
    } else if (context.auth.userAuth && context.auth.userAuth.type === "provider" && context.auth.userAuth.role === "Member") {
      history.push(`/member/provider/${context.auth.userAuth._id}`)
    }
  }, [context.auth.userAuth , history])

  useEffect(() => {
    if (context.auth.error && context.auth.error.status === 422) {
      setErrorForgot("Votre demande n'a pas pu être traitée telle qu'elle a été saisie. Veuillez réessayer.")
    }
  }, [context.auth.error])


  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  function renderLoginForm() {
    return (
      <form className="form-container" onSubmit={handleSubmit(submitLogin)}>

        <label htmlFor="email">Email</label>
        <input
          name="email"
          ref={register({
            required: "Compléter votre email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Adresse mail invalide"
            }
          })}
        />

        <p className="text-error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Mot de passe</label>
        <input
          name="password"
          type={showPassword? "text" : "password"}
          ref={register({
            required: "Compléter votre mot de passe",
          })}
        />

        <img src={passwordImg} onClick={() => setShowPassword(!showPassword)} alt="password" />

        <p className="text-error">{errors.password && errors.password.message}</p>
        
        <div className="btn-container">
        <p className="text-error error-connexion text-center">{context.auth.error && context.auth.error === 401 && <span> Adresse e-mail ou mot de passe invalide.</span>}</p>

          <button className="btn primary" type="submit">Connexion</button>
          <button onClick={() => setDisplayLogin(false)} className="btn-reset">Mot de passe oublié ?</button>
        </div>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className="form-container">

          <p className="text-center">Nous venons de vous envoyer un mail, <br/>veuillez cliquer sur le lien permettant de réinitialiser votre mot de passe.</p>

          <button onClick={() =>  backFromReset()} className="btn primary">Retour au login</button>
        </div>
      )

    } else {
      return (
        <form className="form-container" onSubmit={handleSubmit(submitReset)}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              ref={register({
                required: "Compléter votre email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Adresse mail invalide"
                }
              })}
            />
          </div>

          {errorForgot? 
            <p className="text-error text-center" style={{ margin: 5}}>{errorForgot}</p>
            :
            <p className="text-error text-center">{errors.email && errors.email.message}</p>
          }
          
          <div className="btn-container">
            <button className="btn primary" type="submit">Réinitialiser le mot de passe</button>
            <button onClick={() => setDisplayLogin(true)} className="btn-reset">Retour au login</button>
          </div>
      
        </form>
      )
    }
  }

  return (
    <div className="page-container login-page">
      <div className={`login-container ${displayLogin ? "" : "reset" }`}>
        <img src={logo} alt="logo" className="logo" />

        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }

      </div>
    </div>
  )
}
