import React from 'react';
import { Link } from  'react-router-dom';
import * as moment from 'moment';
import optIcon from '../../../../../assets/images/icons/options-icon.svg';

import { createReceiptAction } from '../../../../../actions/receiptsActions';
import { deleteQuoteAction , getQuoteFileAction , sendQuoteMailAction ,  createQuoteAction,  updateQuoteAction } from '../../../../../actions/quotesActions';

import { calcPrice } from '../../../../../utils/utils';

export default function QuoteRow(props) {
  
  const { quote , dispatch , context } = props;

  function submitAsReceipt() {
      var receiptData = {}
      receiptData.content = quote.content;
      receiptData.client = quote.client;
      receiptData.description = quote.description;
      receiptData.quote = quote._id
      return createReceiptAction(dispatch , receiptData);;
  }

  function HandleChangeState(state) {
    var quoteData = { ...quote , state :state }
    return updateQuoteAction(dispatch , quoteData._id , quoteData)
  }
  
  function copyQuote() {
    let data = quote;
    delete data._id
    createQuoteAction(dispatch , { ...data, status: 'draft'});
  }

  function renderState(state) {
    if (state === "draft") {
      return <p className={state}>Brouillon</p>
    } else if (state === "valid") {
      return <p className={state} >Acceptée</p> 
    } else if (state === "sent") {
      return <p className={state} >Envoyée</p>
    } else if (state === "seen") {
      return <p className={state} >Vue</p>
    } else if (state === "refused") {
      return <p className={state} >Refusée</p>
    }
  }

  function renderClientName() {
    if(quote && quote.client && context.users.usersList?.find(user => user._id === quote.client)) {
      let client = context.users.usersList.find(user => user._id === quote.client);
      if (client && client.LegalRepresentativeFirstName) {
        return `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`
      } else if (client.FirstName) {
        return `${client.FirstName} ${client.LastName}`
      }
    }
  }

  return (
    <div className="quote-row">
      <Link to={"/admin/edit-quote/" + quote._id} className="quote-row border">
        
        <div className="quote-label uppercase">
          {renderState(quote.state)}
        </div>

        <div className="quote-label uppercase">
          <p>{quote.number}</p>
        </div>

        <div className="quote-label uppercase">
          <p>{renderClientName()}</p>
        </div>

        <div className="quote-label uppercase">
          <p>{moment(quote.sendDate).format("DD/MM/YYYY")}</p>
        </div>

        <div className="quote-label uppercase">
          <p>{quote.validUntilDate && moment(quote.validUntilDate).format("DD/MM/YYYY")}</p>
        </div>

        <div className="quote-label uppercase">
          <p>{calcPrice(quote.content).totalTTC} €</p>
        </div>

      </Link>

      <div className="dropdown">
        <button className="dropbtn"><img src={optIcon} alt="item-options"/></button>
        <div className="dropdown-content">
          <div>

            <Link to={"/admin/edit-quote/" + quote._id}>
              Editer
            </Link>

            <button onClick={() => getQuoteFileAction(dispatch , quote._id , quote.number )}>
              Apercu
            </button>

            {quote.state === "draft" || quote.state === "sent" || quote.state === "valid" ?
              <button onClick={() => sendQuoteMailAction(dispatch , quote._id )}>
                Renvoyer
              </button>
              :
              <button onClick={() => sendQuoteMailAction(dispatch , quote._id )}>
                Envoyer
              </button>
            }

            {quote.state === "valid" &&
              <button onClick={() => submitAsReceipt(dispatch , quote )}>
                Transformer en reçu
              </button>
            }

            {quote.state === "sent" || quote.state === "seen" ?
              <>
                <button onClick={() => HandleChangeState("valid")}>
                  Marquer comme acceptée
                </button>
                <button onClick={() => HandleChangeState("refused")}>
                  Marquer comme refusée
                </button>
              </>
              :
              null
            }

            <button onClick={() => copyQuote()}>
              Copier
            </button>

            <button onClick={() => deleteQuoteAction(dispatch , quote._id )}>
              Supprimer
            </button>

          </div>
        </div>
      </div>
     
    </div>
  )
}
