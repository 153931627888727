import React , { useEffect , useState } from 'react';
import { useParams , useHistory , Link , Prompt } from 'react-router-dom';
import Select from "react-select";

import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';
import DatePicker , { registerLocale } from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want
import { getReceiptAction , createReceiptAction , updateReceiptAction , payReceiptAction , sendReceiptMailAction, validReceiptAction  , getReceiptFileAction } from "../../../../../actions/receiptsActions";

import countriesOptions from '../../../../..//utils/countries.json'

import { customSelect } from '../../../../../utils/styles';
import { calcReceiptPrice } from '../../../../../utils/utils';

import './receipts.scss';
import Loader from '../../../../partials/Loader';
import Modale from '../../../../partials/Modale';
import EditReceiptProductRow from './EditReceiptProductRow';
import moment from 'moment';

export default function EditReceipt({ setActivePage , context , dispatch , path , isPreview }) {

  registerLocale("fr", fr);

  const today = new Date()
  const history = useHistory();
  const params = useParams();

  var mail = `J'espère que vous allez bien.<br/>Voici votre reçu pour nos prestations chez vous.<br/>Merci pour votre règlement dès que possible en cliquant sur le bouton « payer ce reçu ».<br/>Si vous avez opté pour le prélèvement, tout est automatique.<br/>Mille mercis<br/>A très bientôt,<br/>`;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'indent': '-1'}, {'indent': '+1'}],
      ['link'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockreceipt',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const [ notes , setNotes] = useState("");
  const [ receipt , setReceipt ] = useState();
  const [ confirmIsLoading , setConfirmIsLoading ] = useState(false);
  const [ message , setMessage ] = useState();
  const [ isSaved , setIsSaved ] = useState(true);
  const [ paymentIsLoading , setPaymentIsLoading ] = useState(false);

  const [ paymentMessage , setPaymentMessage ] = useState();
  const [ displayConfirmModale , setDisplayConfirmModale ] = useState(false);
  const [ sendIsValid , setSendIsValid] = useState(false)

  const [ validUntilDate , setValidUntilDate ] = useState(new Date(today.setMonth(today.getMonth()+1)));
  const [ date , setDate ] = useState(new Date());
  const [ customMail , setCustomMail ] = useState(mail);

  const [ isCreated , setIsCreated ] = useState(false)
  
  const [ clientOptions , setClientOptions ] = useState();
  const [ seletedClient , setSeletedClient ] = useState();

  const [ providerOptions , setProviderOptions ] = useState();

  const [ productsOptions , setProductsOptions ] = useState();
  const [ productToAdd , setProductToAdd ] = useState();

  const [ productList , setProductList ] = useState([]);
  const [ initProductList , setInitProductList ] = useState([])

  useEffect(() => {
    setActivePage("receipts");
    
    if (params.receiptId) {
      getReceiptAction( dispatch , params.receiptId)
      setIsCreated(true);
    }

    return () => dispatch({
      type: 'GET_RECEIPT',
      payload: null
    })
    // eslint-disable-next-line 
  }, []);

  useEffect(( ) => {
    if (context.receipts.receipt) {
      if (context.receipts.receipt.description) setNotes(context.receipts.receipt.description);
      if (context.receipts.receipt.email) setCustomMail(context.receipts.receipt.email);
      setReceipt(context.receipts.receipt);
      if (context.receipts.receipt.date) {
        setDate(new Date(context.receipts.receipt.date));
      } else {
        setDate(new Date())
      }
      if (context.receipts.receipt.validUntilDate) {
        setValidUntilDate(new Date(context.receipts.receipt.validUntilDate));
      } else {
        var createdAt = new Date(context.receipts.receipt.createdAt);
        setValidUntilDate(new Date(createdAt.setMonth(createdAt.getMonth()+1)));
      }
    } 
  }, [context.receipts.receipt]);


  useEffect(( ) => {
    if (context.receipts.receipt && context.receipts.receipt.content && context.users.usersList && providerOptions ) {
      var receiptContent = [];
      if (!isPreview && context.receipts.receipt.state !== "draft") {
        history.push(`${path}/receipts`)
      }
      context.receipts.receipt.content.forEach(product => {
        if (!product.providers.length) {
            var initProviders = [{
              quantity: product.quantity,
              priceHT: product.priceHT,
              tax: product.tax,
              withTAX: providerOptions[0].value.withTVA,
              provider: providerOptions[0].value._id
            }
          ];
          product.providers = initProviders
        } else {
          product.providers.forEach( productProvider => {
              var found = context.users.usersList.find(user => user._id === productProvider.provider);
              productProvider.tax = found && found.withTVA ? product.tax : 0 
            });
          }  
        receiptContent.push(product)

      });

      setProductList(receiptContent);
      setInitProductList(receiptContent);
    }
    // eslint-disable-next-line
  }, [context.receipts.receipt , context.users.usersList , providerOptions ]);

  useEffect(() => {
    if (context.receipts.message === "Receipt successfully updated!" || context.receipts.message === "receipt saved") {

      setIsSaved(true);

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });

    }
  },[context.receipts.message , dispatch ]);

  useEffect(() => {
    if (context.products.productsList) {
      let options = [];
      context.products.productsList.forEach(product => {
        options.push({
          label: product.name,
          value: product
        })
      })
      setProductsOptions(options);
    }

  }, [context.products.productsList]);

  useEffect(() => {
    if (context.users.usersList) {

      let clientsOptions = [];
      var clientList = context.users.usersList.filter(user => user.type === "client")
      clientList.forEach(client => {
        clientsOptions.push({
          label: client.FirstName ? `${client.FirstName} ${client.LastName}` : `${client.LegalRepresentativeFirstName} ${client.LegalRepresentativeLastName}`,
          value: client
        })
      })
      setClientOptions(clientsOptions);

      let providerOptions = [];
      var providerList = context.users.usersList.filter(user => user.type === "provider")
      providerList.forEach(p => {
        providerOptions.push({
          label: p.Name ? p.Name : `${p.FirstName} ${p.LastName}` ,
          value: p
        })
      })
      setProviderOptions(providerOptions)

    }
  }, [context.users.usersList]);

  useEffect(() => {
    if (receipt && receipt.client && receipt.client._id && clientOptions) {
      setSeletedClient(clientOptions.find(client => client.value._id === receipt.client._id));
      if (receipt.email) setCustomMail(receipt.email)
    }

  },[receipt, clientOptions]);

  useEffect(() => {
    if(seletedClient) {
      setIsSaved(false);
      if (!receipt?.email) {
        let firstName = seletedClient.value.FirstName ? seletedClient.value.FirstName : seletedClient.value.LegalRepresentativeFirstName 
        setCustomMail(firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() + ',<br/>' + mail)
      }
    }
    // eslint-disable-next-line
  },[seletedClient])


  useEffect(() => {
    if (isSaved && initProductList && calcReceiptPrice(initProductList).isValid && calcReceiptPrice(initProductList).totalTTC > 0) {
      setSendIsValid(true)
    }
    
  }, [initProductList , isSaved]);

  useEffect(() => {
    if (context.receipts.error?.data?.error === "Mandate not found") {
      setPaymentMessage("Le mandat n'a pas été trouvé")
    }
  }, [context.receipts.error] );

  useEffect(() => {
    
    if (context.receipts.message === "Receipt paiement send") {
      history.push(`${path}/receipts`);
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été envoyé en paiement",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });
    }

    if (context.receipts.message === "Receipt mail send") {
      history.push(`${path}/receipts`);
      
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été envoyé",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });

    }

    // eslint-disable-next-line 
  }, [context.receipts.message] );

  useEffect(() => {
    if (context.receipts.message && confirmIsLoading) {
      if (context.receipts.message === 'Receipt successfully updated!') {
        history.push(`${path}/receipts`);
      }
      setConfirmIsLoading(false);
      if (context.receipts.message === "Receipt valid")
      setMessage("Receipt valid")
    }
    // eslint-disable-next-line
  },[context.receipts.message , confirmIsLoading]);

  function addProduct() {
    var addToList = [...productList]
    var product = {...productToAdd }
    product.value.quantity = 0
    product.value.providers = [];
    addToList.push(product.value);
    setProductList(addToList);
    setProductToAdd();
  }

  function handleChangeProduct(index , key , value ) {

    let updatedList = [...productList];
    let updatedProduct = {...updatedList[index]};

    if(isPreview) return null;
    
    setIsSaved(false);
    setSendIsValid(false);
    
    switch (key) {

      case "name":
        updatedProduct.name = value;
        break;

      case "discount":
        updatedProduct.discount = value;
        break;

      case "unit":
        updatedProduct.unit = value;
        break;

      case "tax":
        updatedProduct.tax = value;
        break;

      case "description":
        updatedProduct.description = value;
        break;
      
      case "priceHT":
        updatedProduct.priceHT = parseFloat(value);
        break;

      case "providers":
        var allProviders = [];
        
        value.forEach(provider => {
          if (providerOptions.find(opt => opt && opt.value && opt.value._id === provider.provider) && providerOptions.find(opt => opt && opt.value && opt.value._id === provider.provider).value.withTVA) {
            provider.tax = updatedProduct.tax;
          } else {
            provider.tax = 0;
          }
          allProviders.push(provider)
        });

        updatedProduct.providers = allProviders;

        break;

      case "quantity":
        updatedProduct.quantity = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
        break;

      case "totalTTC":
        updatedProduct.totalTTC = value;
        break;
    
      default:
        break;
    }

    updatedList[index] = updatedProduct
    setProductList(updatedList);
  }

  function removeProduct(index) {
    let removedList = productList.filter((product , i ) => i !== index);
    setProductList(removedList)
  }

  function submitReceipt() {
    if (seletedClient && productList.length) {
      let receiptData = {
        client: seletedClient.value._id,
        description: notes,
        content: productList,
        date: date.toISOString(),
        validUntilDate: validUntilDate.toISOString(),
        email: customMail
      }

      if (isCreated) {
        return updateReceiptAction( dispatch , receipt._id ,  receiptData );
      } else {
        setIsCreated(true)
        return createReceiptAction(dispatch , receiptData);
      }
    }
  }

  function validReceipt(id) {
    validReceiptAction( dispatch , id )
    setConfirmIsLoading(true);
  }

  function compareDates() {
    var d1 = new Date(validUntilDate);
    var d2 = new Date(date);
    return d1.getTime() >= d2.getTime();
  }

  function validPayment(type) {
    setPaymentIsLoading(true);
    
    if (type !== "paid") {
      payReceiptAction( dispatch , receipt , type )
    } else {
      updateReceiptAction(dispatch , receipt._id , { state: "paid" })
    }
  }

  function renderConfirmReceipt() {
    if (confirmIsLoading && !message) {
      return (
        <>
          {paymentMessage ? <p>{paymentMessage}</p>:<Loader />}
        </>
      )
    } else if (paymentIsLoading) {
      return (
        <>
          <Loader />
        </>
      )
    } else if (!confirmIsLoading  && message ) {
      return (
        <>
           <h2>ENVOYER EN PAIEMENT</h2>

          <button
            type='button'
            className="btn primary"
            onClick={() => validPayment("card")}>
            Paiement par carte bancaire
          </button>

          <button
            type='button'
            className="btn primary"
            onClick={() => validPayment("mandate")}>
            Paiement par mandat
          </button>

          <h2>AUTRES ACTIONS</h2>

          <button
            type='button'
            className="btn primary"
            onClick={() => sendReceiptMailAction(dispatch , receipt._id )} >
            Envoyer le reçu par mail
          </button>

          <button
            type='button'
            className="btn primary"
            onClick={() => validPayment("paid")} >
            Passer en payer
          </button>

          <Link to={`${path}/receipts`}>
            <button className="btn grey">
              Retour à la liste des reçus
            </button>
          </Link>
        </>
      )
    } else {
      return (
        <>
          <h2>Facturation du reçu</h2>
          <p>Cette action est irréversible</p>
          <button className="btn primary"
            onClick={() => validReceipt(receipt._id)}>
            Confirmer
          </button>
          <button className="btn grey"
            onClick={() => setDisplayConfirmModale(false)}>
            Annuler
          </button>
        </>
      )
    }

  }

  function renderReceiptHeader() {
    return (
      <div className="receipt-container">

        <div className="receipt-content">

          <div className="header">

            <h1>Reçu {receipt && receipt.number && `N° ${receipt.number}`}</h1>

            {!isPreview && seletedClient && productList.length > 0 && 
        
              <div className="receipt-actions">
                {compareDates() &&
                  <button className="btn primary"
                    onClick={() => submitReceipt()}>
                    Enregistrer
                  </button>
                }

                {receipt && receipt._id && 
                  <button className="btn primary"
                    onClick={() => getReceiptFileAction(dispatch , receipt._id , receipt.number )}>
                    Aperçu
                  </button>
                }

                {sendIsValid && receipt && receipt._id && calcReceiptPrice(productList).totalTTC.toFixed(2) > 0 && <button
                  onClick={() => setDisplayConfirmModale(true)}
                  className="btn grey ">
                    Facturer
                  </button>
                }

              </div>

            }

          </div>

          <div className="receipt-client">

            <div className="col-2">
              <h2>CLIENT</h2>
              {isPreview?
                <p className="address">{seletedClient?.label}</p>
              :
                <Select
                  styles={customSelect}
                  value={seletedClient}
                  placeholder={'Sélectionner un client'}
                  options={clientOptions}
                  onChange={(client) => setSeletedClient(client)}
                />
              }

              <h2>ADRESSE</h2>
              {seletedClient && seletedClient.value &&
                <>
                  {seletedClient.value.HeadquartersAddress && 
                    <p className="address">
                      {seletedClient.value.Name && <span className="bold">{seletedClient.value.Name}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.AddressLine1 && <span>{seletedClient.value.HeadquartersAddress.AddressLine1}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.AddressLine2 && <span>{seletedClient.value.HeadquartersAddress.AddressLine2}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.Region && <span>{seletedClient.value.HeadquartersAddress.Region}<br/></span>}
                      {seletedClient.value.HeadquartersAddress.City && seletedClient.value.HeadquartersAddress.PostalCode && <span>{seletedClient.value.HeadquartersAddress.PostalCode} {seletedClient.value.HeadquartersAddress.City}</span>}<br/>
                      {seletedClient.value.HeadquartersAddress.Country && countriesOptions.find(item => item.value.toUpperCase() === seletedClient.value.HeadquartersAddress.Country).label}
                    </p>
                  }

                  {seletedClient.value.Address && 
                    <p className="address">
                      {seletedClient.value.Name && <span className="bold">{seletedClient.value.Name}<br/></span>}
                      {seletedClient.value.Address.AddressLine1 && <span>{seletedClient.value.Address.AddressLine1}<br/></span>}
                      {seletedClient.value.Address.AddressLine2 && <span>{seletedClient.value.Address.AddressLine2}<br/></span>}
                      {seletedClient.value.Address.Region && <span>{seletedClient.value.Address.Region}<br/></span>}
                      {seletedClient.value.Address.City && seletedClient.value.Address.PostalCode && <span>{seletedClient.value.Address.PostalCode} {seletedClient.value.Address.City}</span>}<br/>
                      {seletedClient.value.Address.Country && countriesOptions.find(item => item.value.toUpperCase() === seletedClient.value.Address.Country).label}
                    </p>
                  }

                  <h2>EMAIL</h2>
                  {seletedClient.value.email && <p className="address">{seletedClient.value.email}</p>}

                  <h2>ENTREPRISE</h2>
                  <p className="address">
                    {seletedClient.value.companyType && <span>{seletedClient.value.companyType}<br/></span>}
                    {seletedClient.value.siret && <span>siret : {seletedClient.value.siret}<br/></span>}
                  </p>
                </>
              }

            </div>

            <div className="col-2">
              <div className="date-row">
                <div className="date">
                  <h2>Date d'émission</h2>
                  {isPreview?
                    <p className="address">{moment(date).format("DD/MM/YYYY")}</p>
                  :
                    <DatePicker
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      selected={date}
                      onChange={setDate}
                    />
                  }
                </div>
                <div className="date">
                  <h2>Date d'échéance</h2>
                  {isPreview?
                    <p className="address">{moment(validUntilDate).format("DD/MM/YYYY")}</p>
                  :
                    <DatePicker
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      selected={validUntilDate}
                      onChange={setValidUntilDate}
                    />
                  }
                </div>
              </div>

              {isPreview ?
                <>
                  {notes &&
                    <>
                      <h2>NOTE PERSONNELLE</h2>
                      <div dangerouslySetInnerHTML={{ __html: notes }}></div>
                    </>
                  }
                </>
              : 
                <>
                  <h2>NOTE PERSONNELLE</h2>
                  <ReactQuill 
                    theme={'snow'}
                    value={notes || ''}
                    onChange={(val) => setNotes(val)}
                    modules={modules}
                    formats={formats}
                  />
                </>
              }
              
            </div>

          </div>

        </div>

      </div>
    )
  }

  return (
    <>
      <div className="router-transition-container">
        <Prompt when={!isSaved} message={"Voulez-vous vraiment quitter cette page ?"}/> 
        <div className="page-content">
        <div className="row">
            <div className="col-1">
              {renderReceiptHeader()}
            </div>

            <div className="col-1">
              <div className="receipt-container">
                <div className="title">
                  <div className="bar"></div>
                  <h2>{"PRESTATIONS & PRODUITS"}</h2>
                  <div className="bar"></div>
                </div>
              </div>

              <div className="receipt-container">
                {!isPreview &&
                <div className="add-product">
                  <div className="select-product">
                    <Select
                      styles={customSelect}
                      placeholder={'Sélectionner un produit à ajouter'}
                      value={productToAdd || ''}
                      options={productsOptions}
                      onChange={(product) => setProductToAdd(product)}
                    />
                  </div>

                  {productToAdd &&
                    <button
                      onClick={() => addProduct()}
                      className="btn primary">
                      Ajouter
                    </button>
                  }
                </div>
                }
              </div>

              <div className="edit-product-row uppercase">
                <div className="name"><p>TYPE DE PRESTA / PRODUIT</p></div>
                <div className="value"><p>TVA</p></div>
                <div className="value"><p>Quantité</p></div>
                <div className="value"><p>Unités</p></div>
                <div className="value"><p>Prix unitaire TTC</p></div>
                <div className="value"><p>Remise (%)</p></div>
                <div className="value total"><p>Total TTC</p></div>
              </div>

              {productList.map(( product , i) => {
                return <EditReceiptProductRow product={product} isPreview={isPreview} productList={productList} handleChangeProduct={handleChangeProduct} providerOptions={providerOptions} removeProduct={removeProduct} key={i +'Product'} index={i}/>
              })}

              <div className="total-row">
                <p className="bold">Total TTC {calcReceiptPrice(productList).totalTTC.toFixed(2)} €</p>
                {calcReceiptPrice(productList).totalTVA.map(item => ( item.total > 0 ? <p className="tva" key={item.tax} >dont TVA à {item.tax}% <span>{item.total.toFixed(2)} €</span></p> : null ))}
              </div>
            </div>

            <div className="col-1">
              <div className="receipt-container">
                <div className="title">
                  <div className="bar"></div>
                  <h2>{"EMAIL PERSONNALISé"}</h2>
                  <div className="bar"></div>
                </div>
                

              </div>
              {!isPreview ?
                <>
                  <ReactQuill 
                    theme={'snow'}
                    value={customMail}
                    onChange={(val) => setCustomMail(val)}
                    modules={modules}
                    formats={formats}
                  />
                </>
                :
                <div dangerouslySetInnerHTML={{ __html: customMail }}></div>
              }
            </div>

          </div>
        </div>
      </div>
        <Modale
          modaleIsOpen={displayConfirmModale && !isPreview }
          modaleToggle={setDisplayConfirmModale}
          hideClose={true}
          className={"valid-receipt-modale"}
          >
          <div className="content">
            {renderConfirmReceipt()} 
          </div>
        </Modale>
    </>
  )
}
