
import {
  GET_USERS,
  GET_USER,
  ERROR_USERS,
  MESSAGE_USERS,
  UPDATE_USER,
  CREATE_USER,
  CREATE_IBAN,
  DELETE_USER,
  CREATE_USER_MANDATE,
  GET_INVOICES,
  POST_USER_CA
} from "../actions/types";

const initialState = {
  error: null,
  usersList: null,
  user: null,
  message: null,
  toast: null,
  mandate: null,
  invoices: null,
  userCa: null
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {

    case "SET_TOAST":
      return {...state , toast: action.payload }

    case POST_USER_CA:
        return {...state , userCa: action.payload }

    case DELETE_USER:
      var deleteList = state.usersList.filter(quote => quote._id !== action.payload)
      return {...state , usersList: deleteList }

    case CREATE_IBAN:
      return {...state , message: action.payload.message , user: action.payload.user }

    case GET_USER:
      return {...state , user: action.payload}

    case GET_USERS:
      return {...state , usersList: action.payload}

    case UPDATE_USER:
      let updatedList = state.usersList.filter(user => user._id !== action.payload.user._id);
      updatedList.push(action.payload.user)
      return {...state ,
        usersList : updatedList,
        user : action.payload.user,
        message: action.payload.message
      }
      

    case GET_INVOICES:
      return {...state ,
        invoices: action.payload
      }
    
    case CREATE_USER_MANDATE:
      return {...state ,
        user: {...state.user , mandates: action.payload.mandate }
      }

    case CREATE_USER:
      let createdList = [...state.usersList];
      createdList.push(action.payload.user);
      return {...state ,
        usersList : createdList,
        user : action.payload.user,
        message: action.payload.message
      }

    case ERROR_USERS:
      return {...state , error: action.payload}

    case MESSAGE_USERS:
      return {...state , message: action.payload}

    default:
      return {...state};
  }
}