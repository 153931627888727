import React, { useState , useEffect, useRef } from 'react';
import Select from "react-select";

import { addTVA , removeTVA } from '../../../../../utils/utils';

import deleteIcon from '../../../../../assets/images/icons/delete.svg';

import { customSelect } from '../../../../../utils/styles';

export default function ProviderRow({ providerOptions , productProviders , handleChangeProduct ,  provider , product , index , productIndex , addProvider , setAddProvider , isPreview }) {

  const [ selectedProvider , setSelectedProvider ] = useState();

  const priceRef = useRef();

  useEffect(() => {
    if (provider && providerOptions ) {
      var found = providerOptions.find(opt => opt.value._id === provider.provider)
      if (found) {
        setSelectedProvider(found);
      }
    }
  }, [provider , providerOptions , product])

  useEffect(() => {
    if (selectedProvider) {
      handleChangeProviderPresta( index , "id" , selectedProvider.value);
      
    }
    // eslint-disable-next-line 
  }, [selectedProvider , index , product.tax]);

  useEffect(() => {
    if (provider && provider.priceHT && priceRef.current) {
      priceRef.current.value = addTVA(provider.priceHT , product.tax ).toFixed(2);
    }
    // eslint-disable-next-line
  }, [selectedProvider])

  function handleChangeProviderPresta(index , key , value ) {
    let updatedProviderList = [...productProviders];
    let updatedProvider = {...updatedProviderList[index]};

    if(isPreview) return null;

    let newProvider = {
      quantity: 1,
      priceHT: product.priceHT,
      tax : product.tax ,
      withTAX : selectedProvider.value.withTVA ? true : false
    }
    
    switch (key) {

      case "id":
        updatedProvider.provider= value._id;
        newProvider.provider = value._id;
        updatedProvider.tax = product.tax
        updatedProvider.withTAX = selectedProvider.value.withTVA ? true : false
        break;
      
      case "unit":
        updatedProvider.unit = value;
        break;

      // case "discount":
      //   updatedProvider.discount = value;
      //   break;

      case "quantity":
        updatedProvider.quantity = parseFloat(value);
        break;

      case "priceHT":
        updatedProvider.priceHT = value;
        break;

      default:
        break;
    }

    if (addProvider) {
      updatedProviderList.push(newProvider);
    } else {
      updatedProviderList[index] = updatedProvider
    }

    handleChangeProduct(productIndex, "providers" , updatedProviderList);
    setAddProvider(false);
  }

  function removeProvider(index) {
    let providerList = productProviders.filter((Provider , i ) => i !== index);
    handleChangeProduct(productIndex, "providers" , providerList);
  }

  return (
    <div className="provider-row">

      <div className="select-provider">
        {!isPreview ?
          <>
            <Select
              styles={customSelect}
              placeholder={'Sélectionner un indépendant'}
              value={selectedProvider || ''}
              options={providerOptions.filter(p => !productProviders.find(product => product.provider === p.value._id ) ? p : null )}
              onChange={(val) => setSelectedProvider(val)}
            />
            <button onClick={() => removeProvider(index)}>
              <img src={deleteIcon} alt="delete"/>
            </button>
          </>
          :
          <p className="uppercase">{selectedProvider?.label}</p>
        }
      </div>

      {selectedProvider &&
        <>
        <div className="value uppercase"><p>{provider && provider.tax > 0 ?" OUI" : "NON" }</p></div>

        <div className="quantity value">
        {!isPreview ?
          <>
            <input
              type="number"
              step="any"
              id={"quantity" + index}
              onChange={(e) => handleChangeProviderPresta( index , "quantity" , e.target.value)}
              defaultValue={provider && provider.quantity ? provider.quantity : 0 }
              />
          </>
          :
          <p className="uppercase">{provider?.quantity}</p> 
        }
        </div>

        <div className="value uppercase"><p>{product.unit}</p></div>

        <div className="value">
          {!isPreview ?
            <>
              <input
                type="number"
                step="any"
                id={"price" + index + productIndex }
                ref={priceRef}
                onChange={(e) => handleChangeProviderPresta( index , "priceHT" , removeTVA(e.target.value , product.tax ))}
                />
            </>
            :
            <p className="uppercase">{addTVA(provider.priceHT, product.tax).toFixed(2)}</p> 
          }
        </div>

        <div className="value">
          {/* <p>{product?.discount && `${product?.discount}%` }</p> */}
        </div>
        
        <div className="value total">
          {provider && provider.quantity && provider.priceHT && addTVA(provider.priceHT, product.tax) * provider.quantity && <p>{(addTVA(provider.priceHT, product.tax) * provider.quantity).toFixed(2)} €</p> }
        </div>
        </>
      }
    </div>
  )
}
