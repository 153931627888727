import React , { useEffect , useState, useCallback } from 'react';
import { Link } from  'react-router-dom';

import Select from "react-select";
import { SET_QUOTES_FILTERS } from '../../../../../actions/types';
import { getQuotesFilteredAction } from '../../../../../actions/quotesActions';
import { defaultFilters } from '../../../../../reducers/quotesReducer';
import { customSort } from '../../../../../utils/styles';

import QuoteRow from './QuoteRow';
import PeriodPicker from '../../AdminPartials/PeriodPicker';

import searchImg from '../../../../../assets/images/icons/search.svg';
import './quotes.scss';


export default function QuotesList(props) {

  const { setActivePage , context , match , dispatch } = props;

  const sortOptions = [
    { value : { key: "date", order: "desc" } , label : "Trier par date ( les plus récentes) " },
    { value : { key: "date", order: "asc" } , label : "Trier par date ( les moins récentes) " },
    { value : { key: "number", order: "desc" } , label : "Trier par numéro (décroissant)" },
    { value : { key: "number", order: "asc" }  , label : "Trier par numéro (croissant)" },
  ];

  const { filters } = context.quotes;

  const [ allQuotes , setAllQuotes ] = useState();
  const [ search , setSearch ] = useState();

  function handleChangeFilters(obj) {
    dispatch({
      type: SET_QUOTES_FILTERS,
      payload: {
        ...filters,
        ...obj
      }
    })
  };

  const getQuotes = useCallback(() => {
    getQuotesFilteredAction(dispatch, filters)
  },[dispatch, filters]);

  useEffect(() => {
    setActivePage("quotes");
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    getQuotes();
    // eslint-disable-next-line 
  }, [filters])

  useEffect(() => {
    if (context.quotes.quotesList && context.users.usersList) {
      // var start = new Date();
      // var end = new Date();
      const quotes = [...context.quotes.quotesList]
      quotes.forEach(quote => {
        // var quoteDate = new Date(quote.sendDate)
        // if (quoteDate < start) start = quoteDate;
        // if (quoteDate > end) end = quoteDate;
        if (context.users && context.users.usersList && quote.client) {
          let client = context.users.usersList.find(user => user._id === quote.client);
          if (client) {
            quote.clientName = client.LastName ? client.LastName : client.LegalRepresentativeLastName
            quote.clientFirstName = client.FirstName ? client.FirstName : client.LegalRepresentativeFirstName
          }
        }
      });
      setAllQuotes(quotes)
    }
  }, [context.quotes.quotesList , context.users]);

  // useEffect(() => {
  //   if (context.quotes.quotesList) {
  //     if (search) {
  //       var newList = context.quotes.quotesList.filter(quote => 
  //         quote.clientFirstName.toLowerCase().includes(search.toLowerCase()) ||
  //         quote.number.toString().toLowerCase().includes(search.toLowerCase()) ||
  //         quote.clientName.toLowerCase().includes(search.toLowerCase())
  //       )
  //       setAllQuotes(filterByDatePeriodKey("sendDate" , newList , startDate , endDate));
  //     } else {
  //       setAllQuotes(filterByDatePeriodKey("sendDate" , context.quotes.quotesList , startDate , endDate))
  //     }
  //   }
  // }, [search, context.quotes.quotesList]);

  useEffect(() => {
    if (context.receipts.message === "Receipt successfully updated!" || context.receipts.message === "receipt saved") {
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le reçu a bien été enregistré",
          type: "success"
        }
      });

      dispatch({
        type:'MESSAGE_RECEIPTS',
        payload: null
      });

    }
  },[context.receipts.message , dispatch ]);

  function renderQuotesList() {
    if (allQuotes) {
      return allQuotes.map((quote, i) => (<QuoteRow key={i + quote._id} quote={quote} dispatch={dispatch} context={context} path={match.path}/>))
    }
  }

  function renderPagination() {
    const folios = [];
    if (context?.quotes?.count > context.quotes?.quotesList?.length) {
      for (let i = 1 ; i - 1 < context?.quotes?.count / context?.quotes.filters.number ; i++) {
        folios.push(i);
      }
      return <ul className="pagination">
        {folios.map((f) => <li key={'page' + f}>
          <button
            className={`${filters.page === f && 'active'}`}
            onClick={() => handleChangeFilters({ page: f })}>
            {f}
          </button>
        </li>)}
      </ul>
    }
    return null;
  };

  return (
    <div className="router-transition-container">
      <div className="page-content">
        <div className="filters">
          <div className="col-4">
            <div className="header">
              <h2>Filtres</h2>
              <button
                className={"btn primary"}
                onClick={() => handleChangeFilters(defaultFilters)}
                >Réinitialiser
              </button>
            </div>
            <PeriodPicker
              startDate={filters.start ? new Date(filters.start) : null }
              endDate={filters.end ? new Date(filters.end) : null }
              setEndDate={(date) => handleChangeFilters({ end: date.toISOString() , page: 1 })}
              setStartDate={(date) => handleChangeFilters({ start: date.toISOString() , page: 1 })}
              />
          </div>
          <div className="col-4">
            <Select
              styles={customSort}
              isSearchable={false}
              value={sortOptions.find((opt) => opt.value.key === filters.orderBy.key && opt.value.order === filters.orderBy.order)}
              options={sortOptions}
              onChange={(opt) => handleChangeFilters({ orderBy: opt.value , page: 1})}
            />
          </div>
          <div className="col-4 search">   
              <input
                placeholder="Rechercher..."
                onChange={(e) => setSearch(e.target.value)}
                value={search || ''}
              />
              <button
                onClick={() => handleChangeFilters({ search: search , page: 1 })}
                >
                <img src={searchImg} alt='search' />
              </button>
            </div>
        </div>
        <div className="row">
          <div className="col-1">
            <div className="header">
              <h2>PROPOSITIONS</h2>
              <div className="actions">
                <Link to={"/admin/create-quote"}>
                  <button className="btn primary">
                    Nouveau
                  </button>
                </Link>
              </div>
            </div>
            <div className="list-container">
              <div className="quote-row label-row bold">
                <div className="quote-label uppercase">
                  <p>STATUT</p>
                </div>
                <div className="quote-label uppercase">
                  <p>N°</p>
                </div>
                <div className="quote-label uppercase">
                  <p>CLIENT</p>
                </div>
                <div className="quote-label uppercase">
                  <p>Date</p>
                </div>
                <div className="quote-label uppercase">
                  <p>validité</p>
                </div>
                <div className="quote-label uppercase">
                  <p>MONTANT</p>
                </div>
              <div>
            </div>

            </div>
            {renderQuotesList()}
          </div>
          {renderPagination()}
          </div>
        </div>
      </div>
    </div>
  )
}

